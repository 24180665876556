<div class="video-slide">
  <video  controls  laysinline autoplay webkit-playsinline poster="/assets/electronic-poster.png"  (ended)="videoEnd()">
    <source src="/assets/The-Story-of-Electronics.mp4" type="video/mp4">
    <track label="English" kind="subtitles" srclang="en" src="/assets/story-of-electronics.vtt" default>
    Your browser does not support the video tag.
  </video>

  <div class="slide-label" *ngIf="videoEnded">
    Film vznikol v roku 2010. Ľudia vtedy vyprodukovali celosvetovo až 25 miliónov ton elektroodpadu.
  </div>
  <div class="action">
    <app-button *ngIf="videoEnded" (click)="next()" text="Prejdi na kvíz"></app-button>
  </div>
</div>

<div class="video-top"></div>
