import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-table-evolution-intro',
  styleUrls: ['./table-evolution-intro.component.scss'],
  template: `
    <div class="intro">
      <h1 class="title">Evolúcia pracovného stola</h1>
      <div class="subtitle">
      Pozri, čo všetko sa za posledných 60 rokov zmenilo.
      </div>
      <app-button  (click)="next()" text="Pozrieť rok 1960" ></app-button>
    </div>
    <div class="black-top"></div>
    <div class="black-bottom"></div>
  `,
})
export class TableEvolutionIntroComponent implements OnInit {
  @Output()
  nextSlide: EventEmitter<number> = new EventEmitter<number>();
  constructor() {}

  ngOnInit(): void {}

  next(): void {
    this.nextSlide.emit(4);

  }
}
