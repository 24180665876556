import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  NgZone,
  AfterViewInit,
} from '@angular/core';
import { TimelineMax } from 'gsap';
import gsap from 'gsap/all';
@Component({
  selector: 'app-intro-slide',
  templateUrl: './intro-slide.component.html',
  styleUrls: ['./intro-slide.component.scss'],
})
export class IntroSlideComponent implements OnDestroy, AfterViewInit {
  @Output()
  nextSlide: EventEmitter<number> = new EventEmitter<number>();
  public timeline: TimelineMax;
  public timelinePerson: TimelineMax;
  loaded: boolean;
  constructor(private _ngZone: NgZone) {
    this.timelinePerson = gsap.timeline();
    this.timeline = new TimelineMax({
      onReverseComplete: () => {
        this._ngZone.run(() => {
          this.nextSlide.emit(2);
        });
      },
    });
  }

  ngAfterViewInit(): void {
    this.timeline
      .fromTo(
        '.banner',
        { opacity: 0, y: 200 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          delay: 0.5,
          ease: 'elastic.out(1, 0.3)',
        }
      )
      .fromTo(
        '#Group_444',
        { opacity: 0, xPercent: -120 },
        { opacity: 1, xPercent: 0, duration: 0.8, ease: 'elastic.out(1, 0.3)' },
        '-=0.3'
      )
      .fromTo(
        '#Group_450',
        { opacity: 0, xPercent: 100 },
        {
          ease: 'elastic.out(1, 0.3)',
          duration: 0.8,
          opacity: 1,
          xPercent: 0,
        },
        '-=0.3'
      )
      .fromTo(
        '#Group_446',
        { opacity: 0, yPercent: 100 },
        {
          ease: 'elastic.out(1, 0.3)',
          duration: 0.5,
          opacity: 1,
          yPercent: 0,
        },
        '-=0.3'
      )
      .fromTo(
        '#Group_448',
        { opacity: 0, yPercent: -100 },
        {
          ease: 'elastic.out(1, 0.3)',
          duration: 0.5,
          opacity: 1,
          yPercent: 0,
        },
        '-=0.2'
      )
      .fromTo(
        '.button',
        { opacity: 0, y: 50 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          delay: 0.2,
          ease: 'elastic.out(1, 0.3)',
        },
        '-=0.8'
      );

    this.timelinePerson
      .to('#Path_353', {
        rotate: 25,
        duration: 1,
        repeat: -1,
        yoyo: true,
        transformOrigin: '18px 0 ',
      })
      .to('#Path_318', {
        rotate: 10,
        duration: 1,
        repeat: -1,
        yoyo: true,
        transformOrigin: '0 50px ',
      });
  }
  //Path_353
  next(): void {
    this.timeline.reverse(0.1);
  }
  ngOnDestroy() {}
}
