import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-electronics-story-intro',
  templateUrl: './electronics-story-intro.component.html',
  styleUrls: ['./electronics-story-intro.component.scss']
})
export class ElectronicsStoryIntroComponent implements OnInit {

  @Output()
  nextSlide: EventEmitter<number> = new EventEmitter<number>();

  constructor() { }

  ngOnInit(): void {
  }

  next(): void  {
    this.nextSlide.emit(10);
  }

}
