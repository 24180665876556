<div @slidesAnimation *ngIf="step === 1" class="slide">
  <div class="slide-grid">

    <div class="slide-left">
      <div class="slide-inner-left">
      <img
        class="table table-float-right"
        src="assets/table-einst.jpg"
        alt=""
      />
      <div class="image-caption static" *ngIf="selected">
        “Alan Richards photographer. From the Institute for Advanced Study,
        NJ, USA.”
      </div>
    </div>
    </div>
    <div class="slide-right">
      <div class="slide-inner-right">
      <div class="slide-caption">Tipni si, komu patril tento stôl</div>
      <ul class="box-list box-list--center">
        <li
          class="box"
          [class.selected]="selected?.id === person.id"
          *ngFor="let person of persons"
          (click)="select(person)"
        >
          <div class="person">
            <div class="image">
              <img [src]="person.image" alt="" />
              <app-tooltip class="tip">{{ person.info }}</app-tooltip>
            </div>
            <div class="person-name">{{ person.name }}</div>
          </div>
        </li>
      </ul>
      <div class="center actions">
        <app-button *ngIf="selected" (clickHandle)="next()" text="Vyhodnotenie"></app-button>
      </div>
    </div>
    </div>
  </div>
  <div class="element element--bottom"></div>
  <div class="element element--top"></div>
</div>

<div @slidesAnimation *ngIf="step === 2" class="slide">


  <div class="slide-grid">
    <div class="slide-left">
      <div class="slide-inner-left">
        <div class="badge-container">
          <div *ngIf="correctAnswer" class="badge badge--success">
            Tvoja odpoveď je správna
          </div>
          <div *ngIf="!correctAnswer" class="badge badge--error">
            Tvoja odpoveď nie je správna
          </div>
        </div>
      <h1 class="title title--md">Správna odpoveď je Albert Einstein.</h1>
      <div class="subtitle">
        Stôl v pracovni profesora Einsteina v
        Inštitúte pre pokročilé štúdiá, Princeton.

      </div>
      <app-button (click)="navigate()" text="Pokračuj ďalej"></app-button>
      </div>
    </div>
    <div class="slide-right flex-start">
      <div class="slide-inner-right">
      <div  class="table-result" >
      <div class="result-person">
        <div class="box box--no-cursor">
          <div class="person">
            <div class="image">
              <img [src]="persons[1]?.image" alt="" />
              <app-tooltip class="tip">{{ persons[1]?.info }}</app-tooltip>
            </div>
            <div class="person-name">{{ persons[1]?.name }}</div>
          </div>
        </div>
        </div>
        <div class="image-caption">
          “Alan Richards photographer. From the Institute for Advanced Study,
          NJ, USA.”
        </div>
        </div>

      </div>

    </div>

  </div>
  <div class="element element--bottom"></div>
  <div class="element element--top"></div>
</div>
