import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-sodom-intro',
  styleUrls: ['./sodom-intro.component.scss'],
  template: `
  <div class="intro">
    <h1 class="title">„Vitaj v Sodome – tvoj smartfón je už tu“</h1>
    <div class="subtitle">
    Skládka Agbogbloshie v meste Akkra (Ghana) sa pokladá za jedno z najtoxickejších miest na Zemi.

    </div>
    <app-button  (click)="next()" text="Pokračuj" ></app-button>
  </div>
  <div class="black-top"></div>
  <div class="black-bottom"></div>
`,
})
export class SodomIntroComponent implements OnInit {

  @Output()
  nextSlide: EventEmitter<number> = new EventEmitter<number>();
  constructor() { }

  ngOnInit(): void {
  }

  next(): void {
    this.nextSlide.emit(15);
  }
}
