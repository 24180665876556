import {
  trigger,
  animate,
  style,
  transition,
} from '@angular/animations';


export const slidesAnimation = trigger('slidesAnimation', [
  transition(':enter', [
    style({
      transform: 'translateY(100%)',
      opacity: 1,
    }),
    animate(
      '800ms  cubic-bezier(0.83, 0, 0.17, 1)',
      style({ transform: 'translateY(0)', opacity: 1 })
    ),
  ]),
  transition(':leave', [
    style({
      transform: 'translateY(0)',
      opacity: 1,
    }),
   // query('button', style({ transform: 'translateY(200px)', opacity: 0 })),
    animate(
      '500ms cubic-bezier(0.83, 0, 0.17, 1)',
      style({ transform: 'translateY(-200%)', opacity: 1 })
    ),
  ]),
]);

export const slideAnimation = trigger('slider', []);
