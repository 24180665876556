import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-electronics-story-movie',
  templateUrl: './electronics-story-movie.component.html',
  styleUrls: ['./electronics-story-movie.component.scss'],
})
export class ElectronicsStoryMovieComponent implements OnInit {

  public videoEnded: boolean;

  @Output()
  nextSlide: EventEmitter<number> = new EventEmitter<number>();

  constructor() { }

  ngOnInit(): void {
  }

  next(): void  {
    this.nextSlide.emit(11);
  }

  videoEnd(): void {
    this.videoEnded = true;
  }

}
