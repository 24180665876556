import { AfterViewInit, Component, Inject, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { style } from '@angular/animations';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-final',
  templateUrl: './final.component.html',
  styleUrls: ['./final.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class FinalComponent implements OnInit, AfterViewInit {

  constructor(@Inject(DOCUMENT) private _document: HTMLDocument) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
   this._document.querySelectorAll('.slides').forEach( (elem) => {
    elem.classList.add('final-slides');
   });

  }

}
