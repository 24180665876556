<div class="slide-grid">
  <div class="slide-left">
    <div class="slide-inner-left">
      <h1 class="title">Príbeh elektroniky</h1>
      <div class="subtitle">
        Audit elektroniky nám ukázal, že spotrebiče
využívame v každej miestnosti. Výrobcovia ich
produkujú a my ich nakupujeme. <br/>

„Životný cyklus“ elektroniky je však
jednoducho lineárny. Ako to myslíme?<br/>
Pozri si nasledujúci krátky príbeh.



      </div>
      <app-button (click)="next()" text="Pozrieť príbeh" ></app-button>
    </div>

  </div>
  <div class="slide-right">
    <div class="slide-inner-right">

    </div>
  </div>
</div>


<div class="black-top"></div>

