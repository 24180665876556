<div class="dilemy">
  <ng-container *ngIf="step === 1">
  <div class="slide-label">
    Zvoľ vhodné riešenie pre svoj počítač od začiatku až po koniec jeho životnosti.
Postupuj od najlepšieho po najhoršie riešenie.


  </div>

  <div cdkDropListGroup class="list-grid">
    <div class="list-arrow"></div>
    <ul class="list">
      <ng-container *ngFor="let item of doneList; let j = index;">
        <li
          class="list-item item-dropped"
          cdkDropList
          [cdkDropListData]="{ name: item.name, note: item.note, index: j }"
          (cdkDropListDropped)="drop($event, j)"
          [class.disabled]="item.disabled && item.name !== 'Prilepiť txt…' "

        >
          <span [ngStyle]="{ 'background-color': item.color }">{{j+1}}.</span>
          <div class="drag-item" cdkDrag [cdkDragDisabled]="!item.disabled"  >
              <div class="drag-content">
                {{ item.name }}
              </div>

            <app-tooltip [offset]="true" position="left" class="info" *ngIf="item.note">
              <div [innerHTML]="item.note"></div>
            </app-tooltip>
          </div>
        </li>
      </ng-container>
    </ul>

    <ul class="list list-options" cdkDropList [cdkDropListData]="options">
    <ng-container *ngFor="let item of options; let j = index">
      <li cdkDrag [cdkDragDisabled]="item.name === '' " class="list-item">{{item.name}}</li>
    </ng-container>
    </ul>
  </div>

  <div class="center">
    <app-button *ngIf="showStepbutton"  (click)="result()" text="Vyhodnotiť" ></app-button>
  </div>
</ng-container>
<ng-container *ngIf="step === 2">

  <div class="slide-label center">
    Správne zoradenie
  </div>

  <div class="list-result-wrap">
  <ul class="list list-result" >
    <ng-container *ngFor="let item of results; let j = index">

      <li   class="list-item item-{{j}}">
        <span [ngStyle]="{ 'background-color': item.color }">{{j+1}}.</span>
        {{item.name}}</li>
    </ng-container>
    </ul>
  </div>
  <div class="center">
    <app-button  (click)="next()" text="Pokračuj" ></app-button>
  </div>
</ng-container>
</div>
