import { Component, Input, OnInit, Output, EventEmitter, AfterViewInit } from '@angular/core';
import gsap from 'gsap/all';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit, AfterViewInit {
  @Input()
  text  = '';
  timeline: TimelineMax;
  @Output()
  clickHandle: EventEmitter<any> = new EventEmitter();
  constructor() {
    this.timeline = gsap.timeline();
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
   this.timeline.fromTo('.btn',
   { y: 50, opacity: 0},
   { y: 0, opacity: 1, duration: 1,  ease: 'elastic.out(1, 0.3)'});
  }
  next(): void {
    this.clickHandle.emit();
  }
}
