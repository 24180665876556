import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'app-tooltip',
  styleUrls: ['./tooltip.component.scss'],
  template: `
    <div #icon class="icon icon-info">
      <span>i</span>
      <div #tooltip class="tooltip" [class]="position">
        <div><ng-content> </ng-content></div>
      </div>
    </div>
  `,
})
export class TooltipComponent implements OnInit, AfterViewInit {
  constructor(public renderer: Renderer2) {}

  @Input()
  offset: boolean;

  @Input()
  position = 'top';

  @ViewChild('icon', { read: ElementRef })
  icon: ElementRef;

  @ViewChild('tooltip', { read: ElementRef })
  tooltip: ElementRef;

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    if (this.offset) {
    this.renderer.setStyle(this.tooltip.nativeElement, 'position', 'fixed');
    }
  }
  @HostListener('mouseenter', ['$event'])
  onMouseEnter(event: MouseEvent): void {
    const width = this.tooltip.nativeElement.clientWidth;
    const height = this.tooltip.nativeElement.clientHeight;
    const {top, left} = this.icon.nativeElement.getBoundingClientRect();
    if (this.offset) {
      if(this.position === 'left') {
        this.renderer.setStyle(this.tooltip.nativeElement, 'left', `${left + 35}px`);
        this.renderer.setStyle(this.tooltip.nativeElement, 'top', `${top - (height / 2) + 20}px`);
      } else {
      this.renderer.setStyle(this.tooltip.nativeElement, 'left', `${left - width / 2 + 15}px`);
      this.renderer.setStyle(this.tooltip.nativeElement, 'top', `${top - 10 - height}px`);
      }
    } else {
      this.renderer.setStyle(
        this.tooltip.nativeElement,
        'top',
        `-${height + 10}px`
      );
    }
  }
}
