<div class="slide-grid">
  <div class="slide-left">
    <div class="slide-inner-left">
      <div class="slide-label">Audit elektroniky</div>
      <h1 class="title">Elektronika <br/>u nás doma</h1>
      <div class="subtitle">
        Neostaneme len pri stole. <br/> Prejdi do ďalších miestností. <br> Aké spotrebiče doma máte?
      </div>
      <app-button (click)="next()" text="Vyber z ponuky" ></app-button>
    </div>

  </div>
  <div class="slide-right">
    <div class="slide-inner-right">
    <img #banner class="banner" src="/assets/electronics-home.svg" alt="" />
    </div>
  </div>
</div>


<div class="black-top"></div>
<div class="black-bottom"></div>

