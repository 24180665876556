import { AfterViewInit, Component, EventEmitter, OnInit, Output } from '@angular/core';
import gsap from 'gsap';

@Component({
  selector: 'app-sodom-video',
  styleUrls: ['./sodom-video.component.scss'],
  template: `
  <div class="video-slide">
   <div class="video-wrap">
   <video  controls autoplay laysinline  webkit-playsinline poster="/assets/sodom-poster.png"  (ended)="videoEnd()">
    <source src="/assets/electronic-story.mp4" type="video/mp4">
    Your browser does not support the video tag.
  </video>
   </div>

  <div class="slide-label">
  Každý rok tu skončí ilegálne 250-tisíc ton elektroodpadu - vytriedených počítačov, televízorov,
smartfónov a iných elektrozariadení z digitalizovaného a elektrifikovaného sveta.

  </div>
  <div class="action">
    <app-button (click)="next()" text="Pokračuj"></app-button>
  </div>
</div>
`
})

export class SodomVideoComponent implements OnInit, AfterViewInit {
  public videoEnded: boolean;
  @Output()
  nextSlide: EventEmitter<number> = new EventEmitter<number>();
  timeline: TimelineMax;
  constructor() {
    this.timeline = gsap.timeline();
   }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.timeline.set('.action', {
        y: '100%',
        opacity: 0
    });
  }
  next(): void  {
    this.nextSlide.emit(16);
  }

  videoEnd(): void {
    this.timeline.to('.action', {
        y: 0,
        opacity: 1,
        duration: 1, ease: 'elastic.out(1, 0.3)'
    });
  }

}
