<div class="blacktop"></div>
  <div class="slide-grid">
 <div class="slide-left">
   <div class="slide-inner-left">
    <h1 class="title title--md">Ako vyzerá tvoj pracovný stôl?</h1>
    <div class="subtitle">
      Čo sa na ňom bežne nachádza?
    </div>
    <app-button (click)="next()" text="Usporiadaj stôl" ></app-button>
   </div>

  </div>
  <div class="slide-right">
    <div class="slide-inner-right">
    <img class="banner" src="/assets/your-table-ill.svg" alt="" />
    </div>
  </div>
</div>

