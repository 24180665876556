import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { CdkDragDrop, copyArrayItem } from '@angular/cdk/drag-drop';
import gsap from 'gsap';
interface Options {
  name: string;
  index?: number;
  color?: string;
  disabled?: boolean;
  note?: string;
  order: number;
}
@Component({
  selector: 'app-spotreb-dilemy',
  templateUrl: './spotreb-dilemy.component.html',
  styleUrls: ['./spotreb-dilemy.component.scss'],
})
export class SpotrebDilemyComponent implements OnInit {
  public timeline: TimelineMax;
  public timelineItem: TimelineMax;
  public step = 1;
  public showStepbutton: boolean;
  public options: Options[] = [
    {
      name: 'Nefunkčný počítač dám opraviť.',
      order: 2,
      note: `

    <p>Podľa novej legislatívy EÚ by mal byť každý spotrebič opraviteľný a náhradné diely musia byť dostupné po presne stanovený čas.</p>
    <p>Nové pravidlá pre "ekodizajn" platia od r. 2021 pre všetky spotrebiče uvedené na európsky trh. Opatrenia usporia toľko energie do r. 2030,
    koľko minie napr. celé Dánsko za rok. Zredukuje sa 46 mil. ton CO2 a domácnosť ušetrí až 150 EUR ročne. Návody na opravu rôznych výrobkov ponúka napr. stránka www.ifixit.com.
     V zahraničí sú moderné aj tzv. Repair café, stretnutia dobrovoľníkov s cieľom opraviť pokazené spotrebiče.
     </p>


    `,
    },
    {
      name: 'Používam počítač šetrne, čo najdlhšie.',
      order: 1,
      note: `
      <p>Ak sa o svoj počítač staráš, ušetríš najviac energie a prírodných zdrojov na výrobu nového produktu.</p>
      <p>Ako predĺžiš život svojmu počítaču:</p>
      <ul>

    <li> pravidelne ho čisti</li>
    <li>počítač sa skladá z množstva malých, pohyblivých častí - nejedz a nepi nad ním</li>
    <li>nezatváraj notebook príliš často</li>
    <li>nenechávaj notebook zapojený do zásuvky</li>
    <li>aktualizuj  softvér a vykonávaj pravidelnú údržbu</li>
    </ul>
    `,
    },
    {
      name: 'Počítač je skládkovaný v našej krajine.',
      order: 9,
      note: `
  <img src="/assets/skladka.jpg" />
  <p>Komunálny odpad je vzhľadom na rôznosť svojho zloženia nebezpečný. Pritom väčšinu odpadu, ktorá
  týmto spôsobom ničí krajinu, je možné po vytriedení kompostovať a recyklovať. </p>
  <p>Nebezpečné je
  nekontrolované horenie bioodpadu na skládke v kombinácií s elektroodpadom, kedy toxické plyny
  unikajú do ovzdušia.</p>

`,
    },

    {
      name: 'Nepotrebný funkčný počítač predám alebo darujem.',
      order: 3,
      note: `

  <p>Nepotrebný, ale funkčný počítač či iný elektrospotrebič môže urobiť službu kamarátovi, inému členovi rodiny, azylovým či krízovým centrám, alebo požičovniam,
  ako je <a href="https://www.goethe.de/ins/sk/sk/kul/sup/ajn.html">www.goethe.de</a>.</p>
  <p>V Českej republike funguje úspešne projekt www.jsemzpet.cz, kde odborníci skontrolujú a transparentne darujú vyradený funkčný spotrebič tomu, kto ho potrebuje. </p>


  `,
    },

    {
      name: 'Nefunčkný počítač si nechám doma.',
      order: 6,
      note: `
    <p>S nefunkčným počítačom nechávaš doma aj vzácne suroviny, ktoré sa môžu opätovne využiť.</p>
`,
    },
    {
      name: 'Počítač vyhodím do netriedeného odpadu.',
      order: 7,
      note: `
  <p>Elektroodpad nepatrí do zmiešaného, komunálneho odpadu.  V nákupnej cene výrobku platíme už recyklačný poplatok, ktorý slúži na nakladanie s odpadom. </p>
  <p>Ak nie je počítač možné opraviť, odovzdaj ho v elektropredajniach (spätný zber) na recykláciu alebo predaj špecializovaným obchodom zameraným na výkup výpočtovej techniky.
  </p>
`,
    },
    {
      name: 'Počítač je odvezený do spaľovne.',
      order: 8,
      note: `
      <img src="/assets/spalovna.jpg" />
      <p>Tu elektronika zhorí aj so vzácnymi surovinami, ktoré bolo možné recykláciou získať a znova použiť. </p>
      <p> Aj napriek tomu, že v spaľovni je celý proces pod kontrolou a unikajúce spaliny a popolček sú do značnej miery zachytávané, je "spaľovanie" jeden z najhorších spôsobov nakladania s elektroodpadom.
      </p>
  `,
    },
    {
      name: 'Odovzdám nefunkčný počítač na recykláciu.',
      order: 5,
      note: `
      <img src="/assets/recyklacia.jpg" />
      <p>Podľa štatistík sa vo svete recykluje len 20 % elektroodpadu. Na Slovensku je to podľa Eurostat približne 50 %. </p>
      <p>Recyklácia nestíha dobiehať obrovskú produkciu odpadu.  </p>
      <p>Aj napriek prínosom v druhotnom získavaní surovín, sama má vplyv na životné prostredie a je energeticky náročná. Riešením problému s odpadom je zníženie spotreby. </p>
      <p>Obrázok ukazuje výsledok recyklácie: zlato, meď, paládium, platina, ale tiež ťažké kovy, sklo, plasty, ktoré treba oddeliť, opätovne využiť alebo bezpečne zlikvidovať. </p>

`,
    },

    {
      name: 'Počítač je vyvezený do cudzej krajiny, napr. Ghana.',
      order: 10,
      note: `
    <p>Štúdia BAN (Basen Action Network) zistila, že z 314 sledovaných elektrozariadení putovalo 11 (3,5 %)
    pravdepodobne ilegálne do rozvojových krajín ako je Ghana. </p>
    <p>Pri celkovej produkcií elektroodpadu je toto číslo alarmujúce. Pozri si nasledujúce krátke video.</p>

  `,
    },
    {
      name: 'Uprednostním kúpu počítača z druhej ruky.',
      order: 4,
      note: `
  Nákup z druhej ruky je lacnejší a ekologickejší.
   Nakupuj od človeka s dobrými referenciami, ideálne na zmluvu,
    po dôkladom oboznámení sa so stavom spotrebiča, ak je ešte v záruke, tak s bločkom a záručným listom. Celý nákup si dôkladne zdokumentuj

  `,
    },
  ];

  public doneList: Options[] = [
    { name: 'Prilepiť txt…', color: '#55B961', order: 1 },
    { name: 'Prilepiť txt…', color: '#9ACC5D', order: 2 },
    { name: 'Prilepiť txt…', color: '#9ACC5D', order: 3 },
    { name: 'Prilepiť txt…', color: '#9ACC5D', order: 4 },
    { name: 'Prilepiť txt…', color: '#FDAE2E', order: 5 },
    { name: 'Prilepiť txt…', color: '#EA9096', order: 6 },
    { name: 'Prilepiť txt…', color: '#EA9096', order: 7 },
    { name: 'Prilepiť txt…', color: '#EA9096', order: 8 },
    { name: 'Prilepiť txt…', color: '#EA9096', order: 9 },
    { name: 'Prilepiť txt…', color: '#DD4D57', order: 10 },
  ];
  public results: Options[] = [...this.options]
    .sort((a, b) => {
      if (a.order > b.order) {
        return 1;
      }
      return -1;
    })
    .map((item, i) => ({
      ...item,
      color: this.doneList.find((l) => l.order === item.order)?.color,
    }))

  @Output()
  nextSlide: EventEmitter<number> = new EventEmitter<number>();
  constructor(private renderer: Renderer2) {
    this.timeline = gsap.timeline();
    this.timelineItem = gsap.timeline();
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.timeline.to('.list-arrow', {
        x: -20,
        repeat: -1,
        yoyo: true,
        duration: 1,
      });
    });
  }

  public trackItem(index: number, item: Options): string | undefined {
    return item.color;
  }

  result(): void {
    this.step = 2;
  }
  next(): void {
    this.nextSlide.emit(14);
  }

  drop(event: CdkDragDrop<any>, index: number): void {
    const newItem = this.options[event.previousIndex];
    if (event.previousContainer.element.nativeElement.tagName === 'LI') {
      const prevColor = this.doneList[event.previousContainer.data.index].color;
      const prevNote = this.doneList[event.previousContainer.data.index]?.note;
      const prevName = this.doneList[event.previousContainer.data.index]?.name;
      const containerColor = this.doneList[event.container.data.index].color;
      this.doneList[event.previousContainer.data.index] = {
        ...event.container.data,
        color: prevColor,
        note: '',
        disabled: true

      };
      this.doneList[event.container.data.index] = {
        ...event.previousContainer.data,
        color: containerColor,
        disabled: true,
        note: prevNote,
      };
    }

    if (
      event.previousContainer.element.nativeElement.tagName === 'UL' &&
      this.doneList[index].name === 'Prilepiť txt…'
    ) {
      copyArrayItem(
        this.options,
        [this.doneList[index]],
        event.previousIndex,
        event.currentIndex
      );
      const color = this.doneList[index].color;
      this.doneList[index] = {
        ...newItem,
        color,
        disabled: true,
      };

      this.options[event.previousIndex].name = '';
    }
    this.showStepbutton = this.options.every((item) => item.name === '');
  }
}
