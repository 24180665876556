<div @slidesAnimation *ngIf="slide === 'quiz' " class="quiz-slide">
<div class="slide-grid">
  <div class="slide-left">
    <div class="slide-inner-left slide-inner-left--start">
      <ng-container *ngIf="quizNumber === 0">
      <h3>
        Vieš, koľko elektroodpadu vytvorili ľudia v roku
      </h3>
      <h1 class="title" ng-i>2019<span class="primary">?</span></h1>
      </ng-container>
      <ng-container *ngIf="quizNumber === 1">
        <h3>
          Skús predpovedať produkciu odpadu v roku
        </h3>
        <h1 class="title" ng-i>2030<span class="primary">.</span></h1>
        </ng-container>
    </div>
  </div>
  <div class="slide-right">
    <div class="slide-inner-right">
      <div class="items-box">
        <div class="items-box-inner">
          <ul class="items">
            <li class="item"
            [class.selected]="item.selected"
            *ngFor="let item of currentQuiz">
              <div class="item-inner"  (click)="select(item)">
                <div class="check" [class.selected]="item.selected"></div>
                <span>
                 {{item.name}}
                </span>
              </div>
            </li>

          </ul>
        </div>
      </div>
      <app-button *ngIf="showQuizButton"  (click)="result()" text="Vyhodnotiť" ></app-button>
    </div>
  </div>
</div>
<div class="bottom-ill"></div>
<div class="black-bottom"></div>
</div>

<div @slidesAnimation *ngIf="slide === 'result' " class="slide-result">
<div class="slide-grid">
  <div class="slide-left">
    <div class="slide-inner-left">
      <div class="badge-container">
        <div *ngIf="correct" class="badge badge--success">
          Tvoja odpoveď je správna
        </div>
        <div *ngIf="!correct" class="badge badge--error">
          Tvoja odpoveď je nesprávna
        </div>
      </div>
      <h1 class="title title--sm text-white"><ng-container *ngIf="quizNumber === 0">V roku 2019 bolo vytvorených približne 54 miliónov ton elektroodpadu.
        </ng-container><ng-container *ngIf="quizNumber === 1">V roku 2030 bude predpokladaná produkcia elektroodpadu približne 75 miliónov ton.
        </ng-container>
      </h1>
      <div class="subtitle text-white">
        <ng-container *ngIf="quizNumber === 0">
          Ak by tento odpad tvorili iba notebooky, znamenalo by to, že každú sekundu je vo svete do odpadu hodených 607 kusov.
          <br>Predstav si, koľko je to kusov, kým si dočítal tento text.
        </ng-container>
        <ng-container *ngIf="quizNumber === 1">
          Aj napriek rôznym aplikáciám a multifunkčným zariadeniam spotreba elektroniky a produkcia odpadu narastá.
          Prepočet odhadu na obyčajný notebook uvádza, že v koši skončí zhruba 838 kusov za sekundu.
        </ng-container>
      </div>

      <div class="actions">
        <app-button *ngIf="quizNumber === 0" (click)="nextQuestion()" text="Ďalšia otázka" ></app-button>
        <app-button *ngIf="quizNumber === 1" (click)="next()" text="Pokračuj" ></app-button>
      </div>
    </div>
  </div>
  <div class="slide-right">
    <div class="slide-inner-right">
      <img src="/assets/quiz-result-trash.svg" class="results-trash second" alt="">
      <img src="/assets/quiz-result-trash.svg" class="results-trash first" alt="">

      <div class="clock">
        <svg xmlns="http://www.w3.org/2000/svg" width="325" height="325" viewBox="0 0 325 325">
          <g id="Group_1632" data-name="Group 1632" transform="translate(1413.063 -5695.438)">
            <circle id="Ellipse_328" data-name="Ellipse 328" cx="162.5" cy="162.5" r="162.5" transform="translate(-1413.063 5695.438)" fill="#fff"/>
            <g id="Group_1631" data-name="Group 1631" transform="translate(-1383.839 5724.66)">
              <g id="Group_1630" data-name="Group 1630">
                <path id="Path_946" data-name="Path 946" d="M-1214.634,5731.75a12.116,12.116,0,0,1,12.116,12.116,12.116,12.116,0,0,1-12.116,12.116,12.116,12.116,0,0,1-12.116-12.116,12.116,12.116,0,0,1,12.116-12.116" transform="translate(1347.911 -5731.75)"/>
                <path id="Path_947" data-name="Path 947" d="M-1214.634,6031.749a12.117,12.117,0,0,1,12.116,12.116,12.116,12.116,0,0,1-12.116,12.116,12.116,12.116,0,0,1-12.116-12.116,12.117,12.117,0,0,1,12.116-12.116" transform="translate(1347.911 -5789.427)"/>
                <path id="Path_948" data-name="Path 948" d="M-1301.294,5760.838a12.117,12.117,0,0,1,14.842-8.566,12.118,12.118,0,0,1,8.566,14.842,12.118,12.118,0,0,1-14.842,8.566,12.118,12.118,0,0,1-8.566-14.842" transform="translate(1362.323 -5735.616)"/>
                <path id="Path_949" data-name="Path 949" d="M-1145.692,6013.276a12.115,12.115,0,0,0-4.435,16.551,12.115,12.115,0,0,0,16.551,4.434,12.115,12.115,0,0,0,4.434-16.551,12.116,12.116,0,0,0-16.551-4.434" transform="translate(1333.492 -5785.564)"/>
                <path id="Path_950" data-name="Path 950" d="M-1347.629,5807.154a12.118,12.118,0,0,1,14.842,8.566,12.118,12.118,0,0,1-8.566,14.842,12.117,12.117,0,0,1-14.842-8.566,12.118,12.118,0,0,1,8.566-14.842" transform="translate(1372.878 -5746.167)"/>
                <path id="Path_951" data-name="Path 951" d="M-1078.672,5958.373a12.117,12.117,0,0,0-16.551,4.435,12.116,12.116,0,0,0,4.435,16.551,12.115,12.115,0,0,0,16.55-4.435,12.116,12.116,0,0,0-4.434-16.551" transform="translate(1322.936 -5775.009)"/>
                <path id="Path_952" data-name="Path 952" d="M-1364.634,5881.75a12.116,12.116,0,0,1,12.116,12.115,12.117,12.117,0,0,1-12.116,12.117,12.117,12.117,0,0,1-12.116-12.117,12.116,12.116,0,0,1,12.116-12.115" transform="translate(1376.75 -5760.589)"/>
                <path id="Path_953" data-name="Path 953" d="M-1064.634,5881.75a12.116,12.116,0,0,1,12.116,12.115,12.117,12.117,0,0,1-12.116,12.117,12.117,12.117,0,0,1-12.116-12.117,12.116,12.116,0,0,1,12.116-12.115" transform="translate(1319.072 -5760.589)"/>
                <path id="Path_954" data-name="Path 954" d="M-1356.193,5965.783a12.118,12.118,0,0,1,14.842-8.565,12.117,12.117,0,0,1,8.566,14.843,12.118,12.118,0,0,1-14.842,8.565,12.119,12.119,0,0,1-8.566-14.843" transform="translate(1372.878 -5775.019)"/>
                <path id="Path_955" data-name="Path 955" d="M-1078.672,5829.358a12.114,12.114,0,0,0,4.434-16.55,12.114,12.114,0,0,0-16.55-4.436,12.116,12.116,0,0,0-4.435,16.551,12.116,12.116,0,0,0,16.551,4.435" transform="translate(1322.936 -5746.169)"/>
                <path id="Path_956" data-name="Path 956" d="M-1292.684,6012.063a12.118,12.118,0,0,1,14.842,8.566,12.118,12.118,0,0,1-8.566,14.843,12.118,12.118,0,0,1-14.842-8.567,12.118,12.118,0,0,1,8.566-14.842" transform="translate(1362.314 -5785.563)"/>
                <path id="Path_957" data-name="Path 957" d="M-1133.576,5753.469a12.116,12.116,0,0,0-16.55,4.435,12.116,12.116,0,0,0,4.434,16.551,12.116,12.116,0,0,0,16.551-4.435,12.116,12.116,0,0,0-4.434-16.551" transform="translate(1333.491 -5735.613)"/>
              </g>
              <path id="hand" data-name="Path 958" d="M-1229.62,5848.865a36.181,36.181,0,0,0-23.6,8.734l-33.3-19.227a12.113,12.113,0,0,0-16.551,4.435,12.116,12.116,0,0,0,4.434,16.551l33.285,19.216a36.362,36.362,0,0,0-.619,6.639,36.39,36.39,0,0,0,36.348,36.35,36.391,36.391,0,0,0,36.348-36.35,36.39,36.39,0,0,0-36.348-36.348m0,48.465a12.13,12.13,0,0,1-12.116-12.117,12.025,12.025,0,0,1,1.175-5.168c.187-.271.368-.549.536-.838s.31-.586.451-.88a12.1,12.1,0,0,1,9.955-5.229,12.13,12.13,0,0,1,12.116,12.115,12.13,12.13,0,0,1-12.116,12.117" transform="translate(1362.896 -5751.937)" fill="#ed1c24"/>
            </g>
          </g>
        </svg>

      </div>

    </div>
  </div>
</div>
</div>
