 <div class="slide-grid">
    <div class="slide-left">

      <div class="slide-inner-left">
        <h1 class="title">Tajný život elektroniky</h1>
        <div class="subtitle">
          Čo sa pri nákupe nedozvieš
        </div>

        <app-button class="button" (click)="next()" text="Spustiť kurz"></app-button>
      </div>

    </div>
    <div class="slide-right">
      <div class="slide-inner-right">
       <div #banner class="banner">

        <svg xmlns="http://www.w3.org/2000/svg"   viewBox="0 0 754.497 443.304">
          <g id="Group_459" data-name="Group 459" transform="translate(-949.022 -391.678)">
            <g id="Group_458" data-name="Group 458" transform="translate(-1092 22160.568)">
              <g id="Group_430" data-name="Group 430" transform="translate(2041.022 -21805.908)">
                <g id="Group_451" data-name="Group 451" transform="translate(330.817 37.018)">
                  <rect id="Rectangle_380" data-name="Rectangle 380" width="372.552" height="290.532" rx="14.732" transform="translate(18.808 20.502)" fill="#00a7ff"/>
                  <rect id="Rectangle_381" data-name="Rectangle 381" width="337.557" height="222" transform="translate(36.306 70.383)" fill="#0f4751"/>
                  <g id="Group_438" data-name="Group 438" transform="translate(40.664)">
                    <g id="Group_431" data-name="Group 431">
                      <circle id="Ellipse_78" data-name="Ellipse 78" cx="10.223" cy="10.223" r="10.223" transform="translate(0 31.952)" fill="#0f4751"/>
                      <rect id="Rectangle_382" data-name="Rectangle 382" width="12.178" height="48.963" rx="6.089" transform="translate(4.135)" fill="#001441"/>
                    </g>
                    <g id="Group_432" data-name="Group 432" transform="translate(51.399)">
                      <circle id="Ellipse_79" data-name="Ellipse 79" cx="10.223" cy="10.223" r="10.223" transform="translate(0 31.952)" fill="#0f4751"/>
                      <rect id="Rectangle_383" data-name="Rectangle 383" width="12.178" height="48.963" rx="6.089" transform="translate(4.135)" fill="#001441"/>
                    </g>
                    <g id="Group_433" data-name="Group 433" transform="translate(102.798)">
                      <circle id="Ellipse_80" data-name="Ellipse 80" cx="10.223" cy="10.223" r="10.223" transform="translate(0 31.952)" fill="#0f4751"/>
                      <rect id="Rectangle_384" data-name="Rectangle 384" width="12.178" height="48.963" rx="6.089" transform="translate(4.135)" fill="#001441"/>
                    </g>
                    <g id="Group_434" data-name="Group 434" transform="translate(154.197)">
                      <circle id="Ellipse_81" data-name="Ellipse 81" cx="10.223" cy="10.223" r="10.223" transform="translate(0 31.952)" fill="#0f4751"/>
                      <rect id="Rectangle_385" data-name="Rectangle 385" width="12.178" height="48.963" rx="6.089" transform="translate(4.135)" fill="#001441"/>
                    </g>
                    <g id="Group_435" data-name="Group 435" transform="translate(205.596)">
                      <circle id="Ellipse_82" data-name="Ellipse 82" cx="10.223" cy="10.223" r="10.223" transform="translate(0 31.952)" fill="#0f4751"/>
                      <rect id="Rectangle_386" data-name="Rectangle 386" width="12.178" height="48.963" rx="6.089" transform="translate(4.135)" fill="#001441"/>
                    </g>
                    <g id="Group_436" data-name="Group 436" transform="translate(256.995)">
                      <circle id="Ellipse_83" data-name="Ellipse 83" cx="10.223" cy="10.223" r="10.223" transform="translate(0 31.952)" fill="#0f4751"/>
                      <rect id="Rectangle_387" data-name="Rectangle 387" width="12.178" height="48.963" rx="6.089" transform="translate(4.135)" fill="#001441"/>
                    </g>
                    <g id="Group_437" data-name="Group 437" transform="translate(308.395)">
                      <circle id="Ellipse_84" data-name="Ellipse 84" cx="10.223" cy="10.223" r="10.223" transform="translate(0 31.952)" fill="#0f4751"/>
                      <rect id="Rectangle_388" data-name="Rectangle 388" width="12.178" height="48.963" rx="6.089" transform="translate(4.135)" fill="#001441"/>
                    </g>
                  </g>
                  <path id="Path_366" data-name="Path 366" d="M0,0H337.557V222H0Z" transform="translate(36.306 70.383)" fill="#0045ec"/>
                  <g id="Group_440" data-name="Group 440" transform="translate(86.611 70.383)" opacity="0.3">
                    <g id="Group_439" data-name="Group 439">
                      <line id="Line_207" data-name="Line 207" y2="222" fill="none" stroke="#00c3b9" stroke-miterlimit="10" stroke-width="3.683"/>
                      <line id="Line_208" data-name="Line 208" y2="222" transform="translate(47.389)" fill="none" stroke="#00c3b9" stroke-miterlimit="10" stroke-width="3.683"/>
                      <line id="Line_209" data-name="Line 209" y2="222" transform="translate(94.778)" fill="none" stroke="#00c3b9" stroke-miterlimit="10" stroke-width="3.683"/>
                      <line id="Line_210" data-name="Line 210" y2="222" transform="translate(142.168)" fill="none" stroke="#00c3b9" stroke-miterlimit="10" stroke-width="3.683"/>
                      <line id="Line_211" data-name="Line 211" y2="222" transform="translate(189.557)" fill="none" stroke="#00c3b9" stroke-miterlimit="10" stroke-width="3.683"/>
                      <line id="Line_212" data-name="Line 212" y2="222" transform="translate(236.946)" fill="none" stroke="#00c3b9" stroke-miterlimit="10" stroke-width="3.683"/>
                    </g>
                  </g>
                  <g id="Group_442" data-name="Group 442" transform="translate(36.305 115.769)" opacity="0.3">
                    <g id="Group_441" data-name="Group 441">
                      <line id="Line_213" data-name="Line 213" x1="337.558" transform="translate(0 131.229)" fill="none" stroke="#00c3b9" stroke-miterlimit="10" stroke-width="3.683"/>
                      <line id="Line_214" data-name="Line 214" x1="337.558" transform="translate(0 87.486)" fill="none" stroke="#00c3b9" stroke-miterlimit="10" stroke-width="3.683"/>
                      <line id="Line_215" data-name="Line 215" x1="337.558" transform="translate(0 43.743)" fill="none" stroke="#00c3b9" stroke-miterlimit="10" stroke-width="3.683"/>
                      <line id="Line_216" data-name="Line 216" x1="337.558" fill="none" stroke="#00c3b9" stroke-miterlimit="10" stroke-width="3.683"/>
                    </g>
                  </g>
                  <g id="Group_444" data-name="Group 444" transform="translate(0 110.542)">
                    <rect id="Rectangle_390" data-name="Rectangle 390" width="114.828" height="52.857" rx="5.524" fill="#4ad3ea"/>
                    <g id="Group_443" data-name="Group 443" transform="translate(16.321 18.591)">
                      <line id="Line_217" data-name="Line 217" x2="49.941" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="5.524"/>
                      <line id="Line_218" data-name="Line 218" x2="69.361" transform="translate(0 15.675)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="5.524"/>
                    </g>
                  </g>
                  <g id="Group_446" data-name="Group 446" transform="translate(76.138 199.488)">
                    <rect id="Rectangle_391" data-name="Rectangle 391" width="114.828" height="52.857" rx="5.524" fill="#ffcb46"/>
                    <g id="Group_445" data-name="Group 445" transform="translate(16.321 18.591)">
                      <line id="Line_219" data-name="Line 219" x2="49.941" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="5.524"/>
                      <line id="Line_220" data-name="Line 220" x2="69.361" transform="translate(0 15.675)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="5.524"/>
                    </g>
                  </g>
                  <g id="Group_448" data-name="Group 448" transform="translate(220.128 272.079)">
                    <rect id="Rectangle_392" data-name="Rectangle 392" width="114.828" height="52.857" rx="5.524" fill="#4ad3ea"/>
                    <g id="Group_447" data-name="Group 447" transform="translate(16.321 18.591)">
                      <line id="Line_221" data-name="Line 221" x2="49.941" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="5.524"/>
                      <line id="Line_222" data-name="Line 222" x2="69.361" transform="translate(0 15.675)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="5.524"/>
                    </g>
                  </g>
                  <g id="Group_450" data-name="Group 450" transform="translate(308.852 91.234)">
                    <rect id="Rectangle_393" data-name="Rectangle 393" width="114.828" height="52.857" rx="5.524" fill="#4ad3ea"/>
                    <g id="Group_449" data-name="Group 449" transform="translate(16.321 18.591)">
                      <line id="Line_223" data-name="Line 223" x2="49.941" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="5.524"/>
                      <line id="Line_224" data-name="Line 224" x2="69.361" transform="translate(0 15.675)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="5.524"/>
                    </g>
                  </g>
                </g>
                <g id="Group_457" data-name="Group 457" transform="translate(0 158.731)">
                  <g id="Group_456" data-name="Group 456" transform="translate(0 0)">
                    <path id="Path_315" data-name="Path 315" d="M749.459,3188.795l19.171-.518-11.335-8.578-.014-.321A82.976,82.976,0,0,1,748,3181.11Z" transform="translate(-510.392 -2868.218)" fill="#001441"/>
                    <path id="Path_316" data-name="Path 316" d="M743.317,3181.942l1.532,7.486,3.5-.095-1.46-7.684C745.685,3181.792,744.48,3181.9,743.317,3181.942Z" transform="translate(-509.281 -2868.756)" fill="#001441"/>
                    <g id="Group_453" data-name="Group 453" transform="translate(0 6.506)">
                      <path id="Path_317" data-name="Path 317" d="M436.515,2947.672c.926-.309,47.854-10.5,47.854-10.5l8.336,36.431-47.237,11.114Z" transform="translate(-436.515 -2817.279)" fill="#471e6d"/>
                      <ellipse id="Ellipse_85" data-name="Ellipse 85" cx="7.617" cy="4.332" rx="7.617" ry="4.332" transform="translate(24.055 162.268) rotate(-16.339)" fill="#f5cac8"/>
                      <path id="Path_318" data-name="Path 318" d="M550.887,2867.069c4.337,11.952,4.095,18.315.97,24.342s-10.395,7.551-18.748,4.548-9.675-26.411-3.8-35.485c1.309-2.575,3.257-4.322,4.733-6.646a162.939,162.939,0,0,0-5.206-17.5,1.669,1.669,0,0,0-.83-.933,59.679,59.679,0,0,0-5.218-3.174c-.678-.361-.561-1-.056-1.379a9.323,9.323,0,0,1,1.212-.595c.658-.333.637-.925.391-1.575h0c-.482-1.276-.89-2.526.338-3.62a18.412,18.412,0,0,1,4.716-2.733c1.838-.776,4.258,1.24,4.909,3.147C538.789,2838.62,547.439,2857.566,550.887,2867.069Z" transform="translate(-456.866 -2789.998)" fill="#ffdcdc"/>
                      <path id="Path_319" data-name="Path 319" d="M491.289,2835.4c1.419-3.833-1.594-14.629-2.144-20.126a26.642,26.642,0,0,0,13.591-1.5c6.355,8.4,7.92,16.288,5.652,26.653C502.549,2839.059,496.965,2837.3,491.289,2835.4Z" transform="translate(-448.998 -2788.011)" fill="#ffdcdc"/>
                      <path id="Path_320" data-name="Path 320" d="M494.374,2790.451a15.361,15.361,0,0,0-3.7,1.633c-4.1,2.507-5.672,5.4-4.19,10.329a46.1,46.1,0,0,1,2.151,9.7c.5,6.053,6.521,7.672,12.231,7.049,1.336-.145,4.195-.627,5.6-.973,6.02-1.482,9.519-5.082,6.729-11.25-1.656-3.662-5.327-8.873-7.116-11.927C503.065,2789.864,499.375,2789.211,494.374,2790.451Z" transform="translate(-448.236 -2782.351)" fill="#ffdcdc"/>
                      <path id="Path_321" data-name="Path 321" d="M504.093,2806.234c-.955.427.07,3.531.815,10.595.756,7.164.205,8.739,1.63,9.78,2.709,1.979,9.779-3.26,14.669-2.445,4.583.764,1.412-11.293,0-12.224-1.24-.817-3.111,2.458-6.52,2.445C509.1,2814.361,505.722,2805.507,504.093,2806.234Z" transform="translate(-452.451 -2786.212)" fill="#471e6d"/>
                      <path id="Path_322" data-name="Path 322" d="M491.577,2837.119l-1.215-14.927s4.606-2.535,5.393-.172.788,6.3,3.151,8.663,7.074,3.818,9.05,7.816-1.174,7.937-1.174,7.937Z" transform="translate(-449.286 -2789.728)" fill="#f5cac8"/>
                      <path id="Path_323" data-name="Path 323" d="M535.2,3181.249h12.225l21.19,8.964H539.877a3.8,3.8,0,0,1-3.712-2.978l-.969-4.356v-1.63" transform="translate(-459.92 -2875.167)" fill="#001441"/>
                      <path id="Path_324" data-name="Path 324" d="M478.572,3181.249H490.8l21.19,8.964H483.253a3.8,3.8,0,0,1-3.712-2.978l-.969-4.356v-1.63" transform="translate(-446.49 -2875.167)" fill="#001441"/>
                      <path id="Path_325" data-name="Path 325" d="M482.193,2957.163s-2.455,9.82-2.455,15.957a264,264,0,0,0,2.455,28.233c1.227,8.592-3.573,25.629-4.8,40.359s1.1,86.071,1.1,86.071h11.068s20.867-95.744,22.094-119.066-4.909-50.326-4.909-50.326Z" transform="translate(-446.125 -2822.019)" fill="#005fdb"/>
                      <path id="Path_326" data-name="Path 326" d="M533.849,2953.14s8.653,20.789,9.82,44.19c.865,17.337-6.117,130.113-6.117,130.113H524.029s-15.747-86.645-18.2-96.465-.211-29.966-5.121-36.1-12.275-15.957-9.82-31.914l1.227-8.593Z" transform="translate(-449.295 -2821.065)" fill="#179cff"/>
                      <path id="Path_327" data-name="Path 327" d="M488.188,2971.106s9.779-6.521,13.854-5.7c1.787.356,18.745,4.075,20.375,4.075,1.075,0,6.756-1.063,10.5-1.787a2.864,2.864,0,0,0,2.128-3.844l-2.85-7.408-16.3-4.891-19.393,5.948-7.327-1.331Z" transform="translate(-448.771 -2820.687)" fill="#005fdb"/>
                      <path id="Path_328" data-name="Path 328" d="M549.907,2892.308c-5.335,7.255-12.847,9.424-16.907,17.479-3.561-6.078-4.893-6.784-7.064-13.538C534.092,2895.7,541.893,2893.765,549.907,2892.308Z" transform="translate(-457.724 -2806.637)" fill="#f5cac8"/>
                      <path id="Path_329" data-name="Path 329" d="M545.265,2977.257s3.858,15.43-10.8,15.43" transform="translate(-459.746 -2826.785)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="1.114"/>
                      <line id="Line_225" data-name="Line 225" x2="14.701" transform="translate(73.906 299.612)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="1.137"/>
                      <line id="Line_226" data-name="Line 226" x2="13.126" transform="translate(32.162 299.612)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="1.137"/>
                      <g id="Group_452" data-name="Group 452" transform="translate(21.115 45.306)">
                        <path id="Path_330" data-name="Path 330" d="M536.8,2880.226c-4.384-6.671-6.937-16.144-10.442-22.764s-9.229-9.624-14.907-11.978a63.269,63.269,0,0,0-18.077-4.687c-2.773-.312-4.858-.954-6.883-1.231a1.407,1.407,0,0,1,.221.329c1.384,3.065-10.429,13.3-10.429,21.18s2.363,24.415-1.575,26.779-3.938,7.088-2.362,11.026c1.206,3.015,3.325,9.716,2.843,14.463,2.632,7.581,2.461,12.59,1.352,20.663,15.3-1.288,35.022,8.581,50.047,4.521-1.474-21.4-15.382-44.069-15.1-68.594,1,7.056,2.441,13.2,5.924,19.486C524.287,2887.809,531.784,2885.674,536.8,2880.226Z" transform="translate(-465.895 -2839.434)" fill="#ffcb46"/>
                        <path id="Path_331" data-name="Path 331" d="M470.647,2898.84c-1.576-3.938-1.576-8.664,2.362-11.027s1.575-18.9,1.575-26.779,11.814-18.116,10.429-21.18a1.387,1.387,0,0,0-.221-.33,11.553,11.553,0,0,0-8.051,1.768c-6.082,3.512-8.2,6.084-11.469,16.808s1.505,39.336,6.2,50.089c.809,1.853,1.47,3.535,2.018,5.113C473.972,2908.556,471.853,2901.855,470.647,2898.84Z" transform="translate(-464.195 -2839.393)" fill="#ff9e26"/>
                      </g>
                      <path id="Path_332" data-name="Path 332" d="M486.72,2845.564c-8.258,9.566-10.023,19-9.451,23.629,1.145,9.272,10.226,15.942,12.207,27.961.977,5.923-2.7,17.7-3.544,20.871" transform="translate(-446.158 -2795.55)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="1.137"/>
                      <path id="Path_333" data-name="Path 333" d="M475.726,2810.114c.729-2.863,3.693-2.421,3.662-4.522-.047-3.257-4.841-3.217-6.286-6.367-1.276-2.784-.79-7.78,2-10.039,3.315-2.682,7.058-.5,10.635-3.287,2.192-1.706,3.2-4.862,6.64-5.695.6-.146,2.692-.6,3.93.645,1.8,1.814,1.822,5.9.191,7.876-1.1,1.331-2.73.263-3,1.733-.552,3,4.955,4.56,4.89,8.155-.057,3.194-4.406,4.239-5.465,7.688-.638,2.075,1.232,3.67.426,6.908a8.284,8.284,0,0,1-5.539,5.726c-3.977,1.076-9.811.669-11.979-3.068C474.381,2813.363,475.529,2810.893,475.726,2810.114Z" transform="translate(-445.034 -2780)" fill="#471e6d"/>
                      <path id="Path_334" data-name="Path 334" d="M529.956,2985.984s.788,17.327,3.938,27.566,5.513,40.956,5.513,48.044.788,32.292,0,40.956a109.465,109.465,0,0,0,0,17.328" transform="translate(-458.677 -2828.855)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="1.114"/>
                      <path id="Path_335" data-name="Path 335" d="M510.237,2793.767l9.529,6.432-5.513,3.938Z" transform="translate(-454 -2783.266)" fill="#ffdcdc"/>
                      <path id="Path_336" data-name="Path 336" d="M501.716,2809.218s-1.753,1.549-2.363.788c-.436-.544-.232-.413-.268-1.213a1.71,1.71,0,0,0-.361-.979l-.352-.453a1.227,1.227,0,0,0-.97-.474h-.369a1.192,1.192,0,0,0-1.131.815l-.159.478a3.431,3.431,0,0,0-.051,2l.239.859a4.3,4.3,0,0,0,.818,1.578l.6.735a3.051,3.051,0,0,0,.435.435l.988.809a1.9,1.9,0,0,0,1.03.421l1.1.1h1.22Z" transform="translate(-450.521 -2786.377)" fill="#ffdcdc"/>
                      <path id="Path_337" data-name="Path 337" d="M496.916,2998.374a33.983,33.983,0,0,0,4.726,7.088c2.363,2.363,3.15,3.939,3.15,7.089s.788,15.752,1.575,22.053,4.79,22.986,4.79,22.986" transform="translate(-450.841 -2831.793)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="1.137"/>
                      <path id="Path_338" data-name="Path 338" d="M526.6,2899.55c-4.886-9.341-4.522-11.561-6.3-20.308-.1,13.2,3.356,25.872,6.838,38.088,2.035,7.14-19.714,18.776-18.367,25.615.674,3.425,24.966,2.161,25.2,5.514,1.685-.757,6.546-1.693,7.876-3.15C541.778,2932.915,531.49,2908.893,526.6,2899.55Z" transform="translate(-453.638 -2803.538)" fill="#ff9e26"/>
                    </g>
                    <g id="Group_455" data-name="Group 455" transform="translate(138.441)">
                      <path id="Path_339" data-name="Path 339" d="M703.817,2793.383s7.762-5.352,8.967-8.967c1.494-4.483.747-9.713-5.231-11.956s-12.7-.748-18.681,5.23a45.17,45.17,0,0,0-8.967,13.45,5.935,5.935,0,0,0-5.977.748c-2.989,2.241-9.715,20.175,11.956,17.187S703.817,2793.383,703.817,2793.383Z" transform="translate(-630.464 -2771.471)" fill="#471e6d"/>
                      <path id="Path_340" data-name="Path 340" d="M705.412,2842.606l-.091-.074.143-6.989-.98-5.96-14.9,3.564-2.438,6.692-.7,4.075-.488.823,13.794,19.511Z" transform="translate(-634.118 -2785.254)" fill="#ffdcdc"/>
                      <path id="Path_341" data-name="Path 341" d="M690.127,2811.382h14.2l1.646,31.1h-19.96Z" transform="translate(-634.131 -2780.937)" fill="#ffdcdc"/>
                      <rect id="Rectangle_394" data-name="Rectangle 394" width="20.483" height="28.108" rx="10.241" transform="translate(76.251 56.008) rotate(-180)" fill="#471e6d"/>
                      <rect id="Rectangle_395" data-name="Rectangle 395" width="20.483" height="28.108" rx="10.241" transform="translate(76.251 46.403) rotate(-180)" fill="#ffdcdc"/>
                      <path id="Path_342" data-name="Path 342" d="M689.192,2815.109s-2.093,1.046-2.691.3a6.2,6.2,0,0,1-.584-2.766,1.685,1.685,0,0,0-.354-.961l-.345-.443a1.207,1.207,0,0,0-.951-.465H683.9a1.169,1.169,0,0,0-1.109.8l-.156.468a3.37,3.37,0,0,0-.05,1.965l.234.844a4.236,4.236,0,0,0,.8,1.546l.591.721a3.045,3.045,0,0,0,.426.426l.969.792a1.852,1.852,0,0,0,1.009.413l1.076.1h1.2Z" transform="translate(-633.29 -2780.792)" fill="#ffdcdc"/>
                      <path id="Path_343" data-name="Path 343" d="M688.692,2856.545s5.978,5.23,8.967,5.978,9.714-7.473,9.714-7.473l-2.242,17.187-5.978,3.736Z" transform="translate(-634.767 -2791.293)" fill="#179cff"/>
                      <path id="Path_344" data-name="Path 344" d="M810.791,2828.737h-.735a.555.555,0,0,0-.442.219l-1.553,1.538-.673-1.458,2.994-4.223a3.022,3.022,0,0,0-.191-3.739l-4.423,4.581,2.463-4.609a1.946,1.946,0,0,0-.843-2.663l-7.625,8.859,1.723-8.707a1.978,1.978,0,0,0-2.02-2.36.075.075,0,0,0-.068.051l-3.56,10.791.224,16.259-3.139,5.047-1.233-.9c-.012.02-.03.046-.043.067l11.542,7.984,1.62-6.369a28.551,28.551,0,0,0,5.158-6.616c.794-1.5.547-6.263.4-8.225a1.445,1.445,0,0,1,.746-1.372.817.817,0,0,0,.443-.656l-.456-3.168A.314.314,0,0,0,810.791,2828.737Z" transform="translate(-659.185 -2782.074)" fill="#ffdcdc"/>
                      <path id="Path_345" data-name="Path 345" d="M807.3,2848.022a15.613,15.613,0,0,0-1.178-7.962l.6-7.414a1.287,1.287,0,0,0-1.335-1.265h0c-.426.015-.752.652-.892,1.054l-1.832,4.728-.187,4.86" transform="translate(-661.755 -2785.68)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="1.079"/>
                      <path id="Path_346" data-name="Path 346" d="M736.357,2896.127c.749,2.186,4.844,14.157,7.5,22.536.025.079.141.044.13-.039-.762-5.88-1.482-11.033-1.9-14.162l-.625-3.434a.074.074,0,0,0-.018-.036l-4.951-4.95A.086.086,0,0,0,736.357,2896.127Z" transform="translate(-646.07 -2801.01)" fill="#ff9e26"/>
                      <path id="Path_347" data-name="Path 347" d="M652.965,3179.119,639.3,3189.747l24.2-.306,1.845-8.218C659.305,3181.679,655.35,3180.413,652.965,3179.119Z" transform="translate(-623.052 -2868.156)" fill="#001441"/>
                      <path id="Path_348" data-name="Path 348" d="M659.386,3128.824a23.88,23.88,0,0,0,10.726,1.333l27.871-124.128,3.093-12.464,25.905,136.418a83.027,83.027,0,0,0,9.282-1.732l-6.42-146.424s-.525-8-1.161-18.013c-26.928,8.625-48.036,6.6-58.213,4.625-.865,25.417-6.207,101.872-8.157,124.623C661.061,3107.677,660.042,3120.42,659.386,3128.824Z" transform="translate(-627.816 -2817.09)" fill="#179cff"/>
                      <path id="Path_349" data-name="Path 349" d="M738.983,3134.586l-11.113-64.93q-.327-1.916-.465-3.857l-3.42-48.257a74.825,74.825,0,0,0-8.3-29.318l-2.609-5-3.093,27.409,25.427,124.247C736.575,3134.835,737.779,3134.731,738.983,3134.586Z" transform="translate(-639.817 -2821.694)" fill="#005fdb"/>
                      <ellipse id="Ellipse_86" data-name="Ellipse 86" cx="19.054" cy="14.197" rx="19.054" ry="14.197" transform="translate(54.673 151.184)" fill="#179cff"/>
                      <path id="Path_350" data-name="Path 350" d="M730.834,2962.765c-.07-1.1-.142-2.231-.215-3.376-25.92,10.223-52.765,6.243-62.165,4.324.172,1.077.3,2.051.375,2.839C676.66,2968.527,699.914,2972.668,730.834,2962.765Z" transform="translate(-629.967 -2816.041)" fill="#005fdb"/>
                      <path id="Path_351" data-name="Path 351" d="M671.9,2941.047c-.748-8.967.747-26.154,1.494-38.857s-1.494-20.175-7.473-21.669c0,0,5.231,3.736,5.978,9.714.571,4.573-5.576,20.92-6.742,28.364.413,5.126,1.8,21.961,2.512,26.409,1.162.238,2.592.507,4.253.776C671.978,2943.512,671.973,2941.889,671.9,2941.047Z" transform="translate(-629.186 -2797.335)" fill="#ff9e26"/>
                      <path id="Path_352" data-name="Path 352" d="M666.22,2969.785c0,3.677-8.884,159.3-8.884,159.3l-.122.1a15.519,15.519,0,0,0,1.657.771c.656-8.4,6.906-37.587,8.158-52.2.645-7.521,9.256-5.192,5.231-20.175-13.45-50.065,11.956-96.393-2.3-88.008-1.509-.292-2.781-.584-3.792-.838C666.2,2969.134,666.22,2969.492,666.22,2969.785Z" transform="translate(-627.301 -2818.221)" fill="#005fdb"/>
                      <path id="Path_353" data-name="Path 353" d="M618,3022.395c.9-.3,46.348-10.167,46.348-10.167l8.073,35.284-45.75,10.765Z" transform="translate(-618 -2828.573)" fill="#471e6d"/>
                      <path id="Path_354" data-name="Path 354" d="M630.445,3008.416l2.579.112a14.649,14.649,0,0,0,2.579,8.634l-.6.189a2.133,2.133,0,0,0-1.449,1.593l-.127.6a1.819,1.819,0,0,0,1.5,2.175l1.013.155-.4.438a1.145,1.145,0,0,0,.134,1.672,2.13,2.13,0,0,0,2.43.145l.753-.459-.18.632a.92.92,0,0,0,.684,1.15,1.341,1.341,0,0,0,1.512-.761l1.356-3.03a.06.06,0,0,1,.115.022l.1,2.519a1.568,1.568,0,0,0,.768,1.284,1.085,1.085,0,0,0,1.473-.359l5-7.992a4.21,4.21,0,0,0,.292-3.9l-2.052-4.733.009-.074c-4.936-.047-13.829-.128-17.492-.131Z" transform="translate(-620.95 -2827.638)" fill="#ffdcdc"/>
                      <line id="Line_227" data-name="Line 227" y1="2.989" x2="2.989" transform="translate(21.421 190.413)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="1.079"/>
                      <line id="Line_228" data-name="Line 228" y1="3.955" x2="4.377" transform="translate(16.191 189.448)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="1.079"/>
                      <path id="Path_355" data-name="Path 355" d="M736.063,2975.049s-3.736,14.944,10.461,14.944" transform="translate(-645.897 -2819.755)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="1.079"/>
                      <path id="Path_356" data-name="Path 356" d="M686.327,2975.049s3.736,14.944-10.46,14.944" transform="translate(-631.725 -2819.755)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="1.079"/>
                      <line id="Line_229" data-name="Line 229" x2="13.947" transform="translate(30.638 306.91)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="1.079"/>
                      <line id="Line_230" data-name="Line 230" x2="13.947" transform="translate(94.153 306.91)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="1.079"/>
                      <line id="Line_231" data-name="Line 231" y2="7.472" transform="translate(68.871 29.385)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="1.079"/>
                      <path id="Path_357" data-name="Path 357" d="M750.821,2883.945c4.514,3.87,7.356,1.06,7.57-1.7l-.361.468L734.5,2859.955c-1.782-1.487-2.792-2.445-4.4-2.8,1.529,1.619,7.058,7.692,10.256,14.088C744.1,2878.714,745.59,2879.461,750.821,2883.945Z" transform="translate(-644.588 -2791.793)" fill="#ff9e26"/>
                      <g id="Group_454" data-name="Group 454" transform="translate(8.105 57.352)">
                        <path id="Path_358" data-name="Path 358" d="M631.853,2936.048c.748-4.484,5.231-12.7,6.725-18.681s4.484-14.2,5.231-17.186,2.99-11.956,3.386-14.139a11.4,11.4,0,0,1,2.592-4.542,19.927,19.927,0,0,0-3.211,1.17c-1.4,3.137-2.3,7.506-4.078,14.337-2.915,11.214-13.492,35.026-13.828,40.745-.325,5.519,1.23,38.2,1.339,40.465,1.729,0,4.625.02,7.718.044a32.13,32.13,0,0,0-3.632-4.851C631.853,2971.168,631.107,2940.531,631.853,2936.048Z" transform="translate(-628.626 -2854.92)" fill="#ff9e26"/>
                        <path id="Path_359" data-name="Path 359" d="M765.378,2862.953l.063-.247-11.542-7.984c-2.367,3.823-16.256,21.907-18.659,25.031-.214,2.763-3.057,5.572-7.57,1.7-5.231-4.484-6.725-5.23-10.461-12.7-3.2-6.4-8.727-12.469-10.256-14.088a8.218,8.218,0,0,0-2.343-.135l-6.472.125-5.406-8L687.07,2868.3l-13.794-19.511-3.849,9.047s-14.015,8.371-18.275,11.735a12.053,12.053,0,0,0-3.659,4.837,19.99,19.99,0,0,1,3.211-1.17,11.407,11.407,0,0,0-2.592,4.543c-.4,2.182-2.638,11.148-3.386,14.138s-3.736,11.208-5.231,17.186-5.977,14.2-6.725,18.681,0,35.12,2.242,37.361a32.093,32.093,0,0,1,3.632,4.851c3.46.027,7.168.062,9.774.086l.715-34.424,8.159-26.586s.043.548.12,1.492c1.166-7.443,7.313-23.791,6.742-28.364-.748-5.977-5.978-9.713-5.978-9.713,5.978,1.494,8.22,8.966,7.473,21.67s-2.242,29.89-1.494,38.856c.07.843.075,2.466.023,4.737,11.771,1.911,35.2,3.86,57.913-5.1-.459-7.273-1.574-16.755-2.633-24.974-2.917-9.244-7.854-23.607-7.854-23.607l5.231,5.231.629,3.455c-.252-1.9-.391-3.063-.355-3.169,0,0,13.1,12.974,15.641,14.07,4.638,2,9.419.9,13.456-2.017s5.606-6.392,7.849-9.98,13.232-27.473,13.232-27.473Z" transform="translate(-629.542 -2846.655)" fill="#ffcb46"/>
                      </g>
                      <path id="Path_360" data-name="Path 360" d="M726.586,2883.58l1.362,7.173a5.658,5.658,0,0,1-4.5,6.615h0a5.659,5.659,0,0,1-6.616-4.5l-1.363-7.174Z" transform="translate(-641.117 -2798.061)" fill="#ffe383"/>
                      <path id="Path_361" data-name="Path 361" d="M681.109,2864.639l5.981,13.306L698,2872.563" transform="translate(-632.968 -2793.569)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="1.079"/>
                      <path id="Path_362" data-name="Path 362" d="M704.5,2870.72l10.863,1.448.6-13.605" transform="translate(-638.516 -2792.127)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="1.079"/>
                      <circle id="Ellipse_87" data-name="Ellipse 87" cx="1.868" cy="1.868" r="1.868" transform="translate(61.772 85.053)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="1.079"/>
                      <circle id="Ellipse_88" data-name="Ellipse 88" cx="1.868" cy="1.868" r="1.868" transform="translate(63.266 93.273)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="1.079"/>
                      <circle id="Ellipse_89" data-name="Ellipse 89" cx="1.868" cy="1.868" r="1.868" transform="translate(64.013 102.24)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="1.079"/>
                      <path id="Path_363" data-name="Path 363" d="M703.876,2875.132l5.292,19.564a13.946,13.946,0,0,1,.686,4.321v11.848a41.161,41.161,0,0,0,1.024,9.123l2.713,20.154" transform="translate(-638.368 -2796.057)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="1.079"/>
                      <path id="Path_364" data-name="Path 364" d="M752.465,3062.719s-2.064,1.051-2.241,2.242c-.266,1.787,2.092,12.253.747,14.944-2.242,4.483-4.483,5.23-4.483,8.219s.747,5.23,3.736,5.979,3.544,8.7,4.387,14.065S752.465,3062.719,752.465,3062.719Z" transform="translate(-648.474 -2840.548)" fill="#005fdb"/>
                      <line id="Line_232" data-name="Line 232" y1="2.989" x2="9.714" transform="translate(83.815 129.514)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="1.079"/>
                      <line id="Line_233" data-name="Line 233" x2="11.209" y2="1.494" transform="translate(52.805 130.635)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="1.079"/>
                      <path id="Path_365" data-name="Path 365" d="M677.974,3075.5s2.288,9.155,1.526,14.494c-.272,1.9-1.123,5.155-2.107,8.833-.36,1.343-.737,2.743-1.11,4.155" transform="translate(-631.823 -2843.579)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="1.102"/>
                      <line id="Line_234" data-name="Line 234" x2="8.391" y2="6.103" transform="translate(133.875 74.016)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="1.13"/>
                      <line id="Line_235" data-name="Line 235" x2="11.473" y2="0.277" transform="translate(9.505 174.432)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="1.13"/>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>


       </div>
      </div>
    </div>
  </div>
  <div></div>

<div class="black-top"></div>
<div class="black-bottom"></div>
