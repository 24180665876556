import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-what-we-need-intro',
  template: `
  <div class="intro">
    <h1 class="title">Čo naozaj potrebujeme?</h1>
    <div class="subtitle">
    Vráťme sa domov. Ako je to u vás doma so spotrebičmi? Naozaj všetky intenzívne využívate? Ktoré pokladáš za „nákupný omyl“ a podľa teba ich nepotrebujete? Ktoré využívate len zriedkavo, prípadne sezónne?
    </div>
    <app-button  (click)="next()" text="Pokračuj" ></app-button>
  </div>
  <div class="black-top"></div>
  <div class="black-bottom"></div>
`,
  styleUrls: ['./what-we-need-intro.component.scss']
})
export class WhatWeNeedIntroComponent implements OnInit {


  @Output()
  nextSlide: EventEmitter<number> = new EventEmitter<number>();
  constructor() { }

  ngOnInit(): void {
  }

  next(): void {
    this.nextSlide.emit(17);
  }
}
