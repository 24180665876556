import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ElectronicsService } from '@core/services/electronics.service';
import { Electronic, ElectronicsCategories } from '@models';
import { Subscription } from 'rxjs';
import { filter, map, tap, withLatestFrom } from 'rxjs/operators';
import { slidesAnimation } from '../../router.animations';

@Component({
  selector: 'app-what-we-need-select',
  templateUrl: './what-we-need-select.component.html',
  styleUrls: ['./what-we-need-select.component.scss'],
  animations: [slidesAnimation]
})
export class WhatWeNeedSelectComponent implements OnInit {
  @Output()
  nextSlide: EventEmitter<number> = new EventEmitter<number>();
  selectedElectronics: Electronic[] = [];
  previousTotal$: Subscription;
  previousTotal: number;
  newTotal: number;
  slide = 1;
  constructor(public electronicService: ElectronicsService) {
      this.previousTotal$ =
      this.electronicService.getTotalCountElectronic().subscribe(
        t => { this.previousTotal = t; }
      );
  }

  ngOnInit(): void {
    this.electronicService
      .getElectronicsCategories()
      .pipe(
        map((items) =>
          items.reduce((acc: Electronic[], electro) => {
            return acc.concat(electro.items.filter((i) => i.selected));
          }, [])
        ),
        tap( e => {
          this.previousTotal$.unsubscribe();
        }),
        withLatestFrom(this.electronicService.getTotalCountElectronic())
      )
      .subscribe(([items, total]) => {
        this.selectedElectronics = items;
        this.newTotal = total;
      });
  }

  select(item: Electronic): void {
    this.electronicService.selectElectronicCategoryItem(item);
  }

  result(): void {
    this.slide = 2;
  }
  next(): void {
    this.nextSlide.emit(18);
  }

  increase(item: Electronic): void {
    this.electronicService.changeAmount(item, 'increase');
  }

  decrease(item: Electronic): void {
    this.electronicService.changeAmount(item, 'decrease');
  }
}
