<div class="container">
  <nav class="navigation">
    <ul class="nav">
      <li [class.active]="page === a" *ngFor="let a of slides"></li>
    </ul>
  </nav>
  <div class="slides">
    <app-intro-slide
      class="slide"
      @slidesAnimation
      (nextSlide)="goToPage($event)"
      *ngIf="page === 1"
    ></app-intro-slide>
    <app-who-is-the-table
      class="slide"
      @slidesAnimation
      (nextSlide)="goToPage($event)"
      *ngIf="page === 2"
    ></app-who-is-the-table>
    <app-table-evolution-intro
      class="slide"
      @slidesAnimation
      (nextSlide)="goToPage($event)"
      *ngIf="page === 3"
    ></app-table-evolution-intro>
    <app-table-evolution
      class="slide"
      @slidesAnimation
      (nextSlide)="goToPage($event)"
      *ngIf="page === 4"
    ></app-table-evolution>
    <app-your-table-intro
      (nextSlide)="goToPage($event)"
      @slidesAnimation
      class="slide"
      *ngIf="page === 5"
    ></app-your-table-intro>
    <app-arrange-your-table
      @slidesAnimation
      class="slide"
      (nextSlide)="goToPage($event)"
      *ngIf="page === 6"
    ></app-arrange-your-table>
    <app-electronics-home-intro
      @slidesAnimation
      class="slide"
      (nextSlide)="goToPage($event)"
      *ngIf="page === 7"
    >
    </app-electronics-home-intro>
    <app-electronics-selection
      @slidesAnimation
      class="slide"
      (nextSlide)="goToPage($event)"
      *ngIf="page === 8"
    >
    </app-electronics-selection>
    <app-electronics-story-intro
    @slidesAnimation
    class="slide"
    (nextSlide)="goToPage($event)"
    *ngIf="page === 9">

    </app-electronics-story-intro>
    <app-electronics-story-movie
    @slidesAnimation
    class="slide"
    (nextSlide)="goToPage($event)"
    *ngIf="page === 10">
    </app-electronics-story-movie>
    <app-quiz-one
    @slidesAnimation
    class="slide"
    (nextSlide)="goToPage($event)"
    *ngIf="page === 11">
    </app-quiz-one>
    <app-spotreb-dilemy-intro
    @slidesAnimation
    class="slide"
    (nextSlide)="goToPage($event)"
    *ngIf="page === 12">
    </app-spotreb-dilemy-intro>
    <app-spotreb-dilemy
    @slidesAnimation
    class="slide"
    (nextSlide)="goToPage($event)"
    *ngIf="page === 13"
    >
    </app-spotreb-dilemy>
    <app-sodom-intro
    @slidesAnimation
    class="slide"
    (nextSlide)="goToPage($event)"
    *ngIf="page === 14"
    >
    </app-sodom-intro>
    <app-sodom-video
    @slidesAnimation
    class="slide"
    (nextSlide)="goToPage($event)"
    *ngIf="page === 15"
    >
    </app-sodom-video>
    <app-what-we-need-intro
    @slidesAnimation
    class="slide"
    (nextSlide)="goToPage($event)"
    *ngIf="page === 16"
    >
    </app-what-we-need-intro>
    <app-what-we-need-select
    @slidesAnimation
    class="slide"
    (nextSlide)="goToPage($event)"
    *ngIf="page === 17"
    >
    </app-what-we-need-select>
    <app-final
    @slidesAnimation
    class="slide"
    *ngIf="page === 18">
    </app-final>
  </div>
</div>
