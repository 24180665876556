import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ElectronicsService } from './services/electronics.service';
import { Optional } from '@angular/core';
import { SkipSelf } from '@angular/core';



@NgModule({
  declarations: [],
  providers:[ElectronicsService],
  imports: [
    CommonModule
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only'
      );
    }
  }

}
