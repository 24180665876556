import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-spotreb-dilemy-intro',
  styleUrls: ['./spotreb-dilemy-intro.component.scss'],
  template: `
  <div class="intro">
    <h1 class="title">Spotrebiteľské dilemy</h1>
    <div class="subtitle">
    Všetka elektronika, ktorú dnes používame, sa vyrába systémom:  <br />„vyťaž surovinu-vyrob-používaj-zahoď“. <br />
    To znamená, že množstvo vyradenej elektroniky sa do systému formou recyklácie nevráti. <br />
Tvoje rozhodnutia pri nákupe a používaní elektroniky môžu predĺžiť jej životnosť a znížiť dopady na životné prostredie. Ako?

    </div>
    <app-button  (click)="next()" text="Spustiť úlohu" ></app-button>
  </div>
  <div class="black-top"></div>
  <div class="black-bottom"></div>
`,
})
export class SpotrebDilemyIntroComponent implements OnInit {

  @Output()
  nextSlide: EventEmitter<number> = new EventEmitter<number>();
  constructor() { }

  ngOnInit(): void {
  }

  next(): void {
    this.nextSlide.emit(13);
  }

}
