<div @slidesAnimation class="slide-grid" *ngIf="slide === 1">
  <div class="slide-left">
    <div class="slide-inner-left">
      <h1 class="title title--sm">Spotrebiče, ktoré máte podľa tvojho výberu doma.</h1>
      <div class="subtitle">
        Uprav svoj výber podľa toho, ktoré z nich
        považuješ za: <br />
        - nepotrebné, prípadne si ich vieš požičať <br />
        - nevyhnutné (príp. vieš znížiť ich počet)
      </div>
      <app-button (click)="result()" text="Hotovo"></app-button>
    </div>
  </div>
  <div class="slide-right">
    <div class="slide-inner-right">
      <div class="items-box">
        <div class="items-box-inner">
          <ul class="items">
            <li
              class="item"
              [class.selected]="item.selected"
              *ngFor="let item of selectedElectronics"
            >
              <div class="item-inner" (click)="select(item)">
                <div class="check" [class.selected]="item.selected"></div>
                <span>
                  {{ item.name }}
                </span>
              </div>
              <div class="quantity">
                <div class="arrow up" (click)="increase(item)"></div>
                <div class="count">{{ item.count }} <span>ks</span></div>
                <div
                  *ngIf="item.count"
                  class="arrow down"
                  (click)="decrease(item)"
                ></div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>


<div @slidesAnimation *ngIf="slide === 2">
  <div class="result">

<h3 class="title title--xs ">Teraz a potom</h3>
<div class="result-grid" >
  <div class="result-left">
    <div class="result-inner-left">
      <div class="result-count">
        <p>
          Toto je počet Spotrebičov vo vašej domácnosti v súčasnosti:
        </p>
        <div class="count">
          {{previousTotal}}
        </div>
      </div>
    </div>
  </div>
  <div class="result-right">
    <div class="result-inner-right">
      <div class="result-count">
        <p>
          Svojím rozhodnutím v budúcnosti by si vedel/a ovplyvniť počet spotrebičov v domácnosti takto:
        </p>
        <div class="count">
          {{newTotal}}
        </div>
      </div>
    </div>
    </div>
</div>
<div class="center">
  V prípade, že sa ti podarilo znížiť počet spotrebičov, gratulujeme! Prehodnotenie vlastných potrieb je najúčinnejším environmentálnym opatrením.
  <br />
  <br />
  <app-button (click)="next()" text="Záver"></app-button>
</div>

</div>
</div>
