import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import gsap from 'gsap';
import { ElectronicsService } from '@core/services/electronics.service';
import { TableElectronics } from '@models';
import { Observable, Subscription } from 'rxjs';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-arrange-your-table',
  templateUrl: './arrange-your-table.component.html',
  styleUrls: ['./arrange-your-table.component.scss'],
})
export class ArrangeYourTableComponent
  implements OnInit, AfterViewInit, OnDestroy {
  public electronicItems$: Observable<
    TableElectronics[]
  > = this.electronicService.getElectronicItems();
  public timeline: TimelineMax;
  public resultsTimeline: TimelineMax;
  public electronicItems: TableElectronics[];
  public tableItems: TableElectronics[];
  public electronicUnsubscribe: Subscription;
  public showResult: boolean;
  @Output()
  nextSlide: EventEmitter<number> = new EventEmitter<number>();

  constructor(public electronicService: ElectronicsService) {
    this.timeline = gsap.timeline();
    this.resultsTimeline = gsap.timeline();
  }

  ngOnInit(): void {
    this.electronicUnsubscribe = this.electronicItems$.subscribe((items) => {
      this.electronicItems = items;
      this.tableItems = items.filter((item) => item.selected);
    });
  }

  ngAfterViewInit(): void {
    this.timeline.from('.item', {
      opacity: 0,
      scale: 0,
      duration: 0.8,
      delay: 0.5,
      ease: 'elastic.out(1, 0.3)',
      stagger: {
        amount: 0.2,
        from: 'start',
        ease: 'power3.inOut',
        grid: 'auto',
      },
    });

    this.resultsTimeline.set('.table-wrap', {
      opacity: 0,
      display: 'none',
    });
  }

  select(item: TableElectronics): void {
    this.electronicService.selectElectronicItem(item);
    setTimeout(() => {
      const ID = document.getElementById(item.src) as HTMLImageElement;
      this.timeline.fromTo(
        ID,
        {
          scale: item.selected ? 1 : 0,
        },
        {
          scale: item.selected ? 0 : 1,
          duration: 0.5,
          ease: 'elastic.out(1, 0.3)',
        }
      );
    });
  }

  next(): void {
    this.nextSlide.emit(7);
  }

  showResults(): void {
    this.showResult = true;

    setTimeout(() => {
      this.resultsTimeline
        .fromTo('.select-wrap', { opacity: 1 }, { opacity: 0, duration: 0.5 })
        .set('.select-wrap', { display: 'none' })
        .set('.table-wrap', { display: 'block' })
        .fromTo(
          '.table-wrap',
          {
            opacity: 0,
            y: '100%',
          },
          {
            opacity: 1,
            y: 0,
            duration: 0.5,
          }
        );
    }, 0);
  }
  ngOnDestroy(): void {
    this.electronicUnsubscribe.unsubscribe();
  }
}
