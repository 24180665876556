import { Injectable } from '@angular/core';
import { Electronic, ElectronicsCategories, TableElectronics } from '@models';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import electroData from './electro-data.json';

@Injectable({
  providedIn: 'root',
})
export class ElectronicsService {
  private electronicItems: TableElectronics[] = [
    {
      name: 'kamera',
      selected: false,
      src: 'camera',
      width: 50,
      left: 375,
      bottom: 492,
    },
    {
      name: 'reproduktory',
      selected: false,
      src: 'reproductors',
      width: 700,
      left: 115,
      bottom: 300,
    },
    {
      name: 'počítač',
      selected: false,
      src: 'computer',
      width: 120,
      left: 600,
      bottom: 250,
    },
    {
      name: 'monitor',
      selected: false,
      src: 'monitor',
      width: 300,
      left: 250,
      bottom: 250,
    },
    {
      name: 'notebook',
      selected: false,
      src: 'laptop',
      width: 220,
      left: 300,
      bottom: 150,
    },
    {
      name: 'USB kľúč',
      selected: false,
      src: 'usb',
      width: 50,
      left: 150,
      bottom: 120,
    },
    {
      name: 'multifunkčná tlačiareň',
      selected: false,
      src: 'printer',
      width: 200,
      left: 60,
      bottom: 200,
    },
    {
      name: 'mobil',
      selected: false,
      src: 'mobile',
      width: 50,
      left: 100,
      bottom: 120,
    },
    {
      name: 'handsfree',
      selected: false,
      src: 'handsfree',
      width: 100,
      left: 200,
      bottom: 120,
    },
    {
      name: 'herná konzola',
      selected: false,
      src: 'console',
      width: 80,
      left: 510,
      bottom: 230,
    },
    {
      name: 'myš s podložkou',
      selected: false,
      src: 'mouse',
      width: 100,
      left: 497,
      bottom: 120,
    },
    {
      name: 'inteligentné hodinky',
      selected: false,
      src: 'watch',
      width: 50,
      left: 320,
      bottom: 130,
    },
    {
      name: 'lampa',
      selected: false,
      src: 'lamp',
      width: 220,
      left: 650,
      bottom: 200,
    },
    {
      name: 'kalkulačka',
      selected: false,
      src: 'calculator',
      width: 50,
      left: 700,
      bottom: 220,
    },
    {
      name: 'tablet',
      selected: false,
      src: 'tablet',
      width: 130,
      left: 770,
      bottom: 130,
    },
    {
      name: 'zápisník/papiere',
      selected: false,
      src: 'zapisnik',
      width: 80,
      left: 630,
      bottom: 110,
    },
    {
      name: 'pero',
      selected: false,
      src: 'pen',
      width: 60,
      left: 660,
      bottom: 130,
      transform: 'rotate(45deg)',
    },
    {
      name: 'ceruzka',
      selected: false,
      src: 'pencil',
      width: 50,
      left: 700,
      bottom: 150,
      transform: 'rotate(90deg)',
    },
  ];

  private _electronicItems$: BehaviorSubject<
    TableElectronics[]
  > = new BehaviorSubject<TableElectronics[]>([]);

  private _electronicCategoryItems$: BehaviorSubject<
    ElectronicsCategories[]
  > = new BehaviorSubject<ElectronicsCategories[]>([]);

  private electronicItems$ = this._electronicItems$.asObservable();
  private electronicCategoryItems$ = this._electronicCategoryItems$.asObservable();

  constructor() {
    this._electronicItems$.next(this.electronicItems);
    this._electronicCategoryItems$.next(electroData);
  }

  getElectronicItems(): Observable<TableElectronics[]> {
    return this.electronicItems$;
  }

  selectElectronicItem(selectedItem: TableElectronics): void {
    const values = this._electronicItems$.getValue().map((item) => ({
      ...item,
      selected: this.selectItem(item, selectedItem.name),
    }));
    this._electronicItems$.next(values);
  }

  private selectItem(
    name: { name: string; selected: boolean },
    selectedItem: string
  ): boolean {
    if (name.name !== selectedItem) {
      return name.selected;
    }
    return !name.selected;
  }

  selectElectronicCategoryItem(selectedItem: Electronic): void {
    const values = this._electronicCategoryItems$.getValue().map((item) => ({
      ...item,
      items: item.items.map((i) => {
        const selected = this.selectItem(i, selectedItem.name);
        const count = i.count || 1;
        return {
          ...i,
          selected,
          count: selected ? count : 0,
        };
      }),
    }));
    this._electronicCategoryItems$.next(values);
  }

  getElectronicsCategories(): Observable<ElectronicsCategories[]> {
    return this.electronicCategoryItems$;
  }

  changeAmount(electronic: Electronic, how: 'increase' | 'decrease'): void {
    const electronics = this._electronicCategoryItems$
      .getValue()
      .map((item) => {
        const items = item.items.map((i) => {
          let count = i?.count || 0;
          if (i.name === electronic.name) {
            how === 'increase' ? count++ : count--;
          }
          return {
            ...i,
            count,
            selected: !!count,
          };
        });
        return {
          ...item,
          items,
        };
      });

    this._electronicCategoryItems$.next(electronics);
  }

  getTotalCountElectronic(): Observable<number> {
    return this.electronicCategoryItems$.pipe(
      map((category) => {
        const total = category
          .map((cat) => {
            return cat.items.reduce((prev, curr) => {
              return prev + (curr?.count || 0);
            }, 0);
          })
          .reduce((p, c) => p + c);
        return total;
      })
    );
  }
}
