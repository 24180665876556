<div class="slide-grid">
  <div class="slide-left">

    <div class="slide-inner-left">
    <div class="select-wrap">
      <div class="slide-label">
        Usporiadaj si svoj stôl. <br> Označ z ponuky to, čo na stole máš a používaš.
      </div>

      <div class="items-box">
        <div class="items-box-inner">
          <ul class="items">
            <li
              #item
              class="item"
              [class.selected]="item.selected"
              *ngFor="let item of electronicItems; let i = index"
              (click)="select(item)"
            >
              <div class="check" [class.selected]="item.selected"></div>
              {{ item.name }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="table-wrap">
      <div  class="slide-label center">
        Teraz porovnaj tvoj a Einsteinov stôl. <br /> V čom vidíš hlavný rozdiel?
      </div>
      <div class="table">
        <img class="table-image" src="/assets/table-einst.jpg" alt="" />
        <div class="image-caption">
          “Alan Richards photographer. From the Institute for Advanced Study,
          NJ, USA.”
        </div>
      </div>
    </div>
    </div>
  </div>
  <div class="slide-right">

    <div class="slide-inner-right">
    <div class="blank-table">
      <img
        class="table-item"
        *ngFor="let item of tableItems; index as i"
        [src]="'/assets/your-table/' + item.src + '.svg'"
        [id]="item.src"
        [ngStyle]="{
          'width.px': item.width,
          'left.px': item.left,
          'bottom.px': item.bottom,
          transform: item.transform
        }"
      />
    </div>
    <div class="action">
      <div  *ngIf="tableItems.length && !showResult">
        <app-button (click)="showResults()" text="Hotovo"></app-button>
      </div>
      <div  *ngIf="showResult">
        <app-button (click)="next()" text="Ďalšia úloha"></app-button>
      </div>
    </div>
    </div>
  </div>
</div>
