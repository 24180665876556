<div
 class="table-evolution-wrap"
  *ngIf="{
    activeYearIndex: activeYearIndex$ | async
  } as vm"
>
  <ul class="timeline">
    <li
      [class.active]="i === vm.activeYearIndex"
      class="timeline-year"
      *ngFor="let year of years; index as i"
    >
      {{ year }}
    </li>
  </ul>
  <div class="table-evolution">

  <div class="table-setup">
    <div class="table-area">
      <div class="table-item-wrap"
      [ngStyle]="{
        'width.px': img.width,
        'left.px': img.left,
        'bottom.px': img.bottom
      }"
      *ngFor="let img of selectedTable.items">
        <img
        class="table-item"
        src="/assets/{{ selectedTable.year }}/{{ img.src }}.svg"

        alt=""
      />
      <app-tooltip class="tip" *ngIf="img.tooltip" [ngStyle]="{
        'top.px': 10,'right.px': 30, 'position': 'absolute'
      }">{{img.tooltip}}</app-tooltip>
      </div>

      <img
        src="/assets/{{ selectedTable.year }}/table.svg"
        alt=""
        class="table"
      />
    </div>
  </div>
  <div class="center actions" *ngIf="!animating">
    <app-button
      class="button"
      *ngIf="showButton"
      (click)="next(false, vm.activeYearIndex || 0)"
      text="Pozrieť rok {{ nextYear }}"
    ></app-button>
    <app-button
    class="button"
      *ngIf="!showButton"
      (click)="next(true, vm.activeYearIndex || 0)"
      text="Ďalšia úloha"
    ></app-button>
  </div>
</div>
</div>

<div class="bin-lid">
<svg xmlns="http://www.w3.org/2000/svg" width="209.688" height="213.082" viewBox="0 0 209.688 213.082">
  <g id="Group_1609" data-name="Group 1609" transform="translate(4265.624 -5226.673)">

    <g id="Group_1592" data-name="Group 1592">
      <path id="Rectangle_2061" data-name="Rectangle 2061" d="M0,0H107.548a0,0,0,0,1,0,0V10.838A11.289,11.289,0,0,1,96.259,22.127H11.289A11.289,11.289,0,0,1,0,10.838V0A0,0,0,0,1,0,0Z" transform="translate(-4172.59 5411.874)" fill="#cdc6cc"/>
      <path id="Rectangle_2062" data-name="Rectangle 2062" d="M0,0H40.354a0,0,0,0,1,0,0V10.838A11.289,11.289,0,0,1,29.065,22.127H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z" transform="translate(-4105.397 5411.874)" fill="#beb6c0"/>
      <rect id="Rectangle_2063" data-name="Rectangle 2063" width="47.188" height="22.127" transform="translate(-4130.218 5411.874)" fill="#cdc6cc"/>
      <rect id="Rectangle_2064" data-name="Rectangle 2064" width="43.124" height="22.127" transform="translate(-4154.377 5411.874)" fill="#e7e2e8"/>
    </g>

    <g id="Group_1603" data-name="Group 1603">


    </g>
    <g id="Group_1605" data-name="Group 1605">
      <path id="Path_943" data-name="Path 943" d="M-4175.874,5321.6l-.1-.053-10.227-5.042-5.813,11.789-43.092,87.393-5.814,11.789,10.227,5.043c.034.016.068.03.1.047,13.714,6.61,36.991-12.733,52.064-43.3S-4162.28,5328.453-4175.874,5321.6Z" fill="#a59da8"/>
      <ellipse id="Ellipse_326" data-name="Ellipse 326" cx="61.864" cy="27.658" rx="61.864" ry="27.658" transform="translate(-4265.624 5415.292) rotate(-63.753)" fill="#e7e2e8"/>
      <ellipse id="Ellipse_327" data-name="Ellipse 327" cx="44.189" cy="19.756" rx="44.189" ry="19.756" transform="translate(-4250.72 5402.934) rotate(-63.753)" fill="none" stroke="#cdc6cc" stroke-miterlimit="10" stroke-width="5.645"/>
      <g id="Group_1604" data-name="Group 1604">
        <line id="Line_1098" data-name="Line 1098" y1="6.683" x2="3.295" transform="translate(-4224.105 5384.65)" fill="#beb6c0" stroke="#a59da8" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4.741"/>
        <line id="Line_1099" data-name="Line 1099" y1="6.683" x2="3.295" transform="translate(-4207.929 5351.845)" fill="#beb6c0" stroke="#a59da8" stroke-linecap="round" stroke-miterlimit="10" stroke-width="4.741"/>
        <path id="Path_944" data-name="Path 944" d="M-4220.256,5384.294l-9.518-4.693,12.531-25.414,9.518,4.693" fill="none" stroke="#a59da8" stroke-linejoin="round" stroke-width="4.741"/>
      </g>
    </g>
  </g>
</svg>
</div>
<div #bin class="bin">
  <svg xmlns="http://www.w3.org/2000/svg" width="209.688" height="703.114" viewBox="0 0 209.688 703.114">
    <g id="Group_1606" data-name="Group 1606" transform="translate(4265.624 -4736.642)">
      <g id="Group_1592" data-name="Group 1592">
        <path id="Rectangle_2061" data-name="Rectangle 2061" d="M0,0H107.548a0,0,0,0,1,0,0V10.838A11.289,11.289,0,0,1,96.259,22.127H11.289A11.289,11.289,0,0,1,0,10.838V0A0,0,0,0,1,0,0Z" transform="translate(-4172.59 5411.874)" fill="#cdc6cc"/>
        <path id="Rectangle_2062" data-name="Rectangle 2062" d="M0,0H40.354a0,0,0,0,1,0,0V10.838A11.289,11.289,0,0,1,29.065,22.127H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z" transform="translate(-4105.397 5411.874)" fill="#beb6c0"/>
        <rect id="Rectangle_2063" data-name="Rectangle 2063" width="47.188" height="22.127" transform="translate(-4130.218 5411.874)" fill="#cdc6cc"/>
        <rect id="Rectangle_2064" data-name="Rectangle 2064" width="43.124" height="22.127" transform="translate(-4154.377 5411.874)" fill="#e7e2e8"/>
      </g>
      <g id="Group_1603" data-name="Group 1603" transform="translate(0 -97)">
        <g id="Group_1613" data-name="Group 1613" transform="translate(0 93)">
          <rect id="Rectangle_2075" data-name="Rectangle 2075" width="107.548" height="97.764" transform="translate(-4172.59 5323.141)" fill="#cdc6cc"/>
          <rect id="Rectangle_2076" data-name="Rectangle 2076" width="40.354" height="97.764" transform="translate(-4105.397 5323.141)" fill="#beb6c0"/>
          <rect id="Rectangle_2077" data-name="Rectangle 2077" width="47.188" height="97.764" transform="translate(-4130.218 5323.141)" fill="#cdc6cc"/>
          <rect id="Rectangle_2078" data-name="Rectangle 2078" width="43.124" height="97.764" transform="translate(-4154.377 5323.141)" fill="#e7e2e8"/>
        </g>
        <g id="Group_1615" data-name="Group 1615" transform="translate(0 -4.764)">
          <rect id="Rectangle_2075-2" data-name="Rectangle 2075" width="107.548" height="97.764" transform="translate(-4172.59 5323.141)" fill="#cdc6cc"/>
          <rect id="Rectangle_2076-2" data-name="Rectangle 2076" width="40.354" height="97.764" transform="translate(-4105.397 5323.141)" fill="#beb6c0"/>
          <rect id="Rectangle_2077-2" data-name="Rectangle 2077" width="47.188" height="97.764" transform="translate(-4130.218 5323.141)" fill="#cdc6cc"/>
          <rect id="Rectangle_2078-2" data-name="Rectangle 2078" width="43.124" height="97.764" transform="translate(-4154.377 5323.141)" fill="#e7e2e8"/>
        </g>
        <g id="Group_1616" data-name="Group 1616" transform="translate(0 -101.204)">
          <rect id="Rectangle_2075-3" data-name="Rectangle 2075" width="107.548" height="97.764" transform="translate(-4172.59 5323.141)" fill="#cdc6cc"/>
          <rect id="Rectangle_2076-3" data-name="Rectangle 2076" width="40.354" height="97.764" transform="translate(-4105.397 5323.141)" fill="#beb6c0"/>
          <rect id="Rectangle_2077-3" data-name="Rectangle 2077" width="47.188" height="97.764" transform="translate(-4130.218 5323.141)" fill="#cdc6cc"/>
          <rect id="Rectangle_2078-3" data-name="Rectangle 2078" width="43.124" height="97.764" transform="translate(-4154.377 5323.141)" fill="#e7e2e8"/>
        </g>
        <g id="Group_1621" data-name="Group 1621" transform="translate(0 -198.45)">
          <rect id="Rectangle_2075-4" data-name="Rectangle 2075" width="107.548" height="97.764" transform="translate(-4172.59 5323.141)" fill="#cdc6cc"/>
          <rect id="Rectangle_2076-4" data-name="Rectangle 2076" width="40.354" height="97.764" transform="translate(-4105.397 5323.141)" fill="#beb6c0"/>
          <rect id="Rectangle_2077-4" data-name="Rectangle 2077" width="47.188" height="97.764" transform="translate(-4130.218 5323.141)" fill="#cdc6cc"/>
          <rect id="Rectangle_2078-4" data-name="Rectangle 2078" width="43.124" height="97.764" transform="translate(-4154.377 5323.141)" fill="#e7e2e8"/>
        </g>
        <g id="Group_1623" data-name="Group 1623" transform="translate(0 -295.268)">
          <rect id="Rectangle_2075-5" data-name="Rectangle 2075" width="107.548" height="97.764" transform="translate(-4172.59 5323.141)" fill="#cdc6cc"/>
          <rect id="Rectangle_2076-5" data-name="Rectangle 2076" width="40.354" height="97.764" transform="translate(-4105.397 5323.141)" fill="#beb6c0"/>
          <rect id="Rectangle_2077-5" data-name="Rectangle 2077" width="47.188" height="97.764" transform="translate(-4130.218 5323.141)" fill="#cdc6cc"/>
          <rect id="Rectangle_2078-5" data-name="Rectangle 2078" width="43.124" height="97.764" transform="translate(-4154.377 5323.141)" fill="#e7e2e8"/>
        </g>
        <g id="Group_1612" data-name="Group 1612" transform="translate(0 93)">
          <g id="Group_1598" data-name="Group 1598">
            <rect id="Rectangle_2079" data-name="Rectangle 2079" width="12.87" height="79.475" rx="6.435" transform="translate(-4161.15 5331.946)" fill="#958d98"/>
            <rect id="Rectangle_2080" data-name="Rectangle 2080" width="10.668" height="76.089" rx="5.334" transform="translate(-4158.949 5335.333)" fill="#aaa3ad"/>
          </g>
          <g id="Group_1599" data-name="Group 1599">
            <rect id="Rectangle_2081" data-name="Rectangle 2081" width="12.87" height="79.475" rx="6.435" transform="translate(-4137.218 5331.946)" fill="#958d98"/>
            <rect id="Rectangle_2082" data-name="Rectangle 2082" width="10.668" height="76.089" rx="5.334" transform="translate(-4135.016 5335.333)" fill="#aaa3ad"/>
          </g>
          <g id="Group_1600" data-name="Group 1600">
            <rect id="Rectangle_2083" data-name="Rectangle 2083" width="12.87" height="79.475" rx="6.435" transform="translate(-4113.285 5331.946)" fill="#958d98"/>
            <rect id="Rectangle_2084" data-name="Rectangle 2084" width="10.668" height="76.089" rx="5.334" transform="translate(-4111.083 5335.333)" fill="#aaa3ad"/>
          </g>
          <g id="Group_1601" data-name="Group 1601">
            <rect id="Rectangle_2085" data-name="Rectangle 2085" width="12.87" height="79.475" rx="6.435" transform="translate(-4089.352 5331.946)" fill="#958d98"/>
            <rect id="Rectangle_2086" data-name="Rectangle 2086" width="10.668" height="76.089" rx="5.334" transform="translate(-4087.15 5335.333)" fill="#aaa3ad"/>
          </g>
        </g>
        <g id="Group_1614" data-name="Group 1614" transform="translate(0 -4.764)">
          <g id="Group_1598-2" data-name="Group 1598">
            <rect id="Rectangle_2079-2" data-name="Rectangle 2079" width="12.87" height="79.475" rx="6.435" transform="translate(-4161.15 5331.946)" fill="#958d98"/>
            <rect id="Rectangle_2080-2" data-name="Rectangle 2080" width="10.668" height="76.089" rx="5.334" transform="translate(-4158.949 5335.333)" fill="#aaa3ad"/>
          </g>
          <g id="Group_1599-2" data-name="Group 1599">
            <rect id="Rectangle_2081-2" data-name="Rectangle 2081" width="12.87" height="79.475" rx="6.435" transform="translate(-4137.218 5331.946)" fill="#958d98"/>
            <rect id="Rectangle_2082-2" data-name="Rectangle 2082" width="10.668" height="76.089" rx="5.334" transform="translate(-4135.016 5335.333)" fill="#aaa3ad"/>
          </g>
          <g id="Group_1600-2" data-name="Group 1600">
            <rect id="Rectangle_2083-2" data-name="Rectangle 2083" width="12.87" height="79.475" rx="6.435" transform="translate(-4113.285 5331.946)" fill="#958d98"/>
            <rect id="Rectangle_2084-2" data-name="Rectangle 2084" width="10.668" height="76.089" rx="5.334" transform="translate(-4111.083 5335.333)" fill="#aaa3ad"/>
          </g>
          <g id="Group_1601-2" data-name="Group 1601">
            <rect id="Rectangle_2085-2" data-name="Rectangle 2085" width="12.87" height="79.475" rx="6.435" transform="translate(-4089.352 5331.946)" fill="#958d98"/>
            <rect id="Rectangle_2086-2" data-name="Rectangle 2086" width="10.668" height="76.089" rx="5.334" transform="translate(-4087.15 5335.333)" fill="#aaa3ad"/>
          </g>
        </g>
        <g id="Group_1617" data-name="Group 1617" transform="translate(0 -101.204)">
          <g id="Group_1598-3" data-name="Group 1598">
            <rect id="Rectangle_2079-3" data-name="Rectangle 2079" width="12.87" height="79.475" rx="6.435" transform="translate(-4161.15 5331.946)" fill="#958d98"/>
            <rect id="Rectangle_2080-3" data-name="Rectangle 2080" width="10.668" height="76.089" rx="5.334" transform="translate(-4158.949 5335.333)" fill="#aaa3ad"/>
          </g>
          <g id="Group_1599-3" data-name="Group 1599">
            <rect id="Rectangle_2081-3" data-name="Rectangle 2081" width="12.87" height="79.475" rx="6.435" transform="translate(-4137.218 5331.946)" fill="#958d98"/>
            <rect id="Rectangle_2082-3" data-name="Rectangle 2082" width="10.668" height="76.089" rx="5.334" transform="translate(-4135.016 5335.333)" fill="#aaa3ad"/>
          </g>
          <g id="Group_1600-3" data-name="Group 1600">
            <rect id="Rectangle_2083-3" data-name="Rectangle 2083" width="12.87" height="79.475" rx="6.435" transform="translate(-4113.285 5331.946)" fill="#958d98"/>
            <rect id="Rectangle_2084-3" data-name="Rectangle 2084" width="10.668" height="76.089" rx="5.334" transform="translate(-4111.083 5335.333)" fill="#aaa3ad"/>
          </g>
          <g id="Group_1601-3" data-name="Group 1601">
            <rect id="Rectangle_2085-3" data-name="Rectangle 2085" width="12.87" height="79.475" rx="6.435" transform="translate(-4089.352 5331.946)" fill="#958d98"/>
            <rect id="Rectangle_2086-3" data-name="Rectangle 2086" width="10.668" height="76.089" rx="5.334" transform="translate(-4087.15 5335.333)" fill="#aaa3ad"/>
          </g>
        </g>
        <g id="Group_1620" data-name="Group 1620" transform="translate(0 -198.45)">
          <g id="Group_1598-4" data-name="Group 1598">
            <rect id="Rectangle_2079-4" data-name="Rectangle 2079" width="12.87" height="79.475" rx="6.435" transform="translate(-4161.15 5331.946)" fill="#958d98"/>
            <rect id="Rectangle_2080-4" data-name="Rectangle 2080" width="10.668" height="76.089" rx="5.334" transform="translate(-4158.949 5335.333)" fill="#aaa3ad"/>
          </g>
          <g id="Group_1599-4" data-name="Group 1599">
            <rect id="Rectangle_2081-4" data-name="Rectangle 2081" width="12.87" height="79.475" rx="6.435" transform="translate(-4137.218 5331.946)" fill="#958d98"/>
            <rect id="Rectangle_2082-4" data-name="Rectangle 2082" width="10.668" height="76.089" rx="5.334" transform="translate(-4135.016 5335.333)" fill="#aaa3ad"/>
          </g>
          <g id="Group_1600-4" data-name="Group 1600">
            <rect id="Rectangle_2083-4" data-name="Rectangle 2083" width="12.87" height="79.475" rx="6.435" transform="translate(-4113.285 5331.946)" fill="#958d98"/>
            <rect id="Rectangle_2084-4" data-name="Rectangle 2084" width="10.668" height="76.089" rx="5.334" transform="translate(-4111.083 5335.333)" fill="#aaa3ad"/>
          </g>
          <g id="Group_1601-4" data-name="Group 1601">
            <rect id="Rectangle_2085-4" data-name="Rectangle 2085" width="12.87" height="79.475" rx="6.435" transform="translate(-4089.352 5331.946)" fill="#958d98"/>
            <rect id="Rectangle_2086-4" data-name="Rectangle 2086" width="10.668" height="76.089" rx="5.334" transform="translate(-4087.15 5335.333)" fill="#aaa3ad"/>
          </g>
        </g>
        <g id="Group_1622" data-name="Group 1622" transform="translate(0 -295.268)">
          <g id="Group_1598-5" data-name="Group 1598">
            <rect id="Rectangle_2079-5" data-name="Rectangle 2079" width="12.87" height="79.475" rx="6.435" transform="translate(-4161.15 5331.946)" fill="#958d98"/>
            <rect id="Rectangle_2080-5" data-name="Rectangle 2080" width="10.668" height="76.089" rx="5.334" transform="translate(-4158.949 5335.333)" fill="#aaa3ad"/>
          </g>
          <g id="Group_1599-5" data-name="Group 1599">
            <rect id="Rectangle_2081-5" data-name="Rectangle 2081" width="12.87" height="79.475" rx="6.435" transform="translate(-4137.218 5331.946)" fill="#958d98"/>
            <rect id="Rectangle_2082-5" data-name="Rectangle 2082" width="10.668" height="76.089" rx="5.334" transform="translate(-4135.016 5335.333)" fill="#aaa3ad"/>
          </g>
          <g id="Group_1600-5" data-name="Group 1600">
            <rect id="Rectangle_2083-5" data-name="Rectangle 2083" width="12.87" height="79.475" rx="6.435" transform="translate(-4113.285 5331.946)" fill="#958d98"/>
            <rect id="Rectangle_2084-5" data-name="Rectangle 2084" width="10.668" height="76.089" rx="5.334" transform="translate(-4111.083 5335.333)" fill="#aaa3ad"/>
          </g>
          <g id="Group_1601-5" data-name="Group 1601">
            <rect id="Rectangle_2085-5" data-name="Rectangle 2085" width="12.87" height="79.475" rx="6.435" transform="translate(-4089.352 5331.946)" fill="#958d98"/>
            <rect id="Rectangle_2086-5" data-name="Rectangle 2086" width="10.668" height="76.089" rx="5.334" transform="translate(-4087.15 5335.333)" fill="#aaa3ad"/>
          </g>
        </g>
      </g>
      <g id="Group_1619" data-name="Group 1619" transform="translate(0 -0.236)">
        <g #trash id="Group_1591" data-name="Group 1591" transform="translate(0 -489.795)">
          <g id="Group_1496" data-name="Group 1496">
            <g id="Group_1464" data-name="Group 1464">
              <rect id="Rectangle_1874" data-name="Rectangle 1874" width="45.834" height="49.672" transform="translate(-4129.202 5238.698)" fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="0.677"/>
              <rect id="Rectangle_1875" data-name="Rectangle 1875" width="45.834" height="17.159" transform="translate(-4129.202 5238.698)" fill="#f9d53e" stroke="#000" stroke-miterlimit="10" stroke-width="0.452" style="isolation: isolate"/>
              <g id="Group_1457" data-name="Group 1457">
                <g id="Group_1452" data-name="Group 1452">
                  <rect id="Rectangle_1876" data-name="Rectangle 1876" width="3.161" height="3.161" transform="translate(-4124.799 5260.825)" fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="0.452"/>
                  <rect id="Rectangle_1877" data-name="Rectangle 1877" width="3.161" height="3.161" transform="translate(-4119.155 5260.825)" fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="0.452"/>
                  <rect id="Rectangle_1878" data-name="Rectangle 1878" width="3.161" height="3.161" transform="translate(-4113.51 5260.825)" fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="0.452"/>
                  <rect id="Rectangle_1879" data-name="Rectangle 1879" width="3.161" height="3.161" transform="translate(-4107.866 5260.825)" fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="0.452"/>
                  <rect id="Rectangle_1880" data-name="Rectangle 1880" width="3.161" height="3.161" transform="translate(-4102.221 5260.825)" fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="0.452"/>
                  <rect id="Rectangle_1881" data-name="Rectangle 1881" width="3.161" height="3.161" transform="translate(-4096.577 5260.825)" fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="0.452"/>
                  <rect id="Rectangle_1882" data-name="Rectangle 1882" width="3.161" height="3.161" transform="translate(-4090.932 5260.825)" fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="0.452"/>
                </g>
                <g id="Group_1453" data-name="Group 1453">
                  <rect id="Rectangle_1883" data-name="Rectangle 1883" width="3.161" height="3.161" transform="translate(-4124.799 5266.018)" fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="0.452"/>
                  <rect id="Rectangle_1884" data-name="Rectangle 1884" width="3.161" height="3.161" transform="translate(-4119.155 5266.018)" fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="0.452"/>
                  <rect id="Rectangle_1885" data-name="Rectangle 1885" width="3.161" height="3.161" transform="translate(-4113.51 5266.018)" fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="0.452"/>
                  <rect id="Rectangle_1886" data-name="Rectangle 1886" width="3.161" height="3.161" transform="translate(-4107.866 5266.018)" fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="0.452"/>
                  <rect id="Rectangle_1887" data-name="Rectangle 1887" width="3.161" height="3.161" transform="translate(-4102.221 5266.018)" fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="0.452"/>
                  <rect id="Rectangle_1888" data-name="Rectangle 1888" width="3.161" height="3.161" transform="translate(-4096.577 5266.018)" fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="0.452"/>
                  <rect id="Rectangle_1889" data-name="Rectangle 1889" width="3.161" height="3.161" transform="translate(-4090.932 5266.018)" fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="0.452"/>
                </g>
                <g id="Group_1454" data-name="Group 1454">
                  <rect id="Rectangle_1890" data-name="Rectangle 1890" width="3.161" height="3.161" transform="translate(-4124.799 5271.211)" fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="0.452"/>
                  <rect id="Rectangle_1891" data-name="Rectangle 1891" width="3.161" height="3.161" transform="translate(-4119.155 5271.211)" fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="0.452"/>
                  <rect id="Rectangle_1892" data-name="Rectangle 1892" width="3.161" height="3.161" transform="translate(-4113.51 5271.211)" fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="0.452"/>
                  <rect id="Rectangle_1893" data-name="Rectangle 1893" width="3.161" height="3.161" transform="translate(-4107.866 5271.211)" fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="0.452"/>
                  <rect id="Rectangle_1894" data-name="Rectangle 1894" width="3.161" height="3.161" transform="translate(-4102.221 5271.211)" fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="0.452"/>
                  <rect id="Rectangle_1895" data-name="Rectangle 1895" width="3.161" height="3.161" transform="translate(-4096.577 5271.211)" fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="0.452"/>
                  <rect id="Rectangle_1896" data-name="Rectangle 1896" width="3.161" height="3.161" transform="translate(-4090.932 5271.211)" fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="0.452"/>
                </g>
                <g id="Group_1455" data-name="Group 1455">
                  <rect id="Rectangle_1897" data-name="Rectangle 1897" width="3.161" height="3.161" transform="translate(-4124.799 5276.404)" fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="0.452"/>
                  <rect id="Rectangle_1898" data-name="Rectangle 1898" width="3.161" height="3.161" transform="translate(-4119.155 5276.404)" fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="0.452"/>
                  <rect id="Rectangle_1899" data-name="Rectangle 1899" width="3.161" height="3.161" transform="translate(-4113.51 5276.404)" fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="0.452"/>
                  <rect id="Rectangle_1900" data-name="Rectangle 1900" width="3.161" height="3.161" transform="translate(-4107.866 5276.404)" fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="0.452"/>
                  <rect id="Rectangle_1901" data-name="Rectangle 1901" width="3.161" height="3.161" transform="translate(-4102.221 5276.404)" fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="0.452"/>
                  <rect id="Rectangle_1902" data-name="Rectangle 1902" width="3.161" height="3.161" transform="translate(-4096.577 5276.404)" fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="0.452"/>
                  <rect id="Rectangle_1903" data-name="Rectangle 1903" width="3.161" height="3.161" transform="translate(-4090.932 5276.404)" fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="0.452"/>
                </g>
                <g id="Group_1456" data-name="Group 1456">
                  <rect id="Rectangle_1904" data-name="Rectangle 1904" width="3.161" height="3.161" transform="translate(-4124.799 5281.597)" fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="0.452"/>
                  <rect id="Rectangle_1905" data-name="Rectangle 1905" width="3.161" height="3.161" transform="translate(-4119.155 5281.597)" fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="0.452"/>
                  <rect id="Rectangle_1906" data-name="Rectangle 1906" width="3.161" height="3.161" transform="translate(-4113.51 5281.597)" fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="0.452"/>
                </g>
              </g>
              <g id="Group_1463" data-name="Group 1463">
                <g id="Group_1458" data-name="Group 1458">
                  <path id="Path_901" data-name="Path 901" d="M-4115.847,5264.438a18.766,18.766,0,0,1-3.822,3.87" fill="none" stroke="#ed1c24" stroke-linejoin="round" stroke-width="0.677"/>
                  <path id="Path_902" data-name="Path 902" d="M-4119.436,5264.548a30.311,30.311,0,0,0,3.776,3.428" fill="none" stroke="#ed1c24" stroke-linejoin="round" stroke-width="0.677"/>
                </g>
                <g id="Group_1459" data-name="Group 1459">
                  <path id="Path_903" data-name="Path 903" d="M-4104.783,5264.663a18.771,18.771,0,0,1-3.822,3.871" fill="none" stroke="#ed1c24" stroke-linejoin="round" stroke-width="0.677"/>
                  <path id="Path_904" data-name="Path 904" d="M-4108.373,5264.773a30.312,30.312,0,0,0,3.776,3.429" fill="none" stroke="#ed1c24" stroke-linejoin="round" stroke-width="0.677"/>
                </g>
                <g id="Group_1460" data-name="Group 1460">
                  <path id="Path_905" data-name="Path 905" d="M-4098.913,5274.823a18.757,18.757,0,0,1-3.822,3.871" fill="none" stroke="#ed1c24" stroke-linejoin="round" stroke-width="0.677"/>
                  <path id="Path_906" data-name="Path 906" d="M-4102.5,5274.934a30.26,30.26,0,0,0,3.775,3.428" fill="none" stroke="#ed1c24" stroke-linejoin="round" stroke-width="0.677"/>
                </g>
                <g id="Group_1461" data-name="Group 1461">
                  <path id="Path_907" data-name="Path 907" d="M-4093.494,5259.47a18.776,18.776,0,0,1-3.822,3.871" fill="none" stroke="#ed1c24" stroke-linejoin="round" stroke-width="0.677"/>
                  <path id="Path_908" data-name="Path 908" d="M-4097.083,5259.58a30.192,30.192,0,0,0,3.775,3.429" fill="none" stroke="#ed1c24" stroke-linejoin="round" stroke-width="0.677"/>
                </g>
                <g id="Group_1462" data-name="Group 1462">
                  <path id="Path_909" data-name="Path 909" d="M-4110.2,5275.049a18.747,18.747,0,0,1-3.822,3.871" fill="none" stroke="#ed1c24" stroke-linejoin="round" stroke-width="0.677"/>
                  <path id="Path_910" data-name="Path 910" d="M-4113.791,5275.159a30.23,30.23,0,0,0,3.775,3.429" fill="none" stroke="#ed1c24" stroke-linejoin="round" stroke-width="0.677"/>
                </g>
              </g>
            </g>
            <rect id="Rectangle_1907" data-name="Rectangle 1907" width="45.382" height="3.838" transform="translate(-4129.033 5254.052)" fill="#575350" opacity="0.2"/>
            <g id="Group_1495" data-name="Group 1495">
              <g id="Group_1479" data-name="Group 1479">
                <g id="Group_1466" data-name="Group 1466">
                  <g id="Ellipse_301" data-name="Ellipse 301" transform="translate(-4127.484 5240.164)" fill="#fff" stroke="#000" stroke-miterlimit="10" stroke-width="0.226">
                    <circle cx="0.806" cy="0.806" r="0.806" stroke="none"/>
                    <circle cx="0.806" cy="0.806" r="0.919" fill="none"/>
                  </g>
                  <g id="Group_1465" data-name="Group 1465">
                    <line id="Line_998" data-name="Line 998" y2="4.798" transform="translate(-4126.678 5236.333)" fill="#fff" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.452"/>
                  </g>
                </g>
                <g id="Group_1468" data-name="Group 1468">
                  <g id="Ellipse_302" data-name="Ellipse 302" transform="translate(-4124.906 5240.164)" fill="#fff" stroke="#000" stroke-miterlimit="10" stroke-width="0.226">
                    <circle cx="0.806" cy="0.806" r="0.806" stroke="none"/>
                    <circle cx="0.806" cy="0.806" r="0.919" fill="none"/>
                  </g>
                  <g id="Group_1467" data-name="Group 1467">
                    <line id="Line_999" data-name="Line 999" y2="4.798" transform="translate(-4124.101 5236.333)" fill="#fff" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.452"/>
                  </g>
                </g>
                <g id="Group_1470" data-name="Group 1470">
                  <g id="Ellipse_303" data-name="Ellipse 303" transform="translate(-4122.328 5240.164)" fill="#fff" stroke="#000" stroke-miterlimit="10" stroke-width="0.226">
                    <circle cx="0.806" cy="0.806" r="0.806" stroke="none"/>
                    <circle cx="0.806" cy="0.806" r="0.919" fill="none"/>
                  </g>
                  <g id="Group_1469" data-name="Group 1469">
                    <line id="Line_1000" data-name="Line 1000" y2="4.798" transform="translate(-4121.522 5236.333)" fill="#fff" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.452"/>
                  </g>
                </g>
                <g id="Group_1472" data-name="Group 1472">
                  <g id="Ellipse_304" data-name="Ellipse 304" transform="translate(-4119.75 5240.164)" fill="#fff" stroke="#000" stroke-miterlimit="10" stroke-width="0.226">
                    <circle cx="0.806" cy="0.806" r="0.806" stroke="none"/>
                    <circle cx="0.806" cy="0.806" r="0.919" fill="none"/>
                  </g>
                  <g id="Group_1471" data-name="Group 1471">
                    <line id="Line_1001" data-name="Line 1001" y2="4.798" transform="translate(-4118.944 5236.333)" fill="#fff" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.452"/>
                  </g>
                </g>
                <g id="Group_1474" data-name="Group 1474">
                  <g id="Ellipse_305" data-name="Ellipse 305" transform="translate(-4117.172 5240.164)" fill="#fff" stroke="#000" stroke-miterlimit="10" stroke-width="0.226">
                    <circle cx="0.806" cy="0.806" r="0.806" stroke="none"/>
                    <circle cx="0.806" cy="0.806" r="0.919" fill="none"/>
                  </g>
                  <g id="Group_1473" data-name="Group 1473">
                    <line id="Line_1002" data-name="Line 1002" y2="4.798" transform="translate(-4116.367 5236.333)" fill="#fff" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.452"/>
                  </g>
                </g>
                <g id="Group_1476" data-name="Group 1476">
                  <g id="Ellipse_306" data-name="Ellipse 306" transform="translate(-4114.594 5240.164)" fill="#fff" stroke="#000" stroke-miterlimit="10" stroke-width="0.226">
                    <circle cx="0.806" cy="0.806" r="0.806" stroke="none"/>
                    <circle cx="0.806" cy="0.806" r="0.919" fill="none"/>
                  </g>
                  <g id="Group_1475" data-name="Group 1475">
                    <line id="Line_1003" data-name="Line 1003" y2="4.798" transform="translate(-4113.789 5236.333)" fill="#fff" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.452"/>
                  </g>
                </g>
                <g id="Group_1478" data-name="Group 1478">
                  <g id="Ellipse_307" data-name="Ellipse 307" transform="translate(-4112.016 5240.164)" fill="#fff" stroke="#000" stroke-miterlimit="10" stroke-width="0.226">
                    <circle cx="0.806" cy="0.806" r="0.806" stroke="none"/>
                    <circle cx="0.806" cy="0.806" r="0.919" fill="none"/>
                  </g>
                  <g id="Group_1477" data-name="Group 1477">
                    <line id="Line_1004" data-name="Line 1004" y2="4.798" transform="translate(-4111.21 5236.333)" fill="#fff" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.452"/>
                  </g>
                </g>
              </g>
              <g id="Group_1494" data-name="Group 1494">
                <g id="Group_1481" data-name="Group 1481">
                  <g id="Ellipse_308" data-name="Ellipse 308" transform="translate(-4102.084 5240.164)" fill="#fff" stroke="#000" stroke-miterlimit="10" stroke-width="0.226">
                    <circle cx="0.806" cy="0.806" r="0.806" stroke="none"/>
                    <circle cx="0.806" cy="0.806" r="0.919" fill="none"/>
                  </g>
                  <g id="Group_1480" data-name="Group 1480">
                    <line id="Line_1005" data-name="Line 1005" y2="4.798" transform="translate(-4101.279 5236.333)" fill="#fff" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.452"/>
                  </g>
                </g>
                <g id="Group_1483" data-name="Group 1483">
                  <g id="Ellipse_309" data-name="Ellipse 309" transform="translate(-4099.506 5240.164)" fill="#fff" stroke="#000" stroke-miterlimit="10" stroke-width="0.226">
                    <circle cx="0.806" cy="0.806" r="0.806" stroke="none"/>
                    <circle cx="0.806" cy="0.806" r="0.919" fill="none"/>
                  </g>
                  <g id="Group_1482" data-name="Group 1482">
                    <line id="Line_1006" data-name="Line 1006" y2="4.798" transform="translate(-4098.701 5236.333)" fill="#fff" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.452"/>
                  </g>
                </g>
                <g id="Group_1485" data-name="Group 1485">
                  <g id="Ellipse_310" data-name="Ellipse 310" transform="translate(-4096.929 5240.164)" fill="#fff" stroke="#000" stroke-miterlimit="10" stroke-width="0.226">
                    <circle cx="0.806" cy="0.806" r="0.806" stroke="none"/>
                    <circle cx="0.806" cy="0.806" r="0.919" fill="none"/>
                  </g>
                  <g id="Group_1484" data-name="Group 1484">
                    <line id="Line_1007" data-name="Line 1007" y2="4.798" transform="translate(-4096.123 5236.333)" fill="#fff" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.452"/>
                  </g>
                </g>
                <g id="Group_1487" data-name="Group 1487">
                  <g id="Ellipse_311" data-name="Ellipse 311" transform="translate(-4094.351 5240.164)" fill="#fff" stroke="#000" stroke-miterlimit="10" stroke-width="0.226">
                    <circle cx="0.806" cy="0.806" r="0.806" stroke="none"/>
                    <circle cx="0.806" cy="0.806" r="0.919" fill="none"/>
                  </g>
                  <g id="Group_1486" data-name="Group 1486">
                    <line id="Line_1008" data-name="Line 1008" y2="4.798" transform="translate(-4093.545 5236.333)" fill="#fff" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.452"/>
                  </g>
                </g>
                <g id="Group_1489" data-name="Group 1489">
                  <g id="Ellipse_312" data-name="Ellipse 312" transform="translate(-4091.772 5240.164)" fill="#fff" stroke="#000" stroke-miterlimit="10" stroke-width="0.226">
                    <circle cx="0.806" cy="0.806" r="0.806" stroke="none"/>
                    <circle cx="0.806" cy="0.806" r="0.919" fill="none"/>
                  </g>
                  <g id="Group_1488" data-name="Group 1488">
                    <line id="Line_1009" data-name="Line 1009" y2="4.798" transform="translate(-4090.967 5236.333)" fill="#fff" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.452"/>
                  </g>
                </g>
                <g id="Group_1491" data-name="Group 1491">
                  <g id="Ellipse_313" data-name="Ellipse 313" transform="translate(-4089.195 5240.164)" fill="#fff" stroke="#000" stroke-miterlimit="10" stroke-width="0.226">
                    <circle cx="0.806" cy="0.806" r="0.806" stroke="none"/>
                    <circle cx="0.806" cy="0.806" r="0.919" fill="none"/>
                  </g>
                  <g id="Group_1490" data-name="Group 1490">
                    <line id="Line_1010" data-name="Line 1010" y2="4.798" transform="translate(-4088.389 5236.333)" fill="#fff" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.452"/>
                  </g>
                </g>
                <g id="Group_1493" data-name="Group 1493">
                  <g id="Ellipse_314" data-name="Ellipse 314" transform="translate(-4086.617 5240.164)" fill="#fff" stroke="#000" stroke-miterlimit="10" stroke-width="0.226">
                    <circle cx="0.806" cy="0.806" r="0.806" stroke="none"/>
                    <circle cx="0.806" cy="0.806" r="0.919" fill="none"/>
                  </g>
                  <g id="Group_1492" data-name="Group 1492">
                    <line id="Line_1011" data-name="Line 1011" y2="4.798" transform="translate(-4085.811 5236.333)" fill="#fff" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.452"/>
                  </g>
                </g>
              </g>
            </g>
            <circle id="Ellipse_315" data-name="Ellipse 315" cx="1.919" cy="1.919" r="1.919" transform="translate(-4108.035 5234.634)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.452"/>
          </g>
          <g id="Group_1506" data-name="Group 1506">
            <g id="Group_1504" data-name="Group 1504">
              <g id="Group_1503" data-name="Group 1503">
                <path id="Path_911" data-name="Path 911" d="M-4138.3,5249.578l1.05,1.636,1.254-1.485,1.05,1.636.269-.319a29.264,29.264,0,0,0-.007,4.342c.014.3.028.6.039.893.173,4.242-4.754,9.257-9.6,13.6-2.151,1.93-5.938,2.972-9.6,3.979a34.168,34.168,0,0,0-5.508,1.811c-.493.252-1.5,1.529-2.152,4.709a28.172,28.172,0,0,0-.521,4.475l-2.711-.1-2.71-.1a31.12,31.12,0,0,1,.578-5.029c.8-3.946,2.253-6.411,4.447-7.536a36.84,36.84,0,0,1,6.637-2.247c2.886-.793,6.156-1.692,7.409-2.817,2.087-1.873,8.44-7.574,8.311-10.749-.012-.287-.026-.579-.039-.873a29.615,29.615,0,0,1,.062-5.1l.486.757Z" fill="#575350" opacity="0.2"/>
                <rect id="Rectangle_1908" data-name="Rectangle 1908" width="24.159" height="17.159" rx="0.941" transform="translate(-4168.545 5280.468)" fill="#575350" opacity="0.2"/>
                <rect id="Rectangle_1909" data-name="Rectangle 1909" width="27.545" height="26.642" rx="1.252" transform="translate(-4167.642 5270.985)" fill="#575350" opacity="0.2"/>
                <rect id="Rectangle_1910" data-name="Rectangle 1910" width="24.159" height="17.159" rx="0.941" transform="translate(-4168.545 5277.759)" fill="#dbd9c9" stroke="#000" stroke-miterlimit="10" stroke-width="0.452"/>
                <path id="Path_912" data-name="Path 912" d="M-4146.718,5280.468h-19.5a2.217,2.217,0,0,1-2.215-2.329l.921-18.429a1.916,1.916,0,0,1,1.914-1.82h18.255a1.917,1.917,0,0,1,1.914,1.82l.921,18.429A2.217,2.217,0,0,1-4146.718,5280.468Z" fill="#8f876f" stroke="#000" stroke-miterlimit="10" stroke-width="0.452"/>
                <rect id="Rectangle_1911" data-name="Rectangle 1911" width="20.998" height="12.418" rx="0.746" transform="translate(-4166.964 5280.468)" fill="#dbd9c9" stroke="#000" stroke-miterlimit="10" stroke-width="0.452"/>
                <path id="Rectangle_1912" data-name="Rectangle 1912" d="M.722,0h18.2a.722.722,0,0,1,.722.722v7.02a4.676,4.676,0,0,1-4.676,4.676H4.676A4.676,4.676,0,0,1,0,7.742V.722A.722.722,0,0,1,.722,0Z" transform="translate(-4166.287 5257.89)" fill="#dbd9c9" stroke="#000" stroke-miterlimit="10" stroke-width="0.452"/>
                <g id="Group_1497" data-name="Group 1497">
                  <rect id="Rectangle_1913" data-name="Rectangle 1913" width="0.903" height="1.355" transform="translate(-4166.061 5284.532)" fill="#988a86" stroke="#000" stroke-miterlimit="10" stroke-width="0.452"/>
                  <rect id="Rectangle_1914" data-name="Rectangle 1914" width="0.903" height="1.355" transform="translate(-4164.481 5284.532)" fill="#988a86" stroke="#000" stroke-miterlimit="10" stroke-width="0.452"/>
                  <rect id="Rectangle_1915" data-name="Rectangle 1915" width="0.903" height="1.355" transform="translate(-4162.9 5284.532)" fill="#988a86" stroke="#000" stroke-miterlimit="10" stroke-width="0.452"/>
                  <rect id="Rectangle_1916" data-name="Rectangle 1916" width="0.903" height="1.355" transform="translate(-4166.061 5286.79)" fill="#988a86" stroke="#000" stroke-miterlimit="10" stroke-width="0.452"/>
                  <rect id="Rectangle_1917" data-name="Rectangle 1917" width="0.903" height="1.355" transform="translate(-4164.481 5286.79)" fill="#9c2922" stroke="#000" stroke-miterlimit="10" stroke-width="0.452"/>
                  <rect id="Rectangle_1918" data-name="Rectangle 1918" width="0.903" height="1.355" transform="translate(-4162.9 5286.79)" fill="#988a86" stroke="#000" stroke-miterlimit="10" stroke-width="0.452"/>
                </g>
                <g id="Group_1500" data-name="Group 1500">
                  <circle id="Ellipse_316" data-name="Ellipse 316" cx="0.79" cy="0.79" r="0.79" transform="translate(-4158.61 5282.048)" fill="#b89667" stroke="#000" stroke-miterlimit="10" stroke-width="0.452"/>
                  <circle id="Ellipse_317" data-name="Ellipse 317" cx="0.79" cy="0.79" r="0.79" transform="translate(-4156.353 5282.048)" fill="#b89667" stroke="#000" stroke-miterlimit="10" stroke-width="0.452"/>
                  <circle id="Ellipse_318" data-name="Ellipse 318" cx="0.79" cy="0.79" r="0.79" transform="translate(-4154.095 5282.048)" fill="#b89667" stroke="#000" stroke-miterlimit="10" stroke-width="0.452"/>
                  <g id="Group_1498" data-name="Group 1498">
                    <circle id="Ellipse_319" data-name="Ellipse 319" cx="0.79" cy="0.79" r="0.79" transform="translate(-4158.61 5284.306)" fill="#b89667" stroke="#000" stroke-miterlimit="10" stroke-width="0.452"/>
                    <circle id="Ellipse_320" data-name="Ellipse 320" cx="0.79" cy="0.79" r="0.79" transform="translate(-4156.353 5284.306)" fill="#b89667" stroke="#000" stroke-miterlimit="10" stroke-width="0.452"/>
                    <circle id="Ellipse_321" data-name="Ellipse 321" cx="0.79" cy="0.79" r="0.79" transform="translate(-4154.095 5284.306)" fill="#b89667" stroke="#000" stroke-miterlimit="10" stroke-width="0.452"/>
                  </g>
                  <g id="Group_1499" data-name="Group 1499">
                    <circle id="Ellipse_322" data-name="Ellipse 322" cx="0.79" cy="0.79" r="0.79" transform="translate(-4158.61 5286.564)" fill="#b89667" stroke="#000" stroke-miterlimit="10" stroke-width="0.452"/>
                    <circle id="Ellipse_323" data-name="Ellipse 323" cx="0.79" cy="0.79" r="0.79" transform="translate(-4156.353 5286.564)" fill="#b89667" stroke="#000" stroke-miterlimit="10" stroke-width="0.452"/>
                    <circle id="Ellipse_324" data-name="Ellipse 324" cx="0.79" cy="0.79" r="0.79" transform="translate(-4154.095 5286.564)" fill="#b89667" stroke="#000" stroke-miterlimit="10" stroke-width="0.452"/>
                  </g>
                </g>
                <rect id="Rectangle_1919" data-name="Rectangle 1919" width="2.032" height="5.645" transform="translate(-4151.385 5282.5)" fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="0.452"/>
                <rect id="Rectangle_1920" data-name="Rectangle 1920" width="0.903" height="2.935" transform="translate(-4148.225 5285.209)" fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="0.452"/>
                <rect id="Rectangle_1921" data-name="Rectangle 1921" width="2.709" height="1.355" transform="translate(-4158.61 5289.048)" fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="0.452"/>
                <rect id="Rectangle_1922" data-name="Rectangle 1922" width="2.709" height="1.355" transform="translate(-4155.224 5289.048)" fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="0.452"/>
                <g id="Group_1501" data-name="Group 1501">
                  <line id="Line_1012" data-name="Line 1012" x2="5.645" transform="translate(-4152.966 5291.306)" fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="0.452"/>
                  <rect id="Rectangle_1923" data-name="Rectangle 1923" width="0.903" height="1.355" transform="translate(-4150.482 5290.628)" fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="0.452"/>
                </g>
                <path id="Path_913" data-name="Path 913" d="M-4155.224,5262.4h-7.676v2.258s2.483,0,2.483,2.032h8.354c0-2.032,2.484-2.032,2.484-2.032V5262.4Z"/>
                <g id="Group_1502" data-name="Group 1502" opacity="0.4">
                  <line id="Line_1013" data-name="Line 1013" y2="2.032" transform="translate(-4161.545 5263.083)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.452"/>
                  <line id="Line_1014" data-name="Line 1014" y2="2.032" transform="translate(-4160.417 5263.083)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.452"/>
                  <line id="Line_1015" data-name="Line 1015" y2="2.032" transform="translate(-4159.288 5263.083)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.452"/>
                  <line id="Line_1016" data-name="Line 1016" y2="2.032" transform="translate(-4158.159 5263.083)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.452"/>
                  <line id="Line_1017" data-name="Line 1017" y2="2.032" transform="translate(-4157.03 5263.083)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.452"/>
                  <line id="Line_1018" data-name="Line 1018" y2="2.032" transform="translate(-4155.901 5263.083)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.452"/>
                  <line id="Line_1019" data-name="Line 1019" y2="2.032" transform="translate(-4154.772 5263.083)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.452"/>
                  <line id="Line_1020" data-name="Line 1020" y2="2.032" transform="translate(-4153.643 5263.083)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.452"/>
                  <line id="Line_1021" data-name="Line 1021" y2="2.032" transform="translate(-4152.514 5263.083)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.452"/>
                  <line id="Line_1022" data-name="Line 1022" y2="2.032" transform="translate(-4151.385 5263.083)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.452"/>
                </g>
              </g>
              <path id="Path_914" data-name="Path 914" d="M-4137.277,5227l.9,1.564.9-1.564.9,1.564.194-.336a37.05,37.05,0,0,0,.216,4.333c.026.3.052.6.077.889.351,4.222-3.239,9.548-6.8,14.2-1.581,2.066-4.484,3.353-7.292,4.6a23.864,23.864,0,0,0-4.208,2.167c-.372.285-1.094,1.624-1.439,4.84a35.949,35.949,0,0,0-.178,4.5l-2.121.077-2.122.077a39.838,39.838,0,0,1,.194-5.056c.422-3.989,1.432-6.544,3.087-7.81a25.728,25.728,0,0,1,5.067-2.676c2.212-.98,4.719-2.091,5.639-3.295,1.534-2.006,6.2-8.11,5.939-11.27q-.036-.429-.075-.869a37.772,37.772,0,0,1-.212-5.094l.418.724Z" fill="#f9d53e" stroke="#000" stroke-miterlimit="10" stroke-width="0.327" style="isolation: isolate"/>
            </g>
            <rect id="Rectangle_1924" data-name="Rectangle 1924" width="11.927" height="1.438" rx="0.719" transform="translate(-4162.184 5277.082)" fill="#575350" opacity="0.2"/>
            <rect id="Rectangle_1925" data-name="Rectangle 1925" width="11.927" height="1.438" rx="0.719" transform="translate(-4162.184 5274.372)" fill="#575350" opacity="0.2"/>
            <rect id="Rectangle_1926" data-name="Rectangle 1926" width="11.927" height="1.438" rx="0.719" transform="translate(-4162.184 5271.663)" fill="#575350" opacity="0.2"/>
            <g id="Group_1505" data-name="Group 1505">
              <path id="Path_915" data-name="Path 915" d="M-4168.389,5272.561s-11.784-2.14-16.893,0c-4.407,1.845-7.9,9.031-12.262,11.289s-9.264,2.032-15.259,3.161-7.2,10.076-7.2,10.076" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="1.129"/>
              <path id="Path_916" data-name="Path 916" d="M-4168.116,5275.5s-11.379-1.914-16.894,0c-4.513,1.566-6.948,7.338-11.308,9.6s-9.809,1.58-15.8,2.709-7.9,9.483-7.9,9.483" fill="none" stroke="#575350" stroke-miterlimit="10" stroke-width="1.129" opacity="0.2"/>
            </g>
          </g>
          <g id="Group_1575" data-name="Group 1575">
            <g id="Group_1571" data-name="Group 1571">
              <path id="Path_917" data-name="Path 917" d="M-4065.773,5289.033c0-.654-.408-3.266-1.96-8.329s-2.286-4.409-2.286-4.409l-18.686.362-18.686-.362s-.735-.653-2.286,4.409-1.96,7.675-1.96,8.329-.163,3.756,2.287,4.164a202.935,202.935,0,0,0,20.645.815,202.956,202.956,0,0,0,20.646-.815C-4065.61,5292.789-4065.773,5289.686-4065.773,5289.033Z" fill="#575350" opacity="0.2"/>
              <g id="Group_1507" data-name="Group 1507">
                <path id="Path_918" data-name="Path 918" d="M-4069.766,5265.534l-1.9-.216a.847.847,0,0,1-.752-.842v-1.262a.849.849,0,0,1,.752-.843l1.9-.215a.849.849,0,0,1,.944.842v1.693A.848.848,0,0,1-4069.766,5265.534Z" fill="#010101"/>
                <path id="Path_919" data-name="Path 919" d="M-4107.644,5262.156l1.9.215a.848.848,0,0,1,.752.843v1.262a.846.846,0,0,1-.752.842l-1.9.216a.848.848,0,0,1-.943-.843V5263A.848.848,0,0,1-4107.644,5262.156Z" fill="#010101"/>
              </g>
              <path id="Path_920" data-name="Path 920" d="M-4074.518,5267.111h-28.374a2.271,2.271,0,0,1-2.249-1.962l-.194-1.42a2.27,2.27,0,0,1,2.249-2.579h28.763a2.271,2.271,0,0,1,2.249,2.579l-.2,1.42A2.27,2.27,0,0,1-4074.518,5267.111Z" fill="#7a9659" stroke="#000" stroke-miterlimit="10" stroke-width="0.212"/>
              <rect id="Rectangle_1927" data-name="Rectangle 1927" width="27.925" height="2.613" transform="translate(-4102.667 5262.293)" fill="#010101"/>
              <path id="Path_921" data-name="Path 921" d="M-4065.773,5287.443c0-.654-.408-3.267-1.96-8.329s-2.286-4.409-2.286-4.409l-18.686.362-18.686-.362s-.735-.654-2.286,4.409-1.96,7.675-1.96,8.329-.163,3.756,2.287,4.164a202.991,202.991,0,0,0,20.645.815,203.012,203.012,0,0,0,20.646-.815C-4065.61,5291.2-4065.773,5288.1-4065.773,5287.443Z" fill="#b2d99c" stroke="#000" stroke-miterlimit="10" stroke-width="0.212"/>
              <path id="Path_922" data-name="Path 922" d="M-4067.026,5286.136c0-.653-1.8-7.349-2.041-8.329s-.408-5.96-1.47-8.41-4.164-4.9-4.164-4.9l-8.329-.207-5.675-.142-5.675.142-8.328.207s-3.1,2.45-4.165,4.9-1.224,7.431-1.469,8.41-2.042,7.676-2.042,8.329-.408,2.123,1.388,2.286c1.44.131,12,.315,17.907.382.432.017.913.027,1.445.027.25,0,.567,0,.939,0s.69,0,.939,0c.532,0,1.013-.01,1.446-.027,5.905-.067,16.467-.251,17.906-.382C-4066.618,5288.259-4067.026,5286.789-4067.026,5286.136Z" fill="#7a9659" stroke="#000" stroke-miterlimit="10" stroke-width="0.212"/>
              <g id="Group_1558" data-name="Group 1558">
                <path id="Path_923" data-name="Path 923" d="M-4068.956,5281.6a19.2,19.2,0,0,1-1.512-4.293l-.712-5.05-.89-1.958h-15.295v-.033h-17.975l-.89,1.957-.711,5.05a19.161,19.161,0,0,1-1.513,4.293,10.23,10.23,0,0,0-1.043,5.391c.124.61,1.666.794,2.6.838.68.033,10.925.136,16.454.189l0-.039s19-.072,19.932-.116,2.477-.228,2.6-.839A10.212,10.212,0,0,0-4068.956,5281.6Z" fill="#c5c6af" stroke="#000" stroke-miterlimit="10" stroke-width="0.212"/>
                <path id="Rectangle_1928" data-name="Rectangle 1928" d="M3.324,0H32.375A3.324,3.324,0,0,1,35.7,3.324V5.471a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V3.324A3.324,3.324,0,0,1,3.324,0Z" transform="translate(-4106.555 5268.744)" fill="#303d2c"/>
                <g id="Group_1508" data-name="Group 1508">
                  <rect id="Rectangle_1929" data-name="Rectangle 1929" width="18.372" height="1.225" transform="translate(-4097.891 5285.81)" fill="#e2dfc7" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                  <rect id="Rectangle_1930" data-name="Rectangle 1930" width="18.372" height="1.225" rx="0.408" transform="translate(-4097.891 5285.156)" fill="#f7f5dd" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                </g>
                <g id="Group_1557" data-name="Group 1557">
                  <g id="Group_1520" data-name="Group 1520">
                    <g id="Group_1509" data-name="Group 1509">
                      <rect id="Rectangle_1931" data-name="Rectangle 1931" width="1.551" height="1.061" transform="translate(-4099.01 5278.297)" fill="#e2dfc7" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                      <rect id="Rectangle_1932" data-name="Rectangle 1932" width="1.551" height="1.061" rx="0.459" transform="translate(-4099.01 5277.481)" fill="#fff" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                    </g>
                    <g id="Group_1510" data-name="Group 1510">
                      <rect id="Rectangle_1933" data-name="Rectangle 1933" width="1.551" height="1.061" transform="translate(-4096.969 5278.297)" fill="#e2dfc7" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                      <rect id="Rectangle_1934" data-name="Rectangle 1934" width="1.551" height="1.061" rx="0.459" transform="translate(-4096.969 5277.481)" fill="#f7f5dd" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                    </g>
                    <g id="Group_1511" data-name="Group 1511">
                      <rect id="Rectangle_1935" data-name="Rectangle 1935" width="1.551" height="1.061" transform="translate(-4094.928 5278.297)" fill="#e2dfc7" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                      <rect id="Rectangle_1936" data-name="Rectangle 1936" width="1.551" height="1.061" rx="0.459" transform="translate(-4094.928 5277.481)" fill="#fff" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                    </g>
                    <g id="Group_1512" data-name="Group 1512">
                      <rect id="Rectangle_1937" data-name="Rectangle 1937" width="1.551" height="1.061" transform="translate(-4092.886 5278.297)" fill="#e2dfc7" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                      <rect id="Rectangle_1938" data-name="Rectangle 1938" width="1.551" height="1.061" rx="0.459" transform="translate(-4092.886 5277.481)" fill="#f7f5dd" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                    </g>
                    <g id="Group_1513" data-name="Group 1513">
                      <rect id="Rectangle_1939" data-name="Rectangle 1939" width="1.551" height="1.061" transform="translate(-4090.845 5278.297)" fill="#e2dfc7" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                      <rect id="Rectangle_1940" data-name="Rectangle 1940" width="1.551" height="1.061" rx="0.459" transform="translate(-4090.845 5277.481)" fill="#fff" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                    </g>
                    <g id="Group_1514" data-name="Group 1514">
                      <rect id="Rectangle_1941" data-name="Rectangle 1941" width="1.551" height="1.061" transform="translate(-4088.804 5278.297)" fill="#e2dfc7" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                      <rect id="Rectangle_1942" data-name="Rectangle 1942" width="1.551" height="1.061" rx="0.459" transform="translate(-4088.804 5277.481)" fill="#f7f5dd" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                    </g>
                    <g id="Group_1515" data-name="Group 1515">
                      <rect id="Rectangle_1943" data-name="Rectangle 1943" width="1.551" height="1.061" transform="translate(-4086.762 5278.297)" fill="#e2dfc7" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                      <rect id="Rectangle_1944" data-name="Rectangle 1944" width="1.551" height="1.061" rx="0.459" transform="translate(-4086.762 5277.481)" fill="#fff" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                    </g>
                    <g id="Group_1516" data-name="Group 1516">
                      <rect id="Rectangle_1945" data-name="Rectangle 1945" width="1.551" height="1.061" transform="translate(-4084.721 5278.297)" fill="#e2dfc7" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                      <rect id="Rectangle_1946" data-name="Rectangle 1946" width="1.551" height="1.061" rx="0.459" transform="translate(-4084.721 5277.481)" fill="#f7f5dd" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                    </g>
                    <g id="Group_1517" data-name="Group 1517">
                      <rect id="Rectangle_1947" data-name="Rectangle 1947" width="1.551" height="1.061" transform="translate(-4082.68 5278.297)" fill="#e2dfc7" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                      <rect id="Rectangle_1948" data-name="Rectangle 1948" width="1.551" height="1.061" rx="0.459" transform="translate(-4082.68 5277.481)" fill="#fff" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                    </g>
                    <g id="Group_1518" data-name="Group 1518">
                      <rect id="Rectangle_1949" data-name="Rectangle 1949" width="1.551" height="1.061" transform="translate(-4080.638 5278.297)" fill="#e2dfc7" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                      <rect id="Rectangle_1950" data-name="Rectangle 1950" width="1.551" height="1.061" rx="0.459" transform="translate(-4080.638 5277.481)" fill="#f7f5dd" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                    </g>
                    <g id="Group_1519" data-name="Group 1519">
                      <rect id="Rectangle_1951" data-name="Rectangle 1951" width="1.551" height="1.061" transform="translate(-4078.597 5278.297)" fill="#e2dfc7" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                      <rect id="Rectangle_1952" data-name="Rectangle 1952" width="1.551" height="1.061" rx="0.459" transform="translate(-4078.597 5277.481)" fill="#fff" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                    </g>
                  </g>
                  <g id="Group_1532" data-name="Group 1532">
                    <g id="Group_1521" data-name="Group 1521">
                      <rect id="Rectangle_1953" data-name="Rectangle 1953" width="1.551" height="1.061" transform="translate(-4100.072 5279.767)" fill="#e2dfc7" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                      <rect id="Rectangle_1954" data-name="Rectangle 1954" width="1.551" height="1.061" rx="0.459" transform="translate(-4100.072 5278.951)" fill="#f7f5dd" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                    </g>
                    <g id="Group_1522" data-name="Group 1522">
                      <rect id="Rectangle_1955" data-name="Rectangle 1955" width="1.551" height="1.061" transform="translate(-4098.031 5279.767)" fill="#e2dfc7" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                      <rect id="Rectangle_1956" data-name="Rectangle 1956" width="1.551" height="1.061" rx="0.459" transform="translate(-4098.031 5278.951)" fill="#fff" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                    </g>
                    <g id="Group_1523" data-name="Group 1523">
                      <rect id="Rectangle_1957" data-name="Rectangle 1957" width="1.551" height="1.061" transform="translate(-4095.989 5279.767)" fill="#e2dfc7" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                      <rect id="Rectangle_1958" data-name="Rectangle 1958" width="1.551" height="1.061" rx="0.459" transform="translate(-4095.989 5278.951)" fill="#f7f5dd" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                    </g>
                    <g id="Group_1524" data-name="Group 1524">
                      <rect id="Rectangle_1959" data-name="Rectangle 1959" width="1.551" height="1.061" transform="translate(-4093.948 5279.767)" fill="#e2dfc7" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                      <rect id="Rectangle_1960" data-name="Rectangle 1960" width="1.551" height="1.061" rx="0.459" transform="translate(-4093.948 5278.951)" fill="#fff" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                    </g>
                    <g id="Group_1525" data-name="Group 1525">
                      <rect id="Rectangle_1961" data-name="Rectangle 1961" width="1.551" height="1.061" transform="translate(-4091.906 5279.767)" fill="#e2dfc7" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                      <rect id="Rectangle_1962" data-name="Rectangle 1962" width="1.551" height="1.061" rx="0.459" transform="translate(-4091.906 5278.951)" fill="#f7f5dd" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                    </g>
                    <g id="Group_1526" data-name="Group 1526">
                      <rect id="Rectangle_1963" data-name="Rectangle 1963" width="1.551" height="1.061" transform="translate(-4089.865 5279.767)" fill="#e2dfc7" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                      <rect id="Rectangle_1964" data-name="Rectangle 1964" width="1.551" height="1.061" rx="0.459" transform="translate(-4089.865 5278.951)" fill="#fff" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                    </g>
                    <g id="Group_1527" data-name="Group 1527">
                      <rect id="Rectangle_1965" data-name="Rectangle 1965" width="1.551" height="1.061" transform="translate(-4087.824 5279.767)" fill="#e2dfc7" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                      <rect id="Rectangle_1966" data-name="Rectangle 1966" width="1.551" height="1.061" rx="0.459" transform="translate(-4087.824 5278.951)" fill="#f7f5dd" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                    </g>
                    <g id="Group_1528" data-name="Group 1528">
                      <rect id="Rectangle_1967" data-name="Rectangle 1967" width="1.551" height="1.061" transform="translate(-4085.782 5279.767)" fill="#e2dfc7" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                      <rect id="Rectangle_1968" data-name="Rectangle 1968" width="1.551" height="1.061" rx="0.459" transform="translate(-4085.782 5278.951)" fill="#fff" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                    </g>
                    <g id="Group_1529" data-name="Group 1529">
                      <rect id="Rectangle_1969" data-name="Rectangle 1969" width="1.551" height="1.061" transform="translate(-4083.741 5279.767)" fill="#e2dfc7" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                      <rect id="Rectangle_1970" data-name="Rectangle 1970" width="1.551" height="1.061" rx="0.459" transform="translate(-4083.741 5278.951)" fill="#f7f5dd" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                    </g>
                    <g id="Group_1530" data-name="Group 1530">
                      <rect id="Rectangle_1971" data-name="Rectangle 1971" width="1.551" height="1.061" transform="translate(-4081.7 5279.767)" fill="#e2dfc7" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                      <rect id="Rectangle_1972" data-name="Rectangle 1972" width="1.551" height="1.061" rx="0.459" transform="translate(-4081.7 5278.951)" fill="#fff" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                    </g>
                    <g id="Group_1531" data-name="Group 1531">
                      <rect id="Rectangle_1973" data-name="Rectangle 1973" width="1.551" height="1.061" transform="translate(-4079.659 5279.767)" fill="#e2dfc7" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                      <rect id="Rectangle_1974" data-name="Rectangle 1974" width="1.551" height="1.061" rx="0.459" transform="translate(-4079.659 5278.951)" fill="#f7f5dd" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                    </g>
                  </g>
                  <g id="Group_1544" data-name="Group 1544">
                    <g id="Group_1533" data-name="Group 1533">
                      <rect id="Rectangle_1975" data-name="Rectangle 1975" width="1.551" height="1.061" transform="translate(-4099.01 5281.4)" fill="#e2dfc7" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                      <rect id="Rectangle_1976" data-name="Rectangle 1976" width="1.551" height="1.061" rx="0.459" transform="translate(-4099.01 5280.583)" fill="#fff" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                    </g>
                    <g id="Group_1534" data-name="Group 1534">
                      <rect id="Rectangle_1977" data-name="Rectangle 1977" width="1.551" height="1.061" transform="translate(-4096.969 5281.4)" fill="#e2dfc7" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                      <rect id="Rectangle_1978" data-name="Rectangle 1978" width="1.551" height="1.061" rx="0.459" transform="translate(-4096.969 5280.583)" fill="#f7f5dd" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                    </g>
                    <g id="Group_1535" data-name="Group 1535">
                      <rect id="Rectangle_1979" data-name="Rectangle 1979" width="1.551" height="1.061" transform="translate(-4094.928 5281.4)" fill="#e2dfc7" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                      <rect id="Rectangle_1980" data-name="Rectangle 1980" width="1.551" height="1.061" rx="0.459" transform="translate(-4094.928 5280.583)" fill="#fff" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                    </g>
                    <g id="Group_1536" data-name="Group 1536">
                      <rect id="Rectangle_1981" data-name="Rectangle 1981" width="1.551" height="1.061" transform="translate(-4092.886 5281.4)" fill="#e2dfc7" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                      <rect id="Rectangle_1982" data-name="Rectangle 1982" width="1.551" height="1.061" rx="0.459" transform="translate(-4092.886 5280.583)" fill="#f7f5dd" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                    </g>
                    <g id="Group_1537" data-name="Group 1537">
                      <rect id="Rectangle_1983" data-name="Rectangle 1983" width="1.551" height="1.061" transform="translate(-4090.845 5281.4)" fill="#e2dfc7" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                      <rect id="Rectangle_1984" data-name="Rectangle 1984" width="1.551" height="1.061" rx="0.459" transform="translate(-4090.845 5280.583)" fill="#fff" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                    </g>
                    <g id="Group_1538" data-name="Group 1538">
                      <rect id="Rectangle_1985" data-name="Rectangle 1985" width="1.551" height="1.061" transform="translate(-4088.804 5281.4)" fill="#e2dfc7" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                      <rect id="Rectangle_1986" data-name="Rectangle 1986" width="1.551" height="1.061" rx="0.459" transform="translate(-4088.804 5280.583)" fill="#f7f5dd" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                    </g>
                    <g id="Group_1539" data-name="Group 1539">
                      <rect id="Rectangle_1987" data-name="Rectangle 1987" width="1.551" height="1.061" transform="translate(-4086.762 5281.4)" fill="#e2dfc7" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                      <rect id="Rectangle_1988" data-name="Rectangle 1988" width="1.551" height="1.061" rx="0.459" transform="translate(-4086.762 5280.583)" fill="#fff" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                    </g>
                    <g id="Group_1540" data-name="Group 1540">
                      <rect id="Rectangle_1989" data-name="Rectangle 1989" width="1.551" height="1.061" transform="translate(-4084.721 5281.4)" fill="#e2dfc7" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                      <rect id="Rectangle_1990" data-name="Rectangle 1990" width="1.551" height="1.061" rx="0.459" transform="translate(-4084.721 5280.583)" fill="#f7f5dd" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                    </g>
                    <g id="Group_1541" data-name="Group 1541">
                      <rect id="Rectangle_1991" data-name="Rectangle 1991" width="1.551" height="1.061" transform="translate(-4082.68 5281.4)" fill="#e2dfc7" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                      <rect id="Rectangle_1992" data-name="Rectangle 1992" width="1.551" height="1.061" rx="0.459" transform="translate(-4082.68 5280.583)" fill="#fff" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                    </g>
                    <g id="Group_1542" data-name="Group 1542">
                      <rect id="Rectangle_1993" data-name="Rectangle 1993" width="1.551" height="1.061" transform="translate(-4080.638 5281.4)" fill="#e2dfc7" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                      <rect id="Rectangle_1994" data-name="Rectangle 1994" width="1.551" height="1.061" rx="0.459" transform="translate(-4080.638 5280.583)" fill="#f7f5dd" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                    </g>
                    <g id="Group_1543" data-name="Group 1543">
                      <rect id="Rectangle_1995" data-name="Rectangle 1995" width="1.551" height="1.061" transform="translate(-4078.597 5281.4)" fill="#e2dfc7" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                      <rect id="Rectangle_1996" data-name="Rectangle 1996" width="1.551" height="1.061" rx="0.459" transform="translate(-4078.597 5280.583)" fill="#fff" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                    </g>
                  </g>
                  <g id="Group_1556" data-name="Group 1556">
                    <g id="Group_1545" data-name="Group 1545">
                      <rect id="Rectangle_1997" data-name="Rectangle 1997" width="1.551" height="1.061" transform="translate(-4100.072 5282.87)" fill="#e2dfc7" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                      <rect id="Rectangle_1998" data-name="Rectangle 1998" width="1.551" height="1.061" rx="0.459" transform="translate(-4100.072 5282.053)" fill="#f7f5dd" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                    </g>
                    <g id="Group_1546" data-name="Group 1546">
                      <rect id="Rectangle_1999" data-name="Rectangle 1999" width="1.551" height="1.061" transform="translate(-4098.031 5282.87)" fill="#e2dfc7" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                      <rect id="Rectangle_2000" data-name="Rectangle 2000" width="1.551" height="1.061" rx="0.459" transform="translate(-4098.031 5282.053)" fill="#fff" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                    </g>
                    <g id="Group_1547" data-name="Group 1547">
                      <rect id="Rectangle_2001" data-name="Rectangle 2001" width="1.551" height="1.061" transform="translate(-4095.989 5282.87)" fill="#e2dfc7" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                      <rect id="Rectangle_2002" data-name="Rectangle 2002" width="1.551" height="1.061" rx="0.459" transform="translate(-4095.989 5282.053)" fill="#f7f5dd" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                    </g>
                    <g id="Group_1548" data-name="Group 1548">
                      <rect id="Rectangle_2003" data-name="Rectangle 2003" width="1.551" height="1.061" transform="translate(-4093.948 5282.87)" fill="#e2dfc7" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                      <rect id="Rectangle_2004" data-name="Rectangle 2004" width="1.551" height="1.061" rx="0.459" transform="translate(-4093.948 5282.053)" fill="#fff" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                    </g>
                    <g id="Group_1549" data-name="Group 1549">
                      <rect id="Rectangle_2005" data-name="Rectangle 2005" width="1.551" height="1.061" transform="translate(-4091.906 5282.87)" fill="#e2dfc7" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                      <rect id="Rectangle_2006" data-name="Rectangle 2006" width="1.551" height="1.061" rx="0.459" transform="translate(-4091.906 5282.053)" fill="#f7f5dd" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                    </g>
                    <g id="Group_1550" data-name="Group 1550">
                      <rect id="Rectangle_2007" data-name="Rectangle 2007" width="1.551" height="1.061" transform="translate(-4089.865 5282.87)" fill="#e2dfc7" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                      <rect id="Rectangle_2008" data-name="Rectangle 2008" width="1.551" height="1.061" rx="0.459" transform="translate(-4089.865 5282.053)" fill="#fff" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                    </g>
                    <g id="Group_1551" data-name="Group 1551">
                      <rect id="Rectangle_2009" data-name="Rectangle 2009" width="1.551" height="1.061" transform="translate(-4087.824 5282.87)" fill="#e2dfc7" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                      <rect id="Rectangle_2010" data-name="Rectangle 2010" width="1.551" height="1.061" rx="0.459" transform="translate(-4087.824 5282.053)" fill="#f7f5dd" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                    </g>
                    <g id="Group_1552" data-name="Group 1552">
                      <rect id="Rectangle_2011" data-name="Rectangle 2011" width="1.551" height="1.061" transform="translate(-4085.782 5282.87)" fill="#e2dfc7" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                      <rect id="Rectangle_2012" data-name="Rectangle 2012" width="1.551" height="1.061" rx="0.459" transform="translate(-4085.782 5282.053)" fill="#fff" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                    </g>
                    <g id="Group_1553" data-name="Group 1553">
                      <rect id="Rectangle_2013" data-name="Rectangle 2013" width="1.551" height="1.061" transform="translate(-4083.741 5282.87)" fill="#e2dfc7" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                      <rect id="Rectangle_2014" data-name="Rectangle 2014" width="1.551" height="1.061" rx="0.459" transform="translate(-4083.741 5282.053)" fill="#f7f5dd" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                    </g>
                    <g id="Group_1554" data-name="Group 1554">
                      <rect id="Rectangle_2015" data-name="Rectangle 2015" width="1.551" height="1.061" transform="translate(-4081.7 5282.87)" fill="#e2dfc7" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                      <rect id="Rectangle_2016" data-name="Rectangle 2016" width="1.551" height="1.061" rx="0.459" transform="translate(-4081.7 5282.053)" fill="#fff" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                    </g>
                    <g id="Group_1555" data-name="Group 1555">
                      <rect id="Rectangle_2017" data-name="Rectangle 2017" width="1.551" height="1.061" transform="translate(-4079.659 5282.87)" fill="#e2dfc7" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                      <rect id="Rectangle_2018" data-name="Rectangle 2018" width="1.551" height="1.061" rx="0.459" transform="translate(-4079.659 5282.053)" fill="#f7f5dd" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                    </g>
                  </g>
                </g>
              </g>
              <path id="Path_924" data-name="Path 924" d="M-4081,5266.413a.778.778,0,0,0-.536-.188c-5.1.165-5.332-1.564-5.332-1.564v-.653h-3.674v.653s-.236,1.729-5.332,1.564a.776.776,0,0,0-.535.188.165.165,0,0,0,.107.29h15.195A.165.165,0,0,0-4081,5266.413Z" fill="#010101"/>
              <rect id="Rectangle_2019" data-name="Rectangle 2019" width="3.838" height="1.388" rx="0.694" transform="translate(-4090.624 5270.998)" fill="#f9d53e" stroke="#000" stroke-miterlimit="10" stroke-width="0.193" style="isolation: isolate"/>
              <g id="Group_1569" data-name="Group 1569">
                <g id="Group_1563" data-name="Group 1563">
                  <g id="Group_1559" data-name="Group 1559">
                    <rect id="Rectangle_2020" data-name="Rectangle 2020" width="2.091" height="1.431" transform="translate(-4103.631 5277.438)" fill="#f7f5dd" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                    <rect id="Rectangle_2021" data-name="Rectangle 2021" width="2.091" height="1.431" rx="0.619" transform="translate(-4103.631 5276.338)" fill="#f7f5dd" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                  </g>
                  <g id="Group_1560" data-name="Group 1560">
                    <rect id="Rectangle_2022" data-name="Rectangle 2022" width="2.091" height="1.431" transform="translate(-4105.183 5279.479)" fill="#f7f5dd" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                    <rect id="Rectangle_2023" data-name="Rectangle 2023" width="2.091" height="1.431" rx="0.619" transform="translate(-4105.183 5278.379)" fill="#f7f5dd" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                  </g>
                  <g id="Group_1561" data-name="Group 1561">
                    <rect id="Rectangle_2024" data-name="Rectangle 2024" width="2.091" height="1.431" transform="translate(-4104.203 5281.521)" fill="#f7f5dd" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                    <rect id="Rectangle_2025" data-name="Rectangle 2025" width="2.091" height="1.431" rx="0.619" transform="translate(-4104.203 5280.42)" fill="#f7f5dd" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                  </g>
                  <g id="Group_1562" data-name="Group 1562">
                    <rect id="Rectangle_2026" data-name="Rectangle 2026" width="2.091" height="1.431" transform="translate(-4105.917 5283.317)" fill="#f7f5dd" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                    <rect id="Rectangle_2027" data-name="Rectangle 2027" width="2.091" height="1.431" rx="0.619" transform="translate(-4105.917 5282.217)" fill="#f7f5dd" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                  </g>
                </g>
                <g id="Group_1568" data-name="Group 1568">
                  <g id="Group_1564" data-name="Group 1564">
                    <rect id="Rectangle_2028" data-name="Rectangle 2028" width="2.091" height="1.431" transform="translate(-4073.778 5278.869) rotate(-180)" fill="#f7f5dd" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                    <rect id="Rectangle_2029" data-name="Rectangle 2029" width="2.091" height="1.431" rx="0.619" transform="translate(-4073.778 5277.768) rotate(-180)" fill="#f7f5dd" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                  </g>
                  <g id="Group_1565" data-name="Group 1565">
                    <rect id="Rectangle_2030" data-name="Rectangle 2030" width="2.091" height="1.431" transform="translate(-4072.227 5280.91) rotate(-180)" fill="#f7f5dd" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                    <rect id="Rectangle_2031" data-name="Rectangle 2031" width="2.091" height="1.431" rx="0.619" transform="translate(-4072.227 5279.81) rotate(-180)" fill="#f7f5dd" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                  </g>
                  <g id="Group_1566" data-name="Group 1566">
                    <rect id="Rectangle_2032" data-name="Rectangle 2032" width="2.091" height="1.431" transform="translate(-4073.207 5282.952) rotate(-180)" fill="#f7f5dd" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                    <rect id="Rectangle_2033" data-name="Rectangle 2033" width="2.091" height="1.431" rx="0.619" transform="translate(-4073.207 5281.851) rotate(-180)" fill="#f7f5dd" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                  </g>
                  <g id="Group_1567" data-name="Group 1567">
                    <rect id="Rectangle_2034" data-name="Rectangle 2034" width="2.091" height="1.431" transform="translate(-4071.492 5284.748) rotate(-180)" fill="#f7f5dd" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                    <rect id="Rectangle_2035" data-name="Rectangle 2035" width="2.091" height="1.431" rx="0.619" transform="translate(-4071.492 5283.647) rotate(-180)" fill="#f7f5dd" stroke="#231f20" stroke-miterlimit="10" stroke-width="0.163"/>
                  </g>
                </g>
              </g>
              <g id="Group_1570" data-name="Group 1570" opacity="0.35">
                <line id="Line_1023" data-name="Line 1023" y2="1.306" transform="translate(-4102.596 5262.228)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.163"/>
                <line id="Line_1024" data-name="Line 1024" y2="1.306" transform="translate(-4102.091 5262.228)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.163"/>
                <line id="Line_1025" data-name="Line 1025" y2="1.306" transform="translate(-4101.586 5262.228)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.163"/>
                <line id="Line_1026" data-name="Line 1026" y2="1.306" transform="translate(-4101.081 5262.228)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.163"/>
                <line id="Line_1027" data-name="Line 1027" y2="1.306" transform="translate(-4100.576 5262.228)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.163"/>
                <line id="Line_1028" data-name="Line 1028" y2="1.306" transform="translate(-4100.07 5262.228)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.163"/>
                <line id="Line_1029" data-name="Line 1029" y2="1.306" transform="translate(-4099.565 5262.228)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.163"/>
                <line id="Line_1030" data-name="Line 1030" y2="1.306" transform="translate(-4099.06 5262.228)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.163"/>
                <line id="Line_1031" data-name="Line 1031" y2="1.306" transform="translate(-4098.555 5262.228)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.163"/>
                <line id="Line_1032" data-name="Line 1032" y2="1.306" transform="translate(-4098.05 5262.228)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.163"/>
                <line id="Line_1033" data-name="Line 1033" y2="1.306" transform="translate(-4097.545 5262.228)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.163"/>
                <line id="Line_1034" data-name="Line 1034" y2="1.306" transform="translate(-4097.04 5262.228)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.163"/>
                <line id="Line_1035" data-name="Line 1035" y2="1.306" transform="translate(-4096.535 5262.228)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.163"/>
                <line id="Line_1036" data-name="Line 1036" y2="1.306" transform="translate(-4096.029 5262.228)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.163"/>
                <line id="Line_1037" data-name="Line 1037" y2="1.306" transform="translate(-4095.524 5262.228)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.163"/>
                <line id="Line_1038" data-name="Line 1038" y2="1.306" transform="translate(-4095.019 5262.228)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.163"/>
                <line id="Line_1039" data-name="Line 1039" y2="1.306" transform="translate(-4094.514 5262.228)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.163"/>
                <line id="Line_1040" data-name="Line 1040" y2="1.306" transform="translate(-4094.009 5262.228)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.163"/>
                <line id="Line_1041" data-name="Line 1041" y2="1.306" transform="translate(-4093.504 5262.228)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.163"/>
                <line id="Line_1042" data-name="Line 1042" y2="1.306" transform="translate(-4092.999 5262.228)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.163"/>
                <line id="Line_1043" data-name="Line 1043" y2="1.306" transform="translate(-4092.493 5262.228)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.163"/>
                <line id="Line_1044" data-name="Line 1044" y2="1.306" transform="translate(-4091.988 5262.228)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.163"/>
                <line id="Line_1045" data-name="Line 1045" y2="1.306" transform="translate(-4091.483 5262.228)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.163"/>
                <line id="Line_1046" data-name="Line 1046" y2="1.306" transform="translate(-4090.978 5262.228)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.163"/>
                <line id="Line_1047" data-name="Line 1047" y2="1.306" transform="translate(-4090.473 5262.228)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.163"/>
                <line id="Line_1048" data-name="Line 1048" y2="1.306" transform="translate(-4089.968 5262.228)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.163"/>
                <line id="Line_1049" data-name="Line 1049" y2="1.306" transform="translate(-4089.462 5262.228)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.163"/>
                <line id="Line_1050" data-name="Line 1050" y2="1.306" transform="translate(-4088.957 5262.228)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.163"/>
                <line id="Line_1051" data-name="Line 1051" y2="1.306" transform="translate(-4088.452 5262.228)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.163"/>
                <line id="Line_1052" data-name="Line 1052" y2="1.306" transform="translate(-4087.947 5262.228)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.163"/>
                <line id="Line_1053" data-name="Line 1053" y2="1.306" transform="translate(-4087.442 5262.228)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.163"/>
                <line id="Line_1054" data-name="Line 1054" y2="1.306" transform="translate(-4086.937 5262.228)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.163"/>
                <line id="Line_1055" data-name="Line 1055" y2="1.306" transform="translate(-4086.432 5262.228)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.163"/>
                <line id="Line_1056" data-name="Line 1056" y2="1.306" transform="translate(-4085.927 5262.228)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.163"/>
                <line id="Line_1057" data-name="Line 1057" y2="1.306" transform="translate(-4085.421 5262.228)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.163"/>
                <line id="Line_1058" data-name="Line 1058" y2="1.306" transform="translate(-4084.916 5262.228)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.163"/>
                <line id="Line_1059" data-name="Line 1059" y2="1.306" transform="translate(-4084.411 5262.228)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.163"/>
                <line id="Line_1060" data-name="Line 1060" y2="1.306" transform="translate(-4083.906 5262.228)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.163"/>
                <line id="Line_1061" data-name="Line 1061" y2="1.306" transform="translate(-4083.401 5262.228)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.163"/>
                <line id="Line_1062" data-name="Line 1062" y2="1.306" transform="translate(-4082.896 5262.228)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.163"/>
                <line id="Line_1063" data-name="Line 1063" y2="1.306" transform="translate(-4082.391 5262.228)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.163"/>
                <line id="Line_1064" data-name="Line 1064" y2="1.306" transform="translate(-4081.885 5262.228)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.163"/>
                <line id="Line_1065" data-name="Line 1065" y2="1.306" transform="translate(-4081.38 5262.228)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.163"/>
                <line id="Line_1066" data-name="Line 1066" y2="1.306" transform="translate(-4080.875 5262.228)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.163"/>
                <line id="Line_1067" data-name="Line 1067" y2="1.306" transform="translate(-4080.37 5262.228)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.163"/>
                <line id="Line_1068" data-name="Line 1068" y2="1.306" transform="translate(-4079.865 5262.228)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.163"/>
                <line id="Line_1069" data-name="Line 1069" y2="1.306" transform="translate(-4079.36 5262.228)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.163"/>
                <line id="Line_1070" data-name="Line 1070" y2="1.306" transform="translate(-4078.854 5262.228)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.163"/>
                <line id="Line_1071" data-name="Line 1071" y2="1.306" transform="translate(-4078.349 5262.228)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.163"/>
                <line id="Line_1072" data-name="Line 1072" y2="1.306" transform="translate(-4077.844 5262.228)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.163"/>
                <line id="Line_1073" data-name="Line 1073" y2="1.306" transform="translate(-4077.339 5262.228)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.163"/>
                <line id="Line_1074" data-name="Line 1074" y2="1.306" transform="translate(-4076.834 5262.228)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.163"/>
                <line id="Line_1075" data-name="Line 1075" y2="1.306" transform="translate(-4076.329 5262.228)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.163"/>
                <line id="Line_1076" data-name="Line 1076" y2="1.306" transform="translate(-4075.824 5262.228)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.163"/>
                <line id="Line_1077" data-name="Line 1077" y2="1.306" transform="translate(-4075.319 5262.228)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.163"/>
                <line id="Line_1078" data-name="Line 1078" y2="1.306" transform="translate(-4074.813 5262.228)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.163"/>
              </g>
            </g>
            <rect id="Rectangle_2036" data-name="Rectangle 2036" width="25.744" height="10.446" transform="translate(-4101.555 5252.245)" fill="#fff" stroke="#000" stroke-miterlimit="10" stroke-width="0.192" style="isolation: isolate"/>
            <g id="Group_1572" data-name="Group 1572">
              <line id="Line_1079" data-name="Line 1079" x2="6.149" transform="translate(-4098.498 5254.781)" fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="0.212"/>
              <line id="Line_1080" data-name="Line 1080" x2="19.4" transform="translate(-4098.498 5256.157)" fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="0.212"/>
              <line id="Line_1081" data-name="Line 1081" x2="15.16" transform="translate(-4098.498 5257.533)" fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="0.212"/>
              <line id="Line_1082" data-name="Line 1082" x2="19.4" transform="translate(-4098.498 5258.91)" fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="0.212"/>
              <line id="Line_1083" data-name="Line 1083" x2="19.4" transform="translate(-4098.498 5260.286)" fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="0.212"/>
            </g>
            <rect id="Rectangle_2037" data-name="Rectangle 2037" width="32.811" height="1.618" rx="0.809" transform="translate(-4105.229 5289.534)" fill="#231f20" opacity="0.2"/>
            <rect id="Rectangle_2038" data-name="Rectangle 2038" width="35.779" height="0.982" transform="translate(-4106.713 5274.374)" fill="#231f20" opacity="0.2"/>
            <rect id="Rectangle_2039" data-name="Rectangle 2039" width="35.779" height="0.982" transform="translate(-4106.713 5288.792)" fill="#fff" opacity="0.2"/>
            <g id="Group_1573" data-name="Group 1573" opacity="0.35">
              <line id="Line_1084" data-name="Line 1084" x1="1.306" transform="translate(-4070.642 5262.618)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.163"/>
              <line id="Line_1085" data-name="Line 1085" x1="1.306" transform="translate(-4070.642 5263.123)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.163"/>
              <line id="Line_1086" data-name="Line 1086" x1="1.306" transform="translate(-4070.642 5263.628)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.163"/>
              <line id="Line_1087" data-name="Line 1087" x1="1.306" transform="translate(-4070.642 5264.133)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.163"/>
              <line id="Line_1088" data-name="Line 1088" x1="1.306" transform="translate(-4070.642 5264.638)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.163"/>
              <line id="Line_1089" data-name="Line 1089" x1="1.306" transform="translate(-4070.642 5265.143)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.163"/>
            </g>
            <g id="Group_1574" data-name="Group 1574" opacity="0.35">
              <line id="Line_1090" data-name="Line 1090" x1="1.306" transform="translate(-4107.958 5262.618)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.163"/>
              <line id="Line_1091" data-name="Line 1091" x1="1.306" transform="translate(-4107.958 5263.123)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.163"/>
              <line id="Line_1092" data-name="Line 1092" x1="1.306" transform="translate(-4107.958 5263.628)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.163"/>
              <line id="Line_1093" data-name="Line 1093" x1="1.306" transform="translate(-4107.958 5264.133)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.163"/>
              <line id="Line_1094" data-name="Line 1094" x1="1.306" transform="translate(-4107.958 5264.638)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.163"/>
              <line id="Line_1095" data-name="Line 1095" x1="1.306" transform="translate(-4107.958 5265.143)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.163"/>
            </g>
            <path id="Path_925" data-name="Path 925" d="M-4075.593,5262.622a1.31,1.31,0,0,0-.891-.31c-8.515.276-8.909-.492-8.909-.492h0a1.091,1.091,0,0,0-1.091-1.092h-3.957a1.091,1.091,0,0,0-1.091,1.092h0s-.394.768-8.909.492a1.311,1.311,0,0,0-.891.31.276.276,0,0,0,.175.489h25.389A.276.276,0,0,0-4075.593,5262.622Z" fill="#6fc7d9"/>
          </g>
          <g id="Group_1577" data-name="Group 1577">
            <path id="Path_926" data-name="Path 926" d="M-4079.153,5293.364l-19.072,22.229,3.932,4.651,20.334-23.7Z" fill="#bfbfbf" stroke="#000" stroke-linejoin="round" stroke-width="0.33"/>
            <rect id="Rectangle_2040" data-name="Rectangle 2040" width="18.039" height="2.91" transform="translate(-4095.225 5310.756) rotate(-49.372)" fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="0.33"/>
            <rect id="Rectangle_2041" data-name="Rectangle 2041" width="37.436" height="5.237" transform="translate(-4104.705 5315.401) rotate(-49.372)" fill="#eaeaea" stroke="#000" stroke-linejoin="round" stroke-width="0.33"/>
            <rect id="Rectangle_2042" data-name="Rectangle 2042" width="18.039" height="3.879" transform="translate(-4098.39 5308.041) rotate(-49.372)" fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="0.33"/>
            <rect id="Rectangle_2043" data-name="Rectangle 2043" width="49.347" height="38.212" transform="translate(-4136.848 5295.671) rotate(-49.372)" fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="0.33"/>
            <rect id="Rectangle_2044" data-name="Rectangle 2044" width="41.83" height="31.618" transform="translate(-4131.898 5294.965) rotate(-49.372)" fill="#bfbfbf" stroke="#000" stroke-linejoin="round" stroke-width="0.33"/>
            <rect id="Rectangle_2045" data-name="Rectangle 2045" width="37.824" height="28.514" transform="translate(-4129.416 5294.456) rotate(-49.372)" fill="#6fc7d9" stroke="#000" stroke-miterlimit="10" stroke-width="0.33"/>
            <g id="Group_1576" data-name="Group 1576">
              <rect id="Rectangle_2046" data-name="Rectangle 2046" width="3.104" height="1.358" rx="0.679" transform="translate(-4101.107 5314.782) rotate(-49.372)" fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="0.33"/>
              <rect id="Rectangle_2047" data-name="Rectangle 2047" width="3.104" height="1.358" rx="0.679" transform="translate(-4082.414 5292.995) rotate(-49.372)" fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="0.33"/>
            </g>
          </g>
          <g id="Group_1590" data-name="Group 1590">
            <g id="Group_1589" data-name="Group 1589">
              <g id="Group_1578" data-name="Group 1578" opacity="0.2">
                <path id="Path_927" data-name="Path 927" d="M-4136.446,5264.328c-2.155,1.121-4.185,2.269-6.04,3.4a.914.914,0,0,0-.333,1.2l3.146,6.049,5.959,11.456,3.148,6.052a.915.915,0,0,0,1.177.418c1.985-.865,4.081-1.864,6.225-2.979,2.165-1.126,4.2-2.28,6.065-3.418a.915.915,0,0,0,.334-1.2l-3.144-6.044-5.962-11.461-3.146-6.048a.915.915,0,0,0-1.177-.418C-4132.187,5262.2-4134.292,5263.208-4136.446,5264.328Z" fill="#575350"/>
              </g>
              <g id="Group_1579" data-name="Group 1579">
                <path id="Path_928" data-name="Path 928" d="M-4137.55,5262.207c-2.154,1.121-4.184,2.269-6.039,3.4a.914.914,0,0,0-.333,1.2l3.146,6.049,5.959,11.456,3.148,6.052a.915.915,0,0,0,1.177.418c1.985-.865,4.081-1.864,6.225-2.979,2.164-1.126,4.2-2.28,6.065-3.418a.915.915,0,0,0,.333-1.2l-3.144-6.044-5.961-11.461-3.146-6.048a.915.915,0,0,0-1.177-.418C-4133.29,5260.084-4135.4,5261.087-4137.55,5262.207Z" fill="#474747" stroke="#000" stroke-linejoin="round" stroke-width="0.239"/>
              </g>
              <g id="Group_1580" data-name="Group 1580">
                <path id="Path_929" data-name="Path 929" d="M-4137.29,5262.707c-1.855.965-3.6,1.959-5.192,2.945a.832.832,0,0,0-.254,1.1l2.951,5.673,5.589,10.745,2.953,5.677a.832.832,0,0,0,1.046.422c1.714-.735,3.522-1.59,5.368-2.55,1.863-.969,3.616-1.968,5.213-2.959a.831.831,0,0,0,.255-1.1l-2.949-5.67-5.591-10.749-2.951-5.673a.832.832,0,0,0-1.046-.422C-4133.619,5260.884-4135.435,5261.742-4137.29,5262.707Z" fill="#bfbfbf" stroke="#000" stroke-linejoin="round" stroke-width="0.239"/>
              </g>
              <g id="Group_1582" data-name="Group 1582">
                <g id="Group_1581" data-name="Group 1581">
                  <path id="Path_930" data-name="Path 930" d="M-4141.891,5253.861c-2.154,1.12-4.185,2.268-6.039,3.4a.915.915,0,0,0-.334,1.2l3.146,6.048,5.959,11.456,3.149,6.053a.915.915,0,0,0,1.177.418c1.984-.865,4.081-1.865,6.225-2.98,2.164-1.126,4.2-2.279,6.065-3.418a.915.915,0,0,0,.333-1.2l-3.144-6.045-5.961-11.46-3.146-6.049a.916.916,0,0,0-1.177-.418C-4137.631,5251.737-4139.737,5252.74-4141.891,5253.861Z" fill="#474747" stroke="#000" stroke-linejoin="round" stroke-width="0.239"/>
                  <rect id="Rectangle_2048" data-name="Rectangle 2048" width="1.795" height="0.718" rx="0.359" transform="matrix(0.887, -0.461, 0.461, 0.887, -4142.08, 5255.443)" fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="0.239"/>
                </g>
              </g>
              <g id="Group_1586" data-name="Group 1586">
                <g id="Group_1583" data-name="Group 1583">
                  <path id="Path_931" data-name="Path 931" d="M-4133.7,5284.125c1.922-.832,3.951-1.8,6.025-2.875,2.094-1.089,4.065-2.208,5.864-3.314" fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="0.239"/>
                </g>
                <g id="Group_1584" data-name="Group 1584">
                  <path id="Path_932" data-name="Path 932" d="M-4132.819,5285.824c1.922-.832,3.951-1.8,6.025-2.876,2.093-1.089,4.065-2.207,5.863-3.313" fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="0.239"/>
                </g>
                <g id="Group_1585" data-name="Group 1585">
                  <path id="Path_933" data-name="Path 933" d="M-4131.936,5287.523c1.922-.833,3.951-1.8,6.025-2.876,2.094-1.089,4.065-2.207,5.864-3.313" fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="0.239"/>
                </g>
              </g>
              <g id="Group_1587" data-name="Group 1587">
                <line id="Line_1096" data-name="Line 1096" x2="3.755" y2="7.22" transform="translate(-4130.626 5280.444)" fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="0.239"/>
                <line id="Line_1097" data-name="Line 1097" x2="3.755" y2="7.22" transform="translate(-4126.592 5278.346)" fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="0.239"/>
              </g>
              <rect id="Rectangle_2049" data-name="Rectangle 2049" width="12.805" height="17.233" rx="0.459" transform="matrix(0.887, -0.461, 0.461, 0.887, -4146.053, 5259.735)" fill="#6fc7d9" stroke="#000" stroke-miterlimit="10" stroke-width="0.239"/>
              <rect id="Rectangle_2050" data-name="Rectangle 2050" width="0.838" height="0.718" rx="0.115" transform="matrix(0.887, -0.461, 0.461, 0.887, -4139.753, 5254.232)" fill="#f9d53e" stroke="#000" stroke-miterlimit="10" stroke-width="0.239"/>
              <g id="Group_1588" data-name="Group 1588">
                <rect id="Rectangle_2051" data-name="Rectangle 2051" width="8.497" height="1.376" transform="matrix(0.887, -0.461, 0.461, 0.887, -4134.09, 5278.064)" fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="0.239"/>
                <path id="Path_934" data-name="Path 934" d="M-4125.917,5275.364l.3-.154a.623.623,0,0,0,.265-.84l-.348-.668-.849.441Z" fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="0.239"/>
                <path id="Path_935" data-name="Path 935" d="M-4126.559,5273.4l-.3.154.3.584.849-.441-.016-.032A.623.623,0,0,0-4126.559,5273.4Z" fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="0.239"/>
                <path id="Path_936" data-name="Path 936" d="M-4134.94,5278.506l.348.669a.621.621,0,0,0,.839.265l.3-.155-.635-1.221Z" fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="0.239"/>
                <path id="Path_937" data-name="Path 937" d="M-4134.394,5277.48l-.3.155a.622.622,0,0,0-.265.839l.016.032.85-.442Z" fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="0.239"/>
                <rect id="Rectangle_2052" data-name="Rectangle 2052" width="8.497" height="0.658" transform="matrix(0.887, -0.461, 0.461, 0.887, -4134.394, 5277.48)" fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="0.239"/>
                <path id="Path_938" data-name="Path 938" d="M-4134.956,5278.474a.622.622,0,0,1,.265-.839l.3-.155-.414-.8-1.367.711a.973.973,0,0,0-.414,1.312l.269.517,1.38-.718Z" fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="0.239"/>
                <path id="Path_939" data-name="Path 939" d="M-4126.559,5273.4a.623.623,0,0,1,.84.265l.016.032,1.38-.718-.268-.517a.975.975,0,0,0-1.313-.415l-1.366.711.414.8Z" fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="0.239"/>
                <rect id="Rectangle_2053" data-name="Rectangle 2053" width="8.497" height="0.898" transform="matrix(0.887, -0.461, 0.461, 0.887, -4133.455, 5279.286)" fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="0.239"/>
                <path id="Path_940" data-name="Path 940" d="M-4125.7,5273.7l.348.668a.623.623,0,0,1-.265.84l-.3.154.414.8,1.366-.711a.973.973,0,0,0,.415-1.312l-.6-1.154Z" fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="0.239"/>
                <path id="Path_941" data-name="Path 941" d="M-4133.753,5279.44a.621.621,0,0,1-.839-.265l-.348-.669-1.38.718.6,1.154a.974.974,0,0,0,1.313.414l1.366-.71-.414-.8Z" fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="0.239"/>
                <rect id="Rectangle_2054" data-name="Rectangle 2054" width="8.497" height="0.898" transform="matrix(0.887, -0.461, 0.461, 0.887, -4134.809, 5276.684)" fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="0.239"/>
              </g>
              <rect id="Rectangle_2055" data-name="Rectangle 2055" width="10.412" height="2.034" rx="0.622" transform="matrix(0.887, -0.461, 0.461, 0.887, -4135.244, 5277.922)" fill="#bfbfbf" stroke="#000" stroke-linejoin="round" stroke-width="0.239"/>
              <rect id="Rectangle_2056" data-name="Rectangle 2056" width="3.741" height="3.741" rx="1.281" transform="matrix(0.887, -0.461, 0.461, 0.887, -4132.679, 5275.626)" fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="0.239"/>
              <rect id="Rectangle_2057" data-name="Rectangle 2057" width="1.701" height="1.701" rx="0.582" transform="matrix(0.887, -0.461, 0.461, 0.887, -4131.302, 5276.061)" fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="0.239"/>
              <rect id="Rectangle_2058" data-name="Rectangle 2058" width="2.094" height="2.052" rx="0.308" transform="matrix(0.887, -0.461, 0.461, 0.887, -4138.708, 5252.966)" fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="0.239"/>
              <circle id="Ellipse_325" data-name="Ellipse 325" cx="0.419" cy="0.419" r="0.419" transform="translate(-4137.725 5252.975)" fill="#fff" stroke="#000" stroke-linejoin="round" stroke-width="0.239"/>
            </g>
            <rect id="Rectangle_2059" data-name="Rectangle 2059" width="12.192" height="1.195" transform="matrix(0.887, -0.461, 0.461, 0.887, -4145.68, 5259.874)" fill="#575350" opacity="0.2"/>
            <rect id="Rectangle_2060" data-name="Rectangle 2060" width="12.192" height="1.195" transform="matrix(0.887, -0.461, 0.461, 0.887, -4138.509, 5273.659)" fill="#575350" opacity="0.2"/>
          </g>
        </g>
        <g id="Group_1596" data-name="Group 1596" transform="translate(0 -489.795)">
          <path id="Path_942" data-name="Path 942" d="M-4083.03,5309.143h-89.561v29.8h107.548v-29.8Z" fill="#aaa2ac"/>
          <g id="Group_1595" data-name="Group 1595">
            <rect id="Rectangle_2065" data-name="Rectangle 2065" width="114.472" height="16.934" transform="translate(-4176.052 5290.854)" fill="#aaa2ac"/>
            <g id="Group_1593" data-name="Group 1593">
              <rect id="Rectangle_2066" data-name="Rectangle 2066" width="125.761" height="10.16" rx="5.08" transform="translate(-4181.697 5284.758)" fill="#cdc6cc"/>
              <rect id="Rectangle_2067" data-name="Rectangle 2067" width="47.188" height="10.16" rx="5.08" transform="translate(-4103.125 5284.758)" fill="#beb6c0"/>
              <rect id="Rectangle_2068" data-name="Rectangle 2068" width="47.188" height="10.16" rx="5.08" transform="translate(-4130.218 5284.758)" fill="#cdc6cc"/>
              <rect id="Rectangle_2069" data-name="Rectangle 2069" width="43.124" height="10.16" rx="5.08" transform="translate(-4154.377 5284.758)" fill="#e7e2e8"/>
              <rect id="Rectangle_2070" data-name="Rectangle 2070" width="11.233" height="10.16" rx="4.934" transform="translate(-4104.423 5284.758)" fill="#e7e2e8"/>
            </g>
            <g id="Group_1594" data-name="Group 1594">
              <rect id="Rectangle_2071" data-name="Rectangle 2071" width="125.761" height="10.16" rx="5.08" transform="translate(-4181.697 5303.949)" fill="#cdc6cc"/>
              <rect id="Rectangle_2072" data-name="Rectangle 2072" width="47.188" height="10.16" rx="5.08" transform="translate(-4103.125 5303.949)" fill="#beb6c0"/>
              <rect id="Rectangle_2073" data-name="Rectangle 2073" width="47.188" height="10.16" rx="5.08" transform="translate(-4130.218 5303.949)" fill="#cdc6cc"/>
              <rect id="Rectangle_2074" data-name="Rectangle 2074" width="43.124" height="10.16" rx="5.08" transform="translate(-4154.377 5303.949)" fill="#e7e2e8"/>
            </g>
          </g>
        </g>
        <g id="Group_1597" data-name="Group 1597" transform="translate(0 -489.795)">
          <rect id="Rectangle_2075-6" data-name="Rectangle 2075" width="107.548" height="97.764" transform="translate(-4172.59 5323.141)" fill="#cdc6cc"/>
          <rect id="Rectangle_2076-6" data-name="Rectangle 2076" width="40.354" height="97.764" transform="translate(-4105.397 5323.141)" fill="#beb6c0"/>
          <rect id="Rectangle_2077-6" data-name="Rectangle 2077" width="47.188" height="97.764" transform="translate(-4130.218 5323.141)" fill="#cdc6cc"/>
          <rect id="Rectangle_2078-6" data-name="Rectangle 2078" width="43.124" height="97.764" transform="translate(-4154.377 5323.141)" fill="#e7e2e8"/>
        </g>
        <g id="Group_1602" data-name="Group 1602" transform="translate(0 -489.795)">
          <g id="Group_1598-6" data-name="Group 1598">
            <rect id="Rectangle_2079-6" data-name="Rectangle 2079" width="12.87" height="79.475" rx="6.435" transform="translate(-4161.15 5331.946)" fill="#958d98"/>
            <rect id="Rectangle_2080-6" data-name="Rectangle 2080" width="10.668" height="76.089" rx="5.334" transform="translate(-4158.949 5335.333)" fill="#aaa3ad"/>
          </g>
          <g id="Group_1599-6" data-name="Group 1599">
            <rect id="Rectangle_2081-6" data-name="Rectangle 2081" width="12.87" height="79.475" rx="6.435" transform="translate(-4137.218 5331.946)" fill="#958d98"/>
            <rect id="Rectangle_2082-6" data-name="Rectangle 2082" width="10.668" height="76.089" rx="5.334" transform="translate(-4135.016 5335.333)" fill="#aaa3ad"/>
          </g>
          <g id="Group_1600-6" data-name="Group 1600">
            <rect id="Rectangle_2083-6" data-name="Rectangle 2083" width="12.87" height="79.475" rx="6.435" transform="translate(-4113.285 5331.946)" fill="#958d98"/>
            <rect id="Rectangle_2084-6" data-name="Rectangle 2084" width="10.668" height="76.089" rx="5.334" transform="translate(-4111.083 5335.333)" fill="#aaa3ad"/>
          </g>
          <g id="Group_1601-6" data-name="Group 1601">
            <rect id="Rectangle_2085-6" data-name="Rectangle 2085" width="12.87" height="79.475" rx="6.435" transform="translate(-4089.352 5331.946)" fill="#958d98"/>
            <rect id="Rectangle_2086-6" data-name="Rectangle 2086" width="10.668" height="76.089" rx="5.334" transform="translate(-4087.15 5335.333)" fill="#aaa3ad"/>
          </g>
        </g>
      </g>
    </g>
  </svg>


</div>
