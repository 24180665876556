import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { Quiz } from '@models';
import gsap, { TimelineMax } from 'gsap';
import { slidesAnimation } from '../../router.animations';

@Component({
  selector: 'app-quiz-one',
  templateUrl: './quiz-one.component.html',
  styleUrls: ['./quiz-one.component.scss'],
  animations: [slidesAnimation],
})
export class QuizOneComponent implements OnInit, AfterViewInit {
  public timeline: TimelineMax;
  public handtimeline: TimelineMax;
  public quizNumber = 0;
  public slide: 'quiz' | 'result';
  public showQuizButton: boolean;
  public correct: boolean;
  public currentQuiz:  Quiz[];
  public questions: Quiz[][] = [
    [
      { name: '14 mil. ton', selected: false, correct: false },
      { name: '74 mil. ton', selected: false, correct: false },
      { name: '34 mil. ton', selected: false, correct: false },
      { name: '84 mil. ton', selected: false, correct: false },
      { name: '54 mil. ton', selected: false, correct: true },
      { name: '94 mil. ton', selected: false, correct: false },
      { name: '64 mil. ton', selected: false, correct: false },
      { name: '104 mil. ton', selected: false, correct: false },
    ],
    [
      { name: '15 mil. ton', selected: false, correct: false },
      { name: '75 mil. ton', selected: false, correct: true },
      { name: '35 mil. ton', selected: false, correct: false },
      { name: '85 mil. ton', selected: false, correct: false },
      { name: '55 mil. ton', selected: false, correct: false },
      { name: '95 mil. ton', selected: false, correct: false },
      { name: '65 mil. ton', selected: false, correct: false },
      { name: '105 mil. ton', selected: false, correct: false },
    ],
  ];
  @Output()
  nextSlide: EventEmitter<number> = new EventEmitter<number>();
  constructor() {
    this.timeline = gsap.timeline();
    this.handtimeline = gsap.timeline();
  }

  ngOnInit(): void {
    this.slide = 'quiz';
    this.currentQuiz = this.questions[this.quizNumber];
  }

  ngAfterViewInit(): void {

  }


  runAnimation() {
    this.timeline.clear();
    this.handtimeline.to('#hand', {
      transformOrigin: '75px 49px',
      rotation: 360,
      repeat: -1,
      ease: 'none',
      duration: 0.3,
    });

    this.timeline
      .fromTo('.second', { y: '-50%' }, { y: '0%', duration: 1, ease: 'none' })
      .fromTo(
        '.first',
        { y: '50%' },
        { y: '100%', duration: 1, ease: 'none' },
        '-=1'
      )
      .fromTo(
        '.second',
        { y: '-50%' },
        { y: '0%', duration: 1, ease: 'none' },
        '-=1'
      )
      .duration(2)
      .repeat(-1);
  }
  next(): void {
    this.nextSlide.emit(12);
  }

  select(item: Quiz): void {
    this.currentQuiz = this.currentQuiz.map((quiz) => ({
      ...quiz,
      selected: item.name === quiz.name,
    }));
    this.showQuizButton = true;
  }

  result(): void {
    this.slide = 'result';
    this.correct = this.currentQuiz.some((q) => q.selected && q.correct);
    setTimeout( () => {
      this.runAnimation();
    });
  }

  nextQuestion(): void {
    this.quizNumber = 1;
    this.slide = 'quiz';
    this.correct = false;
    this.showQuizButton = false;
    this.currentQuiz = this.questions[this.quizNumber];
  }
}
