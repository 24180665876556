import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  NgZone,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { TimelineMax } from 'gsap';

@Component({
  selector: 'app-your-table-intro',
  templateUrl: './your-table-intro.component.html',
  styleUrls: ['./your-table-intro.component.scss'],
})
export class YourTableIntroComponent implements OnInit, AfterViewInit {
  @Output()
  nextSlide: EventEmitter<number> = new EventEmitter<number>();

  public timeline: TimelineMax;

  constructor(private ngZone: NgZone) {
    this.timeline = new TimelineMax({
      onReverseComplete: () => {
        this.ngZone.run(() => {
          this.nextSlide.emit(6);
        });
      },
    });
  }
  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.timeline
      .fromTo(
        '.banner',
        { opacity: 0, transform: 'translateY(300px)' },
        {
          opacity: 1,
          transform: 'translateY(0%)',
          duration: 1.5,
          ease: 'elastic.out(1, 0.3)',
        }
      )
      .fromTo(
        '.btn',
        { opacity: 0, y: 100 },
        { opacity: 1, y: 0, duration: 1, ease: 'elastic.out(1, 0.3)' },
        '-=1.3'
      )
      .delay(0.5);
  }

  next(): void {
    this.timeline.reverse();
  }
}
