<div class="final">
  <div class="final-inner">
    <h1 class="title title--sm">Ďakujeme ti za absolvovanie kurzu a ochotu prijímať zodpovedné rozhodnutia pre životné prostredie a ľudí.
    </h1>
    <div class="subtitle subtitle--sm">
      Projekt Elektroodpad dopad realizuje CEEV Živica vďaka podpore spoločnosti SEWA, a. s. <br /> <br />
      Viac informácii o elektroodpade sa dozvieš na: <a target="_blank" href="https://www.elektroodpad.zivica.sk/">elektroodpad.zivica.sk</a> <br /> <br />
      Ďakujeme za spoluprácu spoločnosti SEWA, a. s., a GULIWER.
    </div>

    <img src="/assets/partners.png" alt="" class="partners">
    <div class="footer">
      <div>
        <strong>
          Vytvorila: CEEV Živica v roku 2021  <br /><br />
        </strong>
       Dizajn a programovanie:    <strong>GULIWER</strong><br />
       Autorka: <strong>Zuzana Dovalová</strong> <br />
       Preklad videa The Story of Electronics: <strong>Pavol Roman</strong>
      </div>
      <div>
        <strong>Ďakujeme za poskytnutie licencie na použitie obrazového materiálu a videí: </strong>
        <ul>
          <li>Michael O´Heaney zo Story of Stuff Project, www.storyofstuff.org - film „The Story of Electronics“. </li>
          <li>Christian Krönes z Blackbox Film & Medienproduktion GmbH, Austria - trailer filmu „Welcome to Sodom“. </li>
          <li>Erica Mosner zo Shelby White and Leon Levy Archives Center, Historical Studies-Social Science Library, Institute for Advanced Study v Princeton - fotografia pracovného stola prof. Einsteina z originálneho negatívu.</li>
        </ul>
      </div>
    </div>
  </div>

</div>
