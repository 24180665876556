import { Component,  EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-electronics-home-intro',
  templateUrl: './electronics-home-intro.component.html',
  styleUrls: ['./electronics-home-intro.component.scss']
})
export class ElectronicsHomeIntroComponent implements OnInit {

  @Output()
  nextSlide: EventEmitter<number> = new EventEmitter<number>();

  constructor() { }

  ngOnInit(): void {
  }

  next(): void  {
    this.nextSlide.emit(8);
  }
}
