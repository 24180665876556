import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  AfterViewInit,
  ViewChild,
  ElementRef,
  NgZone,
} from '@angular/core';
import { gsap } from 'gsap';
import tables from './tables.json';
import { Tables } from '../../models/tables.models';
import { BehaviorSubject } from 'rxjs';
@Component({
  selector: 'app-table-evolution',
  templateUrl: './table-evolution.component.html',
  styleUrls: ['./table-evolution.component.scss'],
})
export class TableEvolutionComponent implements OnInit, AfterViewInit {
  public tables: Tables[] = tables;
  public selectedTable: Tables = {} as Tables;
  public years: number[] = this.tables.map((y) => y.year);

  public activeYearIndex$: BehaviorSubject<number> = new BehaviorSubject(0);

  get nextYear(): number | null {
    const index = this.activeYearIndex$.getValue();
    return this.years[index + 1] ? this.years[index + 1] : null;
  }

  get activeYear(): number | undefined {
    const index = this.activeYearIndex$.getValue();
    return this.years[index];
  }
  private slideWidth = 350;

  @Output()
  nextSlide: EventEmitter<number> = new EventEmitter<number>();

  @ViewChild('bin') bin: ElementRef<HTMLDivElement>;
  @ViewChild('trash') trash: ElementRef<HTMLDivElement>;

  public timeline = gsap.timeline();
  public tableTimeline = gsap.timeline();
  public tableItems = gsap.timeline({ autoRemoveChildren: true });
  public animating: boolean;
  constructor(public zone: NgZone) {}

  ngOnInit(): void {
    this.activeYearIndex$.subscribe((index) => {
      this.selectedTable = this.tables[index];
      this.timeline.to('.timeline', {
        x: -index * this.slideWidth,
        ease: 'elastic.out(1, 0.3)',
        duration: 0.7,
      });

      setTimeout(() => {
        if (index > 0) {
          this.tableTimeline
            .to('.table', {
              y: 0,
              opacity: 1,
              duration: 0.4,
            })
            .to('.table-item-wrap', {
              scale: 1,
              stagger: 0.1,
              duration: 0.3,
              ease: 'elastic.out(2, 1.3)',
            })
            .eventCallback('onComplete', () => {
              this.zone.run(() => {
                this.animating = false;
              });
            });
        }
      }, 50);
    });
  }

  ngAfterViewInit(): void {
    this.timeline.fromTo(
      '.timeline',
      { xPercent: -50, opacity: 0 },
      { xPercent: 40, opacity: 1, duration: 1, ease: 'elastic.out(2, 0.3)' }
    );

    this.tableItems.to('.table-item-wrap', {
      scale: 1,
      stagger: 0.1,
      duration: 0.3,
      ease: 'elastic.out(2, 1.3)',
    });

    this.tableTimeline.fromTo(
      '.table',
      {
        y: '100%',
        opacity: 0,
      },
      {
        opacity: 1,
        y: 0,
        ease: 'elastic.out(1, 1.3)',
        delay: 0.8,
        duration: 0.4,
      }
    );
    this.tableTimeline.add(this.tableItems);
  }

  get showButton(): boolean {
    const index = this.activeYearIndex$.getValue();
    return this.tables.length > index + 1;
  }

  next(navigate: boolean, index: number): void {
    const { top } = this.trash.nativeElement.getBoundingClientRect();
    console.log(top);
    this.animating = true;
    this.tableTimeline.remove(this.tableItems);
    this.tableItems
      .to('.table-item-wrap', {
        scale: 0.5,
        stagger: 0.1,
        ease: 'elastic.out(1, 1.3)',
      })
      .to('.table-item-wrap', {
        left: -top + 50,
        top: top - 100,
        duration: 0.5,
        opacity: 0,
        stagger: 0.1,
      }).to('#Group_1591', {opacity: 1})
      .to(this.bin.nativeElement, {
        height: '+=70',
        opacity: 1,
        duration: 0.5,
        ease: 'elastic.out(1, 1.3)',
      })
    this.tableTimeline.add(this.tableItems);
    this.tableTimeline
      .to('.table', {
        y: '100%',
        opacity: 0,
        duration: 0.2,
      })

      .call(() => {
        this.zone.run(() => {
          if (navigate) {
            this.nextSlide.emit(5);
          } else {
            const index = this.activeYearIndex$.getValue();
            this.activeYearIndex$.next(index + 1);
          }
        });
      });
  }
}
