import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ElectronicsService } from '@core/services/electronics.service';
import { Electronic, ElectronicsCategories } from '@models';
import { Observable } from 'rxjs';
import { TimelineMax } from 'gsap';

@Component({
  selector: 'app-electronics-selection',
  templateUrl: './electronics-selection.component.html',
  styleUrls: ['./electronics-selection.component.scss'],
})
export class ElectronicsSelectionComponent implements OnInit {
  @Output()
  nextSlide: EventEmitter<number> = new EventEmitter<number>();

  public electronic$: Observable<
    ElectronicsCategories[]
  > = this.electronicService.getElectronicsCategories();

  public total$: Observable<number> =
  this.electronicService.getTotalCountElectronic();

  public timeline: TimelineMax;

  constructor(public electronicService: ElectronicsService) {
    this.timeline = new TimelineMax();
  }

  ngOnInit(): void {
  }

  next(): void {
    this.nextSlide.emit(9);
  }

  select(item: Electronic): void {
    this.electronicService.selectElectronicCategoryItem(item);
  }

  increase(item: Electronic): void {
    this.electronicService.changeAmount(item, 'increase');
  }

  decrease(item: Electronic): void {
    this.electronicService.changeAmount(item, 'decrease');
  }
}
