<div class="slide-grid">
  <div class="slide-left">
    <div class="slide-inner-left">
    <div class="slide-label">
      Vyber z ponuky spotrebiče vo vašej domácnosti:
    </div>
    <div class="items-box">
      <div class="items-box-inner">

        <div class="category" *ngFor="let category of electronic$ | async">
          <h3 class="category-title">{{category.category}}</h3>
          <ul class="items">
            <li
              #item
              class="item"
              [class.selected]="item.selected"
               *ngFor="let item of category.items"

            >
              <div class="item-inner" (click)="select(item)">
                <div class="check" [class.selected]="item.selected"></div>
                {{ item.name }}
              </div>

              <div class="quantity">
                <div class="arrow up" (click)="increase(item)"></div>
                  <div class="count">{{item.count}} <span>ks</span></div>
                <div *ngIf="item.count" class="arrow down" (click)="decrease(item)"></div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      </div>
    </div>
  </div>
  <div class="slide-right">
    <div class="slide-inner-right">
    <div class="electronic-house">
      <div class="counter-description">
        V domácnosti sa Nachádza takýto počet spotrebičov:
      </div>
      <div class="counter">{{total$ | async}}</div>
    </div>
    <app-button *ngIf="total$ | async" (click)="next()" text="Hotovo" ></app-button>
  </div>
  </div>
</div>
