import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { IntroSlideComponent } from './slides/intro-slide/intro-slide.component';
import { WhoIsTheTableComponent } from './slides/who-is-the-table/who-is-the-table.component';
import { TableEvolutionIntroComponent } from './slides/table-evolution-intro/table-evolution-intro.component';
import { TableEvolutionComponent } from './slides/table-evolution/table-evolution.component';
import { YourTableIntroComponent } from './slides/your-table-intro/your-table-intro.component';
import { ArrangeYourTableComponent } from './slides/arrange-your-table/arrange-your-table.component';
import { CoreModule } from './core/core.module';
import { ElectronicsHomeIntroComponent } from './slides/electronics-home-intro/electronics-home-intro.component';
import { ElectronicsSelectionComponent } from './slides/electronics-selection/electronics-selection.component';
import { ElectronicsStoryIntroComponent } from './slides/electronics-story-intro/electronics-story-intro.component';
import { ElectronicsStoryMovieComponent } from './slides/electronics-story-movie/electronics-story-movie.component';
import { QuizOneComponent } from './slides/quiz-one/quiz-one.component';
import { SpotrebDilemyIntroComponent } from './slides/spotreb-dilemy-intro/spotreb-dilemy-intro.component';
import { SpotrebDilemyComponent } from './slides/spotreb-dilemy/spotreb-dilemy.component';
import { SodomIntroComponent } from './slides/sodom-intro/sodom-intro.component';
import { SodomVideoComponent } from './slides/sodom-video/sodom-video.component';
import { WhatWeNeedIntroComponent } from './slides/what-we-need-intro/what-we-need-intro.component';
import { WhatWeNeedSelectComponent } from './slides/what-we-need-select/what-we-need-select.component';
import { FinalComponent } from './slides/final/final.component';
@NgModule({
  declarations: [
    AppComponent,
    IntroSlideComponent,
    WhoIsTheTableComponent,
    TableEvolutionIntroComponent,
    TableEvolutionComponent,
    YourTableIntroComponent,
    ArrangeYourTableComponent,
    ElectronicsHomeIntroComponent,
    ElectronicsSelectionComponent,
    ElectronicsStoryIntroComponent,
    ElectronicsStoryMovieComponent,
    QuizOneComponent,
    SpotrebDilemyIntroComponent,
    SpotrebDilemyComponent,
    SodomIntroComponent,
    SodomVideoComponent,
    WhatWeNeedIntroComponent,
    WhatWeNeedSelectComponent,
    FinalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    CoreModule,
    DragDropModule
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
