import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { slideAnimation, slidesAnimation } from './router.animations';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [slidesAnimation, slideAnimation],
})
export class AppComponent implements OnInit {
  public totalSlides = 18;
  public slides = Array.from(
    { length: this.totalSlides },
    (_, index) => index + 1
  );
  public page = 1;
  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    this.route.queryParams
    .subscribe((p) => {
      if (p.slide) {
        this.page = parseInt(p.slide, 0);
      }
    });
  }

  goToPage(page: number): void {
    this.page = page;
  }
}
