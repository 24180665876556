import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipComponent } from '@shared/components';
import { ButtonComponent } from './components/button/button.component';



@NgModule({
  declarations: [TooltipComponent, ButtonComponent],
  exports: [TooltipComponent, ButtonComponent],
  imports: [
    CommonModule
  ]
})
export class SharedModule { }
