
import {
  AfterViewInit,
  Component,
  EventEmitter,
  NgZone,
  OnInit,
  Output,
} from '@angular/core';
import gsap, { TimelineMax } from 'gsap';
import { slidesAnimation } from '../../router.animations';

interface Person {
  name: string;
  info: string;
  image: string;
  id: number;
}
@Component({
  selector: 'app-who-is-the-table',
  templateUrl: './who-is-the-table.component.html',
  styleUrls: ['./who-is-the-table.component.scss'],
  animations: [slidesAnimation],
})
export class WhoIsTheTableComponent implements OnInit, AfterViewInit {
  @Output()
  nextSlide: EventEmitter<number> = new EventEmitter<number>();
  public persons: Person[] = [];
  public selected: Person | undefined;
  public step: number | undefined = 1;
  public correctAnswer: boolean = false;
  public timeline: TimelineMax;
  public showtooltip: boolean;
  constructor(readonly zone: NgZone) {
    this.timeline = gsap.timeline();
    this.persons = [
      {
        name: 'Aurel Bohuslav Stodola',
        image: '/assets/stodola.png',
        info: '1859 - 1942, slovenský fyzik a technik',
      },
      {
        name: 'Albert Einstein',
        image: '/assets/einstein.png',
        info: '1879 - 1955, nemecký teoretický fyzik',
      },
      {
        name: 'Friedrich Nietzsche',
        image: '/assets/nietzsche.png',
        info: '1844 - 1900, nemecký filozof',
      },
      {
        name: 'Milan Rastislav Štefánik',
        image: '/assets/stefanik.png',
        info: '1890 - 1919, slovenský politik, diplomat',
      },
      {
        name: 'Nikola Tesla',
        image: '/assets/tesla.png',
        info: '1856 - 1943, srbsko-americký vynálezca',
      },
      {
        name: 'Ernest Hemingway',
        image: '/assets/hemingway.png',
        info: '1899 - 1961, americký spisovateľ',
      },
    ].map((person, index) => ({ ...person, id: index }));
  }

  ngOnInit(): void {


  }

  ngAfterViewInit(): void {
    this.timeline
    .fromTo('.box', {scale: 0, opacity: 0}, {
      scale: 1,
      opacity: 1,
      delay: 1,
      duration: 0.8,
      ease: 'elastic.out(1, 0.3)',
      stagger: {
        amount: 0.2,
        from: 'start',
        ease: 'power3.inOut',
        grid: 'auto',
      }
    });
  }

  next(): void {
    this.step = 2;
    setTimeout(() => {
      this.timeline
        .fromTo(
          '.result-person',
          { scale: 0 },
          { scale: 1, duration: 1, ease: 'elastic.out(1, 0.3)' }
        )
        .delay(0.5);
    });
  }

  navigate() {
    this.nextSlide.emit(3);
  }
  select(person: Person) {
    this.selected = person;
    this.correctAnswer = 'Albert Einstein' === person.name;
  }
}
